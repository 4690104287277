@charset "UTF-8";
/* -------------------------------------------------------------------------- */
/*                                 Theme                                      */
/* -------------------------------------------------------------------------- */
/* prettier-ignore */
/* -------------------------------------------------------------------------- */
/*                                  Utilities                                 */
/* -------------------------------------------------------------------------- */
/*-----------------------------------------------
|   Bootstrap Styles
-----------------------------------------------*/
/*!
 * Bootstrap v5.0.0-alpha1 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #2c7be5;
  --bs-indigo: #727cf5;
  --bs-purple: #6b5eae;
  --bs-pink: #ff679b;
  --bs-red: #e63757;
  --bs-orange: #fd7e14;
  --bs-yellow: #f5803e;
  --bs-green: #00d27a;
  --bs-teal: #02a8b5;
  --bs-cyan: #27bcfd;
  --bs-white: #fff;
  --bs-gray: #748194;
  --bs-gray-dark: #4d5969;
  --bs-primary: #2c7be5;
  --bs-secondary: #748194;
  --bs-success: #00d27a;
  --bs-info: #27bcfd;
  --bs-warning: #f5803e;
  --bs-danger: #e63757;
  --bs-light: #f9fafd;
  --bs-dark: #0b1727;
  --bs-font-sans-serif: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --bs-font-monospace: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5e6e82;
  background-color: #edf2f9;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  margin: 1rem 0;
  color: #d8e2ef;
  background-color: currentColor;
  border: 0;
  opacity: 1;
}

hr:not([size]) {
  height: 1px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 500;
  line-height: 1.2;
  color: #344050;
}

h1, .h1 {
  font-size: calc(1.37383rem + 1.48598vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.48832rem;
  }
}

h2, .h2 {
  font-size: calc(1.33236rem + 0.98832vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2.0736rem;
  }
}

h3, .h3 {
  font-size: calc(1.2978rem + 0.5736vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.728rem;
  }
}

h4, .h4 {
  font-size: calc(1.269rem + 0.228vw);
}

@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.44rem;
  }
}

h5, .h5 {
  font-size: 1.2rem;
}

h6, .h6 {
  font-size: 0.83333rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 800;
}

small, .small {
  font-size: 75%;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #2c7be5;
  text-decoration: none;
}

a:hover {
  color: #1657af;
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 75%;
  -ms-overflow-style: scrollbar;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 75%;
  color: #ff679b;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 75%;
  color: #fff;
  background-color: #344050;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #9da9bb;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
  white-space: normal;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.2rem;
  font-weight: 400;
}

.display-1 {
  font-size: calc(1.55498rem + 3.65978vw);
  font-weight: 900;
  line-height: 1;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 4.29982rem;
  }
}

.display-2 {
  font-size: calc(1.48332rem + 2.79982vw);
  font-weight: 900;
  line-height: 1;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 3.58318rem;
  }
}

.display-3 {
  font-size: calc(1.4236rem + 2.08318vw);
  font-weight: 900;
  line-height: 1;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 2.98598rem;
  }
}

.display-4 {
  font-size: calc(1.37383rem + 1.48598vw);
  font-weight: 900;
  line-height: 1;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 2.48832rem;
  }
}

.display-5 {
  font-size: calc(1.33236rem + 0.98832vw);
  font-weight: 900;
  line-height: 1;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 2.0736rem;
  }
}

.display-6 {
  font-size: calc(1.2978rem + 0.5736vw);
  font-weight: 900;
  line-height: 1;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 1.728rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 75%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 75%;
  color: #748194;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 3px solid #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 75%;
  color: #748194;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 2rem;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1540px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1480px;
  }
}

.row {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.8rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.8rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 4rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 4rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 5rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 5rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 7.5rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 7.5rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 10rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 10rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 12.5rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 12.5rem;
}

.g-11,
.gx-11 {
  --bs-gutter-x: 15rem;
}

.g-11,
.gy-11 {
  --bs-gutter-y: 15rem;
}

.g-card,
.gx-card {
  --bs-gutter-x: 2.5rem;
}

.g-card,
.gy-card {
  --bs-gutter-y: 2.5rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.8rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.8rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 4rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 4rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 5rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 5rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 7.5rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 7.5rem;
  }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 10rem;
  }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 10rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 12.5rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 12.5rem;
  }
  .g-sm-11,
  .gx-sm-11 {
    --bs-gutter-x: 15rem;
  }
  .g-sm-11,
  .gy-sm-11 {
    --bs-gutter-y: 15rem;
  }
  .g-sm-card,
  .gx-sm-card {
    --bs-gutter-x: 2.5rem;
  }
  .g-sm-card,
  .gy-sm-card {
    --bs-gutter-y: 2.5rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.8rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.8rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 4rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 4rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 5rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 5rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 7.5rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 7.5rem;
  }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 10rem;
  }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 10rem;
  }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 12.5rem;
  }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 12.5rem;
  }
  .g-md-11,
  .gx-md-11 {
    --bs-gutter-x: 15rem;
  }
  .g-md-11,
  .gy-md-11 {
    --bs-gutter-y: 15rem;
  }
  .g-md-card,
  .gx-md-card {
    --bs-gutter-x: 2.5rem;
  }
  .g-md-card,
  .gy-md-card {
    --bs-gutter-y: 2.5rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.8rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.8rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 4rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 4rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 5rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 5rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 7.5rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 7.5rem;
  }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 10rem;
  }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 10rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 12.5rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 12.5rem;
  }
  .g-lg-11,
  .gx-lg-11 {
    --bs-gutter-x: 15rem;
  }
  .g-lg-11,
  .gy-lg-11 {
    --bs-gutter-y: 15rem;
  }
  .g-lg-card,
  .gx-lg-card {
    --bs-gutter-x: 2.5rem;
  }
  .g-lg-card,
  .gy-lg-card {
    --bs-gutter-y: 2.5rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.8rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.8rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 4rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 4rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 5rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 5rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 7.5rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 7.5rem;
  }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 10rem;
  }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 10rem;
  }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 12.5rem;
  }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 12.5rem;
  }
  .g-xl-11,
  .gx-xl-11 {
    --bs-gutter-x: 15rem;
  }
  .g-xl-11,
  .gy-xl-11 {
    --bs-gutter-y: 15rem;
  }
  .g-xl-card,
  .gx-xl-card {
    --bs-gutter-x: 2.5rem;
  }
  .g-xl-card,
  .gy-xl-card {
    --bs-gutter-y: 2.5rem;
  }
}

@media (min-width: 1540px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.8rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.8rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 4rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 4rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 5rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 5rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 7.5rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 7.5rem;
  }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 10rem;
  }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 10rem;
  }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 12.5rem;
  }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 12.5rem;
  }
  .g-xxl-11,
  .gx-xxl-11 {
    --bs-gutter-x: 15rem;
  }
  .g-xxl-11,
  .gy-xxl-11 {
    --bs-gutter-y: 15rem;
  }
  .g-xxl-card,
  .gx-xxl-card {
    --bs-gutter-x: 2.5rem;
  }
  .g-xxl-card,
  .gy-xxl-card {
    --bs-gutter-y: 2.5rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #5e6e82;
  --bs-table-striped-bg: #f9fafd;
  --bs-table-active-color: #5e6e82;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #5e6e82;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #5e6e82;
  vertical-align: top;
  border-color: #edf2f9;
}

.table > :not(caption) > * > * {
  padding: 0.75rem 0.75rem;
  background-color: var(--bs-table-bg);
  background-image: linear-gradient(var(--bs-table-accent-bg), var(--bs-table-accent-bg));
  border-bottom-width: 1px;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: inherit;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #c4daf8;
  --bs-table-striped-bg: #bed4f1;
  --bs-table-striped-color: #4d5969;
  --bs-table-active-bg: #b8cdea;
  --bs-table-active-color: #4d5969;
  --bs-table-hover-bg: #bbd0ed;
  --bs-table-hover-color: #4d5969;
  color: #4d5969;
  border-color: #b8cdea;
}

.table-secondary {
  --bs-table-bg: #d8dce1;
  --bs-table-striped-bg: #d1d5db;
  --bs-table-striped-color: #4d5969;
  --bs-table-active-bg: #cacfd5;
  --bs-table-active-color: #4d5969;
  --bs-table-hover-bg: #ced2d8;
  --bs-table-hover-color: #4d5969;
  color: #4d5969;
  border-color: #cacfd5;
}

.table-success {
  --bs-table-bg: #b8f2da;
  --bs-table-striped-bg: #b3ead4;
  --bs-table-striped-color: #4d5969;
  --bs-table-active-bg: #ade3cf;
  --bs-table-active-color: #4d5969;
  --bs-table-hover-bg: #b0e7d2;
  --bs-table-hover-color: #4d5969;
  color: #4d5969;
  border-color: #ade3cf;
}

.table-info {
  --bs-table-bg: #c3ecfe;
  --bs-table-striped-bg: #bde5f7;
  --bs-table-striped-color: #4d5969;
  --bs-table-active-bg: #b7ddef;
  --bs-table-active-color: #4d5969;
  --bs-table-hover-bg: #bae1f3;
  --bs-table-hover-color: #4d5969;
  color: #4d5969;
  border-color: #b7ddef;
}

.table-warning {
  --bs-table-bg: #fcdbc9;
  --bs-table-striped-bg: #f3d5c4;
  --bs-table-striped-color: #4d5969;
  --bs-table-active-bg: #ebcebf;
  --bs-table-active-color: #4d5969;
  --bs-table-hover-bg: #efd1c2;
  --bs-table-hover-color: #4d5969;
  color: #4d5969;
  border-color: #ebcebf;
}

.table-danger {
  --bs-table-bg: #f8c7d0;
  --bs-table-striped-bg: #efc2cb;
  --bs-table-striped-color: #4d5969;
  --bs-table-active-bg: #e7bcc6;
  --bs-table-active-color: #4d5969;
  --bs-table-hover-bg: #ebbfc8;
  --bs-table-hover-color: #4d5969;
  color: #4d5969;
  border-color: #e7bcc6;
}

.table-light {
  --bs-table-bg: #f9fafd;
  --bs-table-striped-bg: #f0f2f6;
  --bs-table-striped-color: #4d5969;
  --bs-table-active-bg: #e8eaee;
  --bs-table-active-color: #4d5969;
  --bs-table-hover-bg: #eceef2;
  --bs-table-hover-color: #4d5969;
  color: #4d5969;
  border-color: #e8eaee;
}

.table-dark {
  --bs-table-bg: #0b1727;
  --bs-table-striped-bg: #172332;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #232e3d;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #1d2837;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #232e3d;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1539.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}

.col-form-label {
  padding-top: calc(0.3125rem + 1px);
  padding-bottom: calc(0.3125rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  font-size: 1.2rem;
}

.col-form-label-sm {
  padding-top: calc(0.1875rem + 1px);
  padding-bottom: calc(0.1875rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 75%;
  color: #9da9bb;
}

.form-control {
  display: block;
  width: 100%;
  min-height: calc(1.5em + 0.625rem + 2px);
  padding: 0.3125rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #344050;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8e2ef;
  appearance: none;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control:focus {
  color: #344050;
  background-color: #fff;
  border-color: #9ec2f3;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(44, 123, 229, 0.25);
}

.form-control::placeholder {
  color: #748194;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #edf2f9;
  opacity: 1;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.3125rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #5e6e82;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.375rem + 2px);
  padding: 0.1875rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.form-control-lg {
  min-height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.25rem;
  font-size: 1.2rem;
  border-radius: 0.375rem;
}

.form-control-color {
  max-width: 3rem;
  padding: 0.3125rem;
}

.form-control-color::-moz-color-swatch {
  border-radius: 0.25rem;
}

.form-control-color::-webkit-color-swatch {
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.625rem + 2px);
  padding: 0.3125rem 2rem 0.3125rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #344050;
  vertical-align: middle;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%234d5969' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 1px solid #d8e2ef;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  appearance: none;
}

.form-select:focus {
  border-color: #9ec2f3;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(44, 123, 229, 0.25);
}

.form-select:focus::-ms-value {
  color: #344050;
  background-color: #fff;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1rem;
  background-image: none;
}

.form-select:disabled {
  color: #748194;
  background-color: #edf2f9;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #344050;
}

.form-select-sm {
  height: calc(1.5em + 0.375rem + 2px);
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
  padding-left: 0.75rem;
  font-size: 0.875rem;
}

.form-select-lg {
  height: calc(1.5em + 0.75rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 1.25rem;
  font-size: 1.2rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.34375rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #b6c1d2;
  appearance: none;
  color-adjust: exact;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-check-input {
    transition: none;
  }
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #9ec2f3;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(44, 123, 229, 0.25);
}

.form-check-input:checked {
  background-color: #2c7be5;
  border-color: #2c7be5;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"), var(--bs-gradient);
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"), var(--bs-gradient);
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #2c7be5;
  border-color: #2c7be5;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"), var(--bs-gradient);
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: .5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%239da9bb'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%239ec2f3'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"), var(--bs-gradient);
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.form-file {
  --bs-form-file-height: calc(1.5em + 0.625rem + 2px);
  position: relative;
}

.form-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: var(--bs-form-file-height);
  margin: 0;
  opacity: 0;
}

.form-file-input:focus-within ~ .form-file-label {
  border-color: #9ec2f3;
  box-shadow: 0 0 0 0.25rem rgba(44, 123, 229, 0.25);
}

.form-file-input[disabled] ~ .form-file-label .form-file-text,
.form-file-input:disabled ~ .form-file-label .form-file-text {
  background-color: #edf2f9;
}

.form-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  height: var(--bs-form-file-height);
  border-color: #d8e2ef;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

.form-file-text {
  display: block;
  flex-grow: 1;
  padding: 0.3125rem 1rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #344050;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #fff;
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.form-file-button {
  display: block;
  flex-shrink: 0;
  padding: 0.3125rem 1rem;
  margin-left: -1px;
  line-height: 1.5;
  color: #344050;
  background-color: #edf2f9;
  background-image: var(--bs-gradient);
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.form-file-sm {
  --bs-form-file-height: calc(1.5em + 0.375rem + 2px);
  font-size: 0.875rem;
}

.form-file-sm .form-file-text,
.form-file-sm .form-file-button {
  padding: 0.1875rem 0.75rem;
}

.form-file-lg {
  --bs-form-file-height: calc(1.5em + 0.75rem + 2px);
  font-size: 1.2rem;
}

.form-file-lg .form-file-text,
.form-file-lg .form-file-button {
  padding: 0.375rem 1.25rem;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: none;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #edf2f9, 0 0 0 0.25rem rgba(44, 123, 229, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #edf2f9, 0 0 0 0.25rem rgba(44, 123, 229, 0.25);
}

.form-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #edf2f9, 0 0 0 0.25rem rgba(44, 123, 229, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #2c7be5;
  background-image: var(--bs-gradient);
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #cbdef9;
  background-image: var(--bs-gradient);
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #d8e2ef;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #2c7be5;
  background-image: var(--bs-gradient);
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #cbdef9;
  background-image: var(--bs-gradient);
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #d8e2ef;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

.form-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  background-color: #2c7be5;
  background-image: var(--bs-gradient);
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-ms-thumb {
    transition: none;
  }
}

.form-range::-ms-thumb:active {
  background-color: #cbdef9;
  background-image: var(--bs-gradient);
}

.form-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

.form-range::-ms-fill-lower {
  background-color: #d8e2ef;
  border-radius: 1rem;
}

.form-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #d8e2ef;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #9da9bb;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #9da9bb;
}

.form-range:disabled::-ms-thumb {
  background-color: #9da9bb;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-file .form-file-input:focus ~ .form-file-label {
  z-index: 3;
}

.input-group > .form-file > .form-file-input:focus {
  z-index: 4;
}

.input-group > .form-file:not(:last-child) > .form-file-label {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-file:not(:first-child) > .form-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.3125rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #344050;
  text-align: center;
  white-space: nowrap;
  background-color: #edf2f9;
  border: 1px solid #d8e2ef;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}

.input-group-lg > .form-select {
  height: calc(1.5em + 0.75rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.375rem 1.25rem;
  font-size: 1.2rem;
  border-radius: 0.375rem;
}

.input-group-sm > .form-control {
  min-height: calc(1.5em + 0.375rem + 2px);
}

.input-group-sm > .form-select {
  height: calc(1.5em + 0.375rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.1875rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 2rem;
}

.input-group > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 75%;
  color: #00d27a;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.83333rem;
  color: #fff;
  background-color: rgba(0, 210, 122, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #00d27a;
  padding-right: calc(1.5em + 0.625rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300d27a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.15625rem) center;
  background-size: calc(0.75em + 0.3125rem) calc(0.75em + 0.3125rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #00d27a;
  box-shadow: 0 0 0 0.25rem rgba(0, 210, 122, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.625rem);
  background-position: top calc(0.375em + 0.15625rem) right calc(0.375em + 0.15625rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #00d27a;
  padding-right: calc(0.75em + 2.46875rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%234d5969' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300d27a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 2rem;
  background-size: 16px 12px, calc(0.75em + 0.3125rem) calc(0.75em + 0.3125rem);
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #00d27a;
  box-shadow: 0 0 0 0.25rem rgba(0, 210, 122, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #00d27a;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #00d27a;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 210, 122, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #00d27a;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .form-file-input:valid ~ .form-file-label, .form-file-input.is-valid ~ .form-file-label {
  border-color: #00d27a;
}

.was-validated .form-file-input:valid:focus ~ .form-file-label, .form-file-input.is-valid:focus ~ .form-file-label {
  border-color: #00d27a;
  box-shadow: 0 0 0 0.25rem rgba(0, 210, 122, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 75%;
  color: #e63757;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.83333rem;
  color: #fff;
  background-color: rgba(230, 55, 87, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #e63757;
  padding-right: calc(1.5em + 0.625rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e63757' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e63757' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.15625rem) center;
  background-size: calc(0.75em + 0.3125rem) calc(0.75em + 0.3125rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #e63757;
  box-shadow: 0 0 0 0.25rem rgba(230, 55, 87, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.625rem);
  background-position: top calc(0.375em + 0.15625rem) right calc(0.375em + 0.15625rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #e63757;
  padding-right: calc(0.75em + 2.46875rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%234d5969' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e63757' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e63757' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 2rem;
  background-size: 16px 12px, calc(0.75em + 0.3125rem) calc(0.75em + 0.3125rem);
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #e63757;
  box-shadow: 0 0 0 0.25rem rgba(230, 55, 87, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #e63757;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #e63757;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(230, 55, 87, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #e63757;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .form-file-input:invalid ~ .form-file-label, .form-file-input.is-invalid ~ .form-file-label {
  border-color: #e63757;
}

.was-validated .form-file-input:invalid:focus ~ .form-file-label, .form-file-input.is-invalid:focus ~ .form-file-label {
  border-color: #e63757;
  box-shadow: 0 0 0 0.25rem rgba(230, 55, 87, 0.25);
}

.btn {
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  color: #5e6e82;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.3125rem 1rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #5e6e82;
  text-decoration: none;
}

.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(44, 123, 229, 0.25);
}

.btn-check:checked + .btn,
.btn-check:active + .btn, .btn:active, .btn.active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-check:checked + .btn:focus,
.btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus {
  box-shadow: 0 0 0 0.25rem rgba(44, 123, 229, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn:disabled, .btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
  box-shadow: none;
}

.btn-primary, .navbar-vertical .btn-purchase {
  color: #fff;
  background-color: #2c7be5;
  background-image: var(--bs-gradient);
  border-color: #2c7be5;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-primary:hover, .navbar-vertical .btn-purchase:hover {
  color: #fff;
  background-color: #1a68d1;
  background-image: var(--bs-gradient);
  border-color: #1862c6;
}

.btn-check:focus + .btn-primary, .navbar-vertical .btn-check:focus + .btn-purchase, .btn-primary:focus, .navbar-vertical .btn-purchase:focus {
  color: #fff;
  background-color: #1a68d1;
  background-image: var(--bs-gradient);
  border-color: #1862c6;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0 rgba(76, 143, 233, 0.5);
}

.btn-check:checked + .btn-primary, .navbar-vertical .btn-check:checked + .btn-purchase,
.btn-check:active + .btn-primary,
.navbar-vertical .btn-check:active + .btn-purchase, .btn-primary:active, .navbar-vertical .btn-purchase:active, .btn-primary.active, .navbar-vertical .active.btn-purchase,
.show > .btn-primary.dropdown-toggle,
.navbar-vertical .show > .dropdown-toggle.btn-purchase {
  color: #fff;
  background-color: #1862c6;
  background-image: none;
  border-color: #175dba;
}

.btn-check:checked + .btn-primary:focus, .navbar-vertical .btn-check:checked + .btn-purchase:focus,
.btn-check:active + .btn-primary:focus,
.navbar-vertical .btn-check:active + .btn-purchase:focus, .btn-primary:active:focus, .navbar-vertical .btn-purchase:active:focus, .btn-primary.active:focus, .navbar-vertical .active.btn-purchase:focus,
.show > .btn-primary.dropdown-toggle:focus,
.navbar-vertical .show > .dropdown-toggle.btn-purchase:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(76, 143, 233, 0.5);
}

.btn-primary:disabled, .navbar-vertical .btn-purchase:disabled, .btn-primary.disabled, .navbar-vertical .disabled.btn-purchase {
  color: #fff;
  background-color: #2c7be5;
  background-image: none;
  border-color: #2c7be5;
}

.btn-secondary {
  color: #fff;
  background-color: #748194;
  background-image: var(--bs-gradient);
  border-color: #748194;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-secondary:hover {
  color: #fff;
  background-color: #626e80;
  background-image: var(--bs-gradient);
  border-color: #5d6878;
}

.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #626e80;
  background-image: var(--bs-gradient);
  border-color: #5d6878;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0 rgba(137, 148, 164, 0.5);
}

.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #5d6878;
  background-image: none;
  border-color: #576271;
}

.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(137, 148, 164, 0.5);
}

.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #748194;
  background-image: none;
  border-color: #748194;
}

.btn-success {
  color: #fff;
  background-color: #00d27a;
  background-image: var(--bs-gradient);
  border-color: #00d27a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-success:hover {
  color: #fff;
  background-color: #00ac64;
  background-image: var(--bs-gradient);
  border-color: #009f5c;
}

.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #00ac64;
  background-image: var(--bs-gradient);
  border-color: #009f5c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0 rgba(38, 217, 142, 0.5);
}

.btn-check:checked + .btn-success,
.btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #009f5c;
  background-image: none;
  border-color: #009255;
}

.btn-check:checked + .btn-success:focus,
.btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(38, 217, 142, 0.5);
}

.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #00d27a;
  background-image: none;
  border-color: #00d27a;
}

.btn-info {
  color: #fff;
  background-color: #27bcfd;
  background-image: var(--bs-gradient);
  border-color: #27bcfd;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-info:hover {
  color: #fff;
  background-color: #02b0fb;
  background-image: var(--bs-gradient);
  border-color: #02a7ef;
}

.btn-check:focus + .btn-info, .btn-info:focus {
  color: #fff;
  background-color: #02b0fb;
  background-image: var(--bs-gradient);
  border-color: #02a7ef;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0 rgba(71, 198, 253, 0.5);
}

.btn-check:checked + .btn-info,
.btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #02a7ef;
  background-image: none;
  border-color: #029ee2;
}

.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(71, 198, 253, 0.5);
}

.btn-info:disabled, .btn-info.disabled {
  color: #fff;
  background-color: #27bcfd;
  background-image: none;
  border-color: #27bcfd;
}

.btn-warning {
  color: #fff;
  background-color: #f5803e;
  background-image: var(--bs-gradient);
  border-color: #f5803e;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-warning:hover {
  color: #fff;
  background-color: #f3681a;
  background-image: var(--bs-gradient);
  border-color: #f2600e;
}

.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #fff;
  background-color: #f3681a;
  background-image: var(--bs-gradient);
  border-color: #f2600e;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0 rgba(247, 147, 91, 0.5);
}

.btn-check:checked + .btn-warning,
.btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #f2600e;
  background-image: none;
  border-color: #e75b0c;
}

.btn-check:checked + .btn-warning:focus,
.btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(247, 147, 91, 0.5);
}

.btn-warning:disabled, .btn-warning.disabled {
  color: #fff;
  background-color: #f5803e;
  background-image: none;
  border-color: #f5803e;
}

.btn-danger {
  color: #fff;
  background-color: #e63757;
  background-image: var(--bs-gradient);
  border-color: #e63757;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-danger:hover {
  color: #fff;
  background-color: #db1b3f;
  background-image: var(--bs-gradient);
  border-color: #d01a3b;
}

.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #db1b3f;
  background-image: var(--bs-gradient);
  border-color: #d01a3b;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0 rgba(234, 85, 112, 0.5);
}

.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #d01a3b;
  background-image: none;
  border-color: #c51938;
}

.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(234, 85, 112, 0.5);
}

.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #e63757;
  background-image: none;
  border-color: #e63757;
}

.btn-light, .navbar-vertical.navbar-vibrant .btn-purchase {
  color: #4d5969;
  background-color: #f9fafd;
  background-image: var(--bs-gradient);
  border-color: #f9fafd;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-light:hover, .navbar-vertical.navbar-vibrant .btn-purchase:hover {
  color: #4d5969;
  background-color: white;
  background-image: var(--bs-gradient);
  border-color: white;
}

.btn-check:focus + .btn-light, .navbar-vertical.navbar-vibrant .btn-check:focus + .btn-purchase, .btn-light:focus, .navbar-vertical.navbar-vibrant .btn-purchase:focus {
  color: #4d5969;
  background-color: white;
  background-image: var(--bs-gradient);
  border-color: white;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0 rgba(223, 226, 231, 0.5);
}

.btn-check:checked + .btn-light, .navbar-vertical.navbar-vibrant .btn-check:checked + .btn-purchase,
.btn-check:active + .btn-light,
.navbar-vertical.navbar-vibrant .btn-check:active + .btn-purchase, .btn-light:active, .navbar-vertical.navbar-vibrant .btn-purchase:active, .btn-light.active, .navbar-vertical.navbar-vibrant .active.btn-purchase,
.show > .btn-light.dropdown-toggle,
.navbar-vertical.navbar-vibrant .show > .dropdown-toggle.btn-purchase {
  color: #4d5969;
  background-color: white;
  background-image: none;
  border-color: white;
}

.btn-check:checked + .btn-light:focus, .navbar-vertical.navbar-vibrant .btn-check:checked + .btn-purchase:focus,
.btn-check:active + .btn-light:focus,
.navbar-vertical.navbar-vibrant .btn-check:active + .btn-purchase:focus, .btn-light:active:focus, .navbar-vertical.navbar-vibrant .btn-purchase:active:focus, .btn-light.active:focus, .navbar-vertical.navbar-vibrant .active.btn-purchase:focus,
.show > .btn-light.dropdown-toggle:focus,
.navbar-vertical.navbar-vibrant .show > .dropdown-toggle.btn-purchase:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(223, 226, 231, 0.5);
}

.btn-light:disabled, .navbar-vertical.navbar-vibrant .btn-purchase:disabled, .btn-light.disabled, .navbar-vertical.navbar-vibrant .disabled.btn-purchase {
  color: #4d5969;
  background-color: #f9fafd;
  background-image: none;
  border-color: #f9fafd;
}

.btn-dark {
  color: #fff;
  background-color: #0b1727;
  background-image: var(--bs-gradient);
  border-color: #0b1727;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-dark:hover {
  color: #fff;
  background-color: #030509;
  background-image: var(--bs-gradient);
  border-color: black;
}

.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #030509;
  background-image: var(--bs-gradient);
  border-color: black;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0 rgba(48, 58, 71, 0.5);
}

.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: black;
  background-image: none;
  border-color: black;
}

.btn-check:checked + .btn-dark:focus,
.btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(48, 58, 71, 0.5);
}

.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #0b1727;
  background-image: none;
  border-color: #0b1727;
}

.btn-outline-primary {
  color: #2c7be5;
  border-color: #2c7be5;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #2c7be5;
  border-color: #2c7be5;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0 rgba(44, 123, 229, 0.5);
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #2c7be5;
  border-color: #2c7be5;
}

.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(44, 123, 229, 0.5);
}

.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #2c7be5;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #748194;
  border-color: #748194;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #748194;
  border-color: #748194;
}

.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0 rgba(116, 129, 148, 0.5);
}

.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #748194;
  border-color: #748194;
}

.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(116, 129, 148, 0.5);
}

.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #748194;
  background-color: transparent;
}

.btn-outline-success {
  color: #00d27a;
  border-color: #00d27a;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #00d27a;
  border-color: #00d27a;
}

.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0 rgba(0, 210, 122, 0.5);
}

.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #00d27a;
  border-color: #00d27a;
}

.btn-check:checked + .btn-outline-success:focus,
.btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(0, 210, 122, 0.5);
}

.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #00d27a;
  background-color: transparent;
}

.btn-outline-info {
  color: #27bcfd;
  border-color: #27bcfd;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #27bcfd;
  border-color: #27bcfd;
}

.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0 rgba(39, 188, 253, 0.5);
}

.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #fff;
  background-color: #27bcfd;
  border-color: #27bcfd;
}

.btn-check:checked + .btn-outline-info:focus,
.btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(39, 188, 253, 0.5);
}

.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #27bcfd;
  background-color: transparent;
}

.btn-outline-warning {
  color: #f5803e;
  border-color: #f5803e;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #f5803e;
  border-color: #f5803e;
}

.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0 rgba(245, 128, 62, 0.5);
}

.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #fff;
  background-color: #f5803e;
  border-color: #f5803e;
}

.btn-check:checked + .btn-outline-warning:focus,
.btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(245, 128, 62, 0.5);
}

.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #f5803e;
  background-color: transparent;
}

.btn-outline-danger {
  color: #e63757;
  border-color: #e63757;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #e63757;
  border-color: #e63757;
}

.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0 rgba(230, 55, 87, 0.5);
}

.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #e63757;
  border-color: #e63757;
}

.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(230, 55, 87, 0.5);
}

.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #e63757;
  background-color: transparent;
}

.btn-outline-light {
  color: #f9fafd;
  border-color: #f9fafd;
}

.btn-outline-light:hover {
  color: #4d5969;
  background-color: #f9fafd;
  border-color: #f9fafd;
}

.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0 rgba(249, 250, 253, 0.5);
}

.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #4d5969;
  background-color: #f9fafd;
  border-color: #f9fafd;
}

.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(249, 250, 253, 0.5);
}

.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f9fafd;
  background-color: transparent;
}

.btn-outline-dark {
  color: #0b1727;
  border-color: #0b1727;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #0b1727;
  border-color: #0b1727;
}

.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0 rgba(11, 23, 39, 0.5);
}

.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #0b1727;
  border-color: #0b1727;
}

.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(11, 23, 39, 0.5);
}

.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #0b1727;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #2c7be5;
  text-decoration: none;
}

.btn-link:hover {
  color: #1657af;
  text-decoration: underline;
}

.btn-link:focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #748194;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.375rem 1.25rem;
  font-size: 1.2rem;
  border-radius: 0.375rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.1875rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 1rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.8rem;
  color: #5e6e82;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f9fafd;
  border-radius: 0.25rem;
  box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1540px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #edf2f9;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #5e6e82;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #344050;
  text-decoration: none;
  background-color: #edf2f9;
  background-image: var(--bs-gradient);
}

.dropdown-item.active, .dropdown-item:active {
  color: #232e3c;
  text-decoration: none;
  background-color: #d8e2ef;
  background-image: var(--bs-gradient);
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #9da9bb;
  pointer-events: none;
  background-color: transparent;
  background-image: none;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 1rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #748194;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #5e6e82;
}

.dropdown-menu-dark {
  color: #d8e2ef;
  background-color: #4d5969;
  border-color: #f9fafd;
}

.dropdown-menu-dark .dropdown-item {
  color: #d8e2ef;
}

.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
  background-image: var(--bs-gradient);
}

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #232e3c;
  background-color: #d8e2ef;
  background-image: var(--bs-gradient);
}

.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #9da9bb;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: #edf2f9;
}

.dropdown-menu-dark .dropdown-item-text {
  color: #d8e2ef;
}

.dropdown-menu-dark .dropdown-header {
  color: #9da9bb;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #748194;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #d8e2ef;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #edf2f9 #edf2f9 #d8e2ef;
}

.nav-tabs .nav-link.disabled {
  color: #748194;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #5e6e82;
  background-color: #edf2f9;
  border-color: #d8e2ef #d8e2ef #edf2f9;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2c7be5;
  background-image: var(--bs-gradient);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  background-image: var(--bs-gradient);
}

.navbar > .container,
.navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: -0.046rem;
  padding-bottom: -0.046rem;
  margin-right: 1rem;
  font-size: calc(1.2978rem + 0.5736vw);
  white-space: nowrap;
}

@media (min-width: 1200px) {
  .navbar-brand {
    font-size: 1.728rem;
  }
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  align-items: center;
  width: 100%;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.2rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1540px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: #2c7be5;
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #2c7be5;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.25);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: #2c7be5;
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M0 6h30M0 14h30M0 22h30'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: #2c7be5;
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.9);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.7%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid #edf2f9;
  border-radius: 0.375rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.375rem - 0px);
  border-top-right-radius: calc(0.375rem - 0px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.375rem - 0px);
  border-bottom-left-radius: calc(0.375rem - 0px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem 1.25rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 1rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 0px solid #edf2f9;
}

.card-header:first-child {
  border-radius: calc(0.375rem - 0px) calc(0.375rem - 0px) 0 0;
}

.card-footer {
  padding: 1rem 1.25rem;
  background-color: #fff;
  border-top: 0px solid #edf2f9;
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.375rem - 0px) calc(0.375rem - 0px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -1rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: #fff;
  border-bottom-color: #fff;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.375rem - 0px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.375rem - 0px);
  border-top-right-radius: calc(0.375rem - 0px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.375rem - 0px);
  border-bottom-left-radius: calc(0.375rem - 0px);
}

.card-group > .card {
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: 0px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 0;
  margin-bottom: 0;
  list-style: none;
  background-color: "transparent";
  border-radius: 0.25rem;
}

.breadcrumb-item {
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #748194;
  content: "»";
}

.breadcrumb-item.active {
  color: #748194;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #000;
  background-color: #fff;
  border: 1px solid #d8e2ef;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #2c7be5;
  border-color: #2c7be5;
}

.page-link:focus {
  z-index: 3;
  color: #1657af;
  background-color: #edf2f9;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(44, 123, 229, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #2c7be5;
  background-image: var(--bs-gradient);
  border-color: #2c7be5;
}

.page-item.disabled .page-link {
  color: #748194;
  pointer-events: none;
  background-color: #fff;
  border-color: #d8e2ef;
}

.page-link {
  padding: 0.5rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.2rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35556em 0.71111em;
  font-size: 0.75em;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  background-image: var(--bs-gradient);
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #174077;
  background-color: #d5e5fa;
  background-image: var(--bs-gradient);
  border-color: #c4daf8;
}

.alert-primary .alert-link {
  color: #0f294c;
}

.alert-secondary {
  color: #3c434d;
  background-color: #e3e6ea;
  background-image: var(--bs-gradient);
  border-color: #d8dce1;
}

.alert-secondary .alert-link {
  color: #262a30;
}

.alert-success {
  color: #006d3f;
  background-color: #ccf6e4;
  background-image: var(--bs-gradient);
  border-color: #b8f2da;
}

.alert-success .alert-link {
  color: #003a22;
}

.alert-info {
  color: #146284;
  background-color: #d4f2ff;
  background-image: var(--bs-gradient);
  border-color: #c3ecfe;
}

.alert-info .alert-link {
  color: #0d4158;
}

.alert-warning {
  color: #7f4320;
  background-color: #fde6d8;
  background-image: var(--bs-gradient);
  border-color: #fcdbc9;
}

.alert-warning .alert-link {
  color: #562e16;
}

.alert-danger {
  color: #781d2d;
  background-color: #fad7dd;
  background-image: var(--bs-gradient);
  border-color: #f8c7d0;
}

.alert-danger .alert-link {
  color: #4f131e;
}

.alert-light {
  color: #818284;
  background-color: #fefeff;
  background-image: var(--bs-gradient);
  border-color: #fdfefe;
}

.alert-light .alert-link {
  color: #68696a;
}

.alert-dark {
  color: #060c14;
  background-color: #ced1d4;
  background-image: var(--bs-gradient);
  border-color: #bbbec3;
}

.alert-dark .alert-link {
  color: black;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #edf2f9;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #2c7be5;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #5e6e82;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #5e6e82;
  text-decoration: none;
  background-color: #f9fafd;
}

.list-group-item-action:active {
  color: #5e6e82;
  background-color: #edf2f9;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border: 1px solid #d8e2ef;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #748194;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #2c7be5;
  border-color: #2c7be5;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1540px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #174077;
  background-color: #c4daf8;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #174077;
  background-color: #adccf5;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #174077;
  border-color: #174077;
}

.list-group-item-secondary {
  color: #3c434d;
  background-color: #d8dce1;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #3c434d;
  background-color: #cacfd6;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #3c434d;
  border-color: #3c434d;
}

.list-group-item-success {
  color: #006d3f;
  background-color: #b8f2da;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #006d3f;
  background-color: #a2eecf;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #006d3f;
  border-color: #006d3f;
}

.list-group-item-info {
  color: #146284;
  background-color: #c3ecfe;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #146284;
  background-color: #aae4fe;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #146284;
  border-color: #146284;
}

.list-group-item-warning {
  color: #7f4320;
  background-color: #fcdbc9;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #7f4320;
  background-color: #fbcbb1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #7f4320;
  border-color: #7f4320;
}

.list-group-item-danger {
  color: #781d2d;
  background-color: #f8c7d0;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #781d2d;
  background-color: #f5b0bd;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #781d2d;
  border-color: #781d2d;
}

.list-group-item-light {
  color: #818284;
  background-color: #fdfefe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818284;
  background-color: #ecf6f6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818284;
  border-color: #818284;
}

.list-group-item-dark {
  color: #060c14;
  background-color: #bbbec3;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #060c14;
  background-color: #adb1b7;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #060c14;
  border-color: #060c14;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 16 16'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") no-repeat center center/1em auto;
  background-clip: content-box;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: none;
  box-shadow: 0 0 0 0.25rem rgba(44, 123, 229, 0.25);
  opacity: 1;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #748194;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.375rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #d8e2ef;
  border-top-left-radius: calc(0.375rem - 1px);
  border-top-right-radius: calc(0.375rem - 1px);
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #d8e2ef;
  border-bottom-right-radius: calc(0.375rem - 1px);
  border-bottom-left-radius: calc(0.375rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-content {
    box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1539.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.83333rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[x-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="top"] .tooltip-arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .tooltip-arrow, .bs-tooltip-auto[x-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="right"] .tooltip-arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .tooltip-arrow, .bs-tooltip-auto[x-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="left"] .tooltip-arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.5rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.375rem;
  box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.375rem;
}

.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[x-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[x-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .popover-arrow::after, .bs-popover-auto[x-placement^="top"] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .popover-arrow, .bs-popover-auto[x-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.375rem 0;
}

.bs-popover-right > .popover-arrow::before, .bs-popover-auto[x-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .popover-arrow::after, .bs-popover-auto[x-placement^="right"] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .popover-arrow, .bs-popover-auto[x-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.375rem 0;
}

.bs-popover-left > .popover-arrow::before, .bs-popover-auto[x-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .popover-arrow::after, .bs-popover-auto[x-placement^="left"] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #344050;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.375rem - 1px);
  border-top-right-radius: calc(0.375rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #5e6e82;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.8s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.8s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001));
}

.carousel-control-next {
  right: 0;
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001));
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators li {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1.35rem;
  height: 1.35rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1.35rem;
  height: 1.35rem;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #2c7be5;
}

.link-primary:hover, .link-primary:focus {
  color: #1657af;
}

.link-secondary {
  color: #748194;
}

.link-secondary:hover, .link-secondary:focus {
  color: #525b6a;
}

.link-success {
  color: #00d27a;
}

.link-success:hover, .link-success:focus {
  color: #00864e;
}

.link-info {
  color: #27bcfd;
}

.link-info:hover, .link-info:focus {
  color: #0295d6;
}

.link-warning {
  color: #f5803e;
}

.link-warning:hover, .link-warning:focus {
  color: #db560b;
}

.link-danger {
  color: #e63757;
}

.link-danger:hover, .link-danger:focus {
  color: #b91735;
}

.link-light {
  color: #f9fafd;
}

.link-light:hover, .link-light:focus {
  color: white;
}

.link-dark {
  color: #0b1727;
}

.link-dark:hover, .link-dark:focus {
  color: black;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--aspect-ratio);
  content: "";
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --aspect-ratio: 100%;
}

.ratio-4x3 {
  --aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1015;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1015;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1015;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1015;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1015;
  }
}

@media (min-width: 1540px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1015;
  }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-absolute {
  position: absolute !important;
}

.position-relative {
  position: relative !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.left-0 {
  left: 0 !important;
}

.left-50 {
  left: 50% !important;
}

.left-100 {
  left: 100% !important;
}

.right-0 {
  right: 0 !important;
}

.right-50 {
  right: 50% !important;
}

.right-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translateX(-50%) translateY(-50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #d8e2ef !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #d8e2ef !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right {
  border-right: 1px solid #d8e2ef !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #d8e2ef !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left {
  border-left: 1px solid #d8e2ef !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-facebook {
  border-color: #3c5a99 !important;
}

.border-google-plus {
  border-color: #dd4b39 !important;
}

.border-twitter {
  border-color: #1da1f2 !important;
}

.border-linkedin {
  border-color: #0077b5 !important;
}

.border-youtube {
  border-color: #ff0000 !important;
}

.border-black {
  border-color: #000 !important;
}

.border-100 {
  border-color: #f9fafd !important;
}

.border-200 {
  border-color: #edf2f9 !important;
}

.border-300 {
  border-color: #d8e2ef !important;
}

.border-400 {
  border-color: #b6c1d2 !important;
}

.border-500 {
  border-color: #9da9bb !important;
}

.border-600 {
  border-color: #748194 !important;
}

.border-700 {
  border-color: #5e6e82 !important;
}

.border-800 {
  border-color: #4d5969 !important;
}

.border-900 {
  border-color: #344050 !important;
}

.border-1000 {
  border-color: #232e3c !important;
}

.border-1100 {
  border-color: #0b1727 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-primary {
  border-color: #2c7be5 !important;
}

.border-secondary {
  border-color: #748194 !important;
}

.border-success {
  border-color: #00d27a !important;
}

.border-info {
  border-color: #27bcfd !important;
}

.border-warning {
  border-color: #f5803e !important;
}

.border-danger {
  border-color: #e63757 !important;
}

.border-light {
  border-color: #f9fafd !important;
}

.border-dark {
  border-color: #0b1727 !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-25 {
  width: 25vw !important;
}

.vw-50 {
  width: 50vw !important;
}

.vw-75 {
  width: 75vw !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-25 {
  height: 25vh !important;
}

.vh-50 {
  height: 50vh !important;
}

.vh-75 {
  height: 75vh !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-25 {
  min-height: 25vh !important;
}

.min-vh-50 {
  min-height: 50vh !important;
}

.min-vh-75 {
  min-height: 75vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.8rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-6 {
  margin: 4rem !important;
}

.m-7 {
  margin: 5rem !important;
}

.m-8 {
  margin: 7.5rem !important;
}

.m-9 {
  margin: 10rem !important;
}

.m-10 {
  margin: 12.5rem !important;
}

.m-11 {
  margin: 15rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.8rem !important;
  margin-left: 1.8rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-6 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-7 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-8 {
  margin-right: 7.5rem !important;
  margin-left: 7.5rem !important;
}

.mx-9 {
  margin-right: 10rem !important;
  margin-left: 10rem !important;
}

.mx-10 {
  margin-right: 12.5rem !important;
  margin-left: 12.5rem !important;
}

.mx-11 {
  margin-right: 15rem !important;
  margin-left: 15rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.8rem !important;
  margin-bottom: 1.8rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-6 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-7 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-8 {
  margin-top: 7.5rem !important;
  margin-bottom: 7.5rem !important;
}

.my-9 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-10 {
  margin-top: 12.5rem !important;
  margin-bottom: 12.5rem !important;
}

.my-11 {
  margin-top: 15rem !important;
  margin-bottom: 15rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.8rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 4rem !important;
}

.mt-7 {
  margin-top: 5rem !important;
}

.mt-8 {
  margin-top: 7.5rem !important;
}

.mt-9 {
  margin-top: 10rem !important;
}

.mt-10 {
  margin-top: 12.5rem !important;
}

.mt-11 {
  margin-top: 15rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.8rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.mr-6 {
  margin-right: 4rem !important;
}

.mr-7 {
  margin-right: 5rem !important;
}

.mr-8 {
  margin-right: 7.5rem !important;
}

.mr-9 {
  margin-right: 10rem !important;
}

.mr-10 {
  margin-right: 12.5rem !important;
}

.mr-11 {
  margin-right: 15rem !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.8rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 4rem !important;
}

.mb-7 {
  margin-bottom: 5rem !important;
}

.mb-8 {
  margin-bottom: 7.5rem !important;
}

.mb-9 {
  margin-bottom: 10rem !important;
}

.mb-10 {
  margin-bottom: 12.5rem !important;
}

.mb-11 {
  margin-bottom: 15rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 1.8rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.ml-6 {
  margin-left: 4rem !important;
}

.ml-7 {
  margin-left: 5rem !important;
}

.ml-8 {
  margin-left: 7.5rem !important;
}

.ml-9 {
  margin-left: 10rem !important;
}

.ml-10 {
  margin-left: 12.5rem !important;
}

.ml-11 {
  margin-left: 15rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.8rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.m-n6 {
  margin: -4rem !important;
}

.m-n7 {
  margin: -5rem !important;
}

.m-n8 {
  margin: -7.5rem !important;
}

.m-n9 {
  margin: -10rem !important;
}

.m-n10 {
  margin: -12.5rem !important;
}

.m-n11 {
  margin: -15rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.8rem !important;
  margin-left: -1.8rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n6 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-n7 {
  margin-right: -5rem !important;
  margin-left: -5rem !important;
}

.mx-n8 {
  margin-right: -7.5rem !important;
  margin-left: -7.5rem !important;
}

.mx-n9 {
  margin-right: -10rem !important;
  margin-left: -10rem !important;
}

.mx-n10 {
  margin-right: -12.5rem !important;
  margin-left: -12.5rem !important;
}

.mx-n11 {
  margin-right: -15rem !important;
  margin-left: -15rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.8rem !important;
  margin-bottom: -1.8rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n6 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n7 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-n8 {
  margin-top: -7.5rem !important;
  margin-bottom: -7.5rem !important;
}

.my-n9 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.my-n10 {
  margin-top: -12.5rem !important;
  margin-bottom: -12.5rem !important;
}

.my-n11 {
  margin-top: -15rem !important;
  margin-bottom: -15rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.8rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.mt-n6 {
  margin-top: -4rem !important;
}

.mt-n7 {
  margin-top: -5rem !important;
}

.mt-n8 {
  margin-top: -7.5rem !important;
}

.mt-n9 {
  margin-top: -10rem !important;
}

.mt-n10 {
  margin-top: -12.5rem !important;
}

.mt-n11 {
  margin-top: -15rem !important;
}

.mr-n1 {
  margin-right: -0.25rem !important;
}

.mr-n2 {
  margin-right: -0.5rem !important;
}

.mr-n3 {
  margin-right: -1rem !important;
}

.mr-n4 {
  margin-right: -1.8rem !important;
}

.mr-n5 {
  margin-right: -3rem !important;
}

.mr-n6 {
  margin-right: -4rem !important;
}

.mr-n7 {
  margin-right: -5rem !important;
}

.mr-n8 {
  margin-right: -7.5rem !important;
}

.mr-n9 {
  margin-right: -10rem !important;
}

.mr-n10 {
  margin-right: -12.5rem !important;
}

.mr-n11 {
  margin-right: -15rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.8rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.mb-n6 {
  margin-bottom: -4rem !important;
}

.mb-n7 {
  margin-bottom: -5rem !important;
}

.mb-n8 {
  margin-bottom: -7.5rem !important;
}

.mb-n9 {
  margin-bottom: -10rem !important;
}

.mb-n10 {
  margin-bottom: -12.5rem !important;
}

.mb-n11 {
  margin-bottom: -15rem !important;
}

.ml-n1 {
  margin-left: -0.25rem !important;
}

.ml-n2 {
  margin-left: -0.5rem !important;
}

.ml-n3 {
  margin-left: -1rem !important;
}

.ml-n4 {
  margin-left: -1.8rem !important;
}

.ml-n5 {
  margin-left: -3rem !important;
}

.ml-n6 {
  margin-left: -4rem !important;
}

.ml-n7 {
  margin-left: -5rem !important;
}

.ml-n8 {
  margin-left: -7.5rem !important;
}

.ml-n9 {
  margin-left: -10rem !important;
}

.ml-n10 {
  margin-left: -12.5rem !important;
}

.ml-n11 {
  margin-left: -15rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.8rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-6 {
  padding: 4rem !important;
}

.p-7 {
  padding: 5rem !important;
}

.p-8 {
  padding: 7.5rem !important;
}

.p-9 {
  padding: 10rem !important;
}

.p-10 {
  padding: 12.5rem !important;
}

.p-11 {
  padding: 15rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.8rem !important;
  padding-left: 1.8rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-6 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-7 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-8 {
  padding-right: 7.5rem !important;
  padding-left: 7.5rem !important;
}

.px-9 {
  padding-right: 10rem !important;
  padding-left: 10rem !important;
}

.px-10 {
  padding-right: 12.5rem !important;
  padding-left: 12.5rem !important;
}

.px-11 {
  padding-right: 15rem !important;
  padding-left: 15rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.8rem !important;
  padding-bottom: 1.8rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-6 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-7 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-8 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}

.py-9 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.py-10 {
  padding-top: 12.5rem !important;
  padding-bottom: 12.5rem !important;
}

.py-11 {
  padding-top: 15rem !important;
  padding-bottom: 15rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.8rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-6 {
  padding-top: 4rem !important;
}

.pt-7 {
  padding-top: 5rem !important;
}

.pt-8 {
  padding-top: 7.5rem !important;
}

.pt-9 {
  padding-top: 10rem !important;
}

.pt-10 {
  padding-top: 12.5rem !important;
}

.pt-11 {
  padding-top: 15rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pr-4 {
  padding-right: 1.8rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.pr-6 {
  padding-right: 4rem !important;
}

.pr-7 {
  padding-right: 5rem !important;
}

.pr-8 {
  padding-right: 7.5rem !important;
}

.pr-9 {
  padding-right: 10rem !important;
}

.pr-10 {
  padding-right: 12.5rem !important;
}

.pr-11 {
  padding-right: 15rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.8rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-6 {
  padding-bottom: 4rem !important;
}

.pb-7 {
  padding-bottom: 5rem !important;
}

.pb-8 {
  padding-bottom: 7.5rem !important;
}

.pb-9 {
  padding-bottom: 10rem !important;
}

.pb-10 {
  padding-bottom: 12.5rem !important;
}

.pb-11 {
  padding-bottom: 15rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pl-4 {
  padding-left: 1.8rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

.pl-6 {
  padding-left: 4rem !important;
}

.pl-7 {
  padding-left: 5rem !important;
}

.pl-8 {
  padding-left: 7.5rem !important;
}

.pl-9 {
  padding-left: 10rem !important;
}

.pl-10 {
  padding-left: 12.5rem !important;
}

.pl-11 {
  padding-left: 15rem !important;
}

.font-weight-thin {
  font-weight: 100 !important;
}

.font-weight-lighter {
  font-weight: 200 !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: 800 !important;
}

.font-weight-black {
  font-weight: 900 !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-primary {
  color: #2c7be5 !important;
}

.text-secondary {
  color: #748194 !important;
}

.text-success {
  color: #00d27a !important;
}

.text-info {
  color: #27bcfd !important;
}

.text-warning {
  color: #f5803e !important;
}

.text-danger {
  color: #e63757 !important;
}

.text-light {
  color: #f9fafd !important;
}

.text-dark {
  color: #0b1727 !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #5e6e82 !important;
}

.text-muted {
  color: #9da9bb !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.bg-facebook {
  background-color: #3c5a99 !important;
}

.bg-google-plus {
  background-color: #dd4b39 !important;
}

.bg-twitter {
  background-color: #1da1f2 !important;
}

.bg-linkedin {
  background-color: #0077b5 !important;
}

.bg-youtube {
  background-color: #ff0000 !important;
}

.bg-black {
  background-color: #000 !important;
}

.bg-100 {
  background-color: #f9fafd !important;
}

.bg-200 {
  background-color: #edf2f9 !important;
}

.bg-300 {
  background-color: #d8e2ef !important;
}

.bg-400 {
  background-color: #b6c1d2 !important;
}

.bg-500 {
  background-color: #9da9bb !important;
}

.bg-600 {
  background-color: #748194 !important;
}

.bg-700 {
  background-color: #5e6e82 !important;
}

.bg-800 {
  background-color: #4d5969 !important;
}

.bg-900 {
  background-color: #344050 !important;
}

.bg-1000 {
  background-color: #232e3c !important;
}

.bg-1100 {
  background-color: #0b1727 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-primary {
  background-color: #2c7be5 !important;
}

.bg-secondary {
  background-color: #748194 !important;
}

.bg-success {
  background-color: #00d27a !important;
}

.bg-info {
  background-color: #27bcfd !important;
}

.bg-warning {
  background-color: #f5803e !important;
}

.bg-danger {
  background-color: #e63757 !important;
}

.bg-light {
  background-color: #f9fafd !important;
}

.bg-dark {
  background-color: #0b1727 !important;
}

.bg-body {
  background-color: #edf2f9 !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.font-italic {
  font-style: italic !important;
}

.font-normal {
  font-style: normal !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded-lg {
  border-radius: 0.375rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-top-lg {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-right-lg {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.rounded-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-bottom-lg {
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-left {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.rounded-left-lg {
  border-bottom-left-radius: 0.375rem !important;
  border-top-left-radius: 0.375rem !important;
}

.rounded-left-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-85 {
  opacity: 0.85 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.text-facebook {
  color: #3c5a99 !important;
}

.text-google-plus {
  color: #dd4b39 !important;
}

.text-twitter {
  color: #1da1f2 !important;
}

.text-linkedin {
  color: #0077b5 !important;
}

.text-youtube {
  color: #ff0000 !important;
}

.text-black {
  color: #000 !important;
}

.text-100 {
  color: #f9fafd !important;
}

.text-200 {
  color: #edf2f9 !important;
}

.text-300 {
  color: #d8e2ef !important;
}

.text-400 {
  color: #b6c1d2 !important;
}

.text-500 {
  color: #9da9bb !important;
}

.text-600 {
  color: #748194 !important;
}

.text-700 {
  color: #5e6e82 !important;
}

.text-800 {
  color: #4d5969 !important;
}

.text-900 {
  color: #344050 !important;
}

.text-1000 {
  color: #232e3c !important;
}

.text-1100 {
  color: #0b1727 !important;
}

.text-white {
  color: #fff !important;
}

.fs--2 {
  font-size: 0.69444rem !important;
}

.fs--1 {
  font-size: 0.83333rem !important;
}

.fs-0 {
  font-size: 1rem !important;
}

.fs-1 {
  font-size: 1.2rem !important;
}

.fs-2 {
  font-size: 1.44rem !important;
}

.fs-3 {
  font-size: 1.728rem !important;
}

.fs-4 {
  font-size: 2.0736rem !important;
}

.fs-5 {
  font-size: 2.48832rem !important;
}

.fs-6 {
  font-size: 2.98598rem !important;
}

.fs-7 {
  font-size: 3.58318rem !important;
}

.fs-8 {
  font-size: 4.29982rem !important;
}

.max-vh-25 {
  max-height: 25vh !important;
}

.max-vh-50 {
  max-height: 50vh !important;
}

.max-vh-75 {
  max-height: 75vh !important;
}

.max-vh-100 {
  max-height: 100vh !important;
}

.border-x {
  border-left: 1px solid #d8e2ef !important;
  border-right: 1px solid #d8e2ef !important;
}

.border-x-0 {
  border-left: 0 !important;
  border-right: 0 !important;
}

.border-y {
  border-top: 1px solid #d8e2ef !important;
  border-bottom: 1px solid #d8e2ef !important;
}

.border-y-0 {
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.border-dashed {
  border: 1px dashed #d8e2ef !important;
}

.border-dashed-top {
  border-top: 1px dashed #d8e2ef !important;
}

.border-dashed-right {
  border-right: 1px dashed #d8e2ef !important;
}

.border-dashed-left {
  border-left: 1px dashed #d8e2ef !important;
}

.border-dashed-bottom {
  border-bottom: 1px dashed #d8e2ef !important;
}

.border-dashed-x {
  border-left: 1px dashed #d8e2ef !important;
  border-right: 1px dashed #d8e2ef !important;
}

.border-dashed-y {
  border-top: 1px dashed #d8e2ef !important;
  border-bottom: 1px dashed #d8e2ef !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.bg-soft-primary, .fc-timegrid .event-bg-soft-primary {
  background-color: #e6effc !important;
}

.bg-soft-secondary, .fc-timegrid .event-bg-soft-secondary {
  background-color: #eef0f2 !important;
}

.bg-soft-success, .fc-timegrid .event-bg-soft-success {
  background-color: #e0faef !important;
}

.bg-soft-info, .fc-timegrid .event-bg-soft-info {
  background-color: #e5f7ff !important;
}

.bg-soft-warning, .fc-timegrid .event-bg-soft-warning {
  background-color: #fef0e8 !important;
}

.bg-soft-danger, .fc-timegrid .event-bg-soft-danger {
  background-color: #fce7eb !important;
}

.bg-soft-light, .fc-timegrid .event-bg-soft-light {
  background-color: #fefeff !important;
}

.bg-soft-dark, .fc-timegrid .event-bg-soft-dark {
  background-color: #e2e3e5 !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .position-sm-static {
    position: static !important;
  }
  .position-sm-absolute {
    position: absolute !important;
  }
  .position-sm-relative {
    position: relative !important;
  }
  .position-sm-fixed {
    position: fixed !important;
  }
  .position-sm-sticky {
    position: sticky !important;
  }
  .translate-sm-middle {
    transform: translateX(-50%) translateY(-50%) !important;
  }
  .translate-sm-middle-x {
    transform: translateX(-50%) !important;
  }
  .translate-sm-middle-y {
    transform: translateY(-50%) !important;
  }
  .border-sm {
    border: 1px solid #d8e2ef !important;
  }
  .border-sm-0 {
    border: 0 !important;
  }
  .border-sm-top {
    border-top: 1px solid #d8e2ef !important;
  }
  .border-sm-top-0 {
    border-top: 0 !important;
  }
  .border-sm-right {
    border-right: 1px solid #d8e2ef !important;
  }
  .border-sm-right-0 {
    border-right: 0 !important;
  }
  .border-sm-bottom {
    border-bottom: 1px solid #d8e2ef !important;
  }
  .border-sm-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-sm-left {
    border-left: 1px solid #d8e2ef !important;
  }
  .border-sm-left-0 {
    border-left: 0 !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .vw-sm-25 {
    width: 25vw !important;
  }
  .vw-sm-50 {
    width: 50vw !important;
  }
  .vw-sm-75 {
    width: 75vw !important;
  }
  .vw-sm-100 {
    width: 100vw !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
  .vh-sm-25 {
    height: 25vh !important;
  }
  .vh-sm-50 {
    height: 50vh !important;
  }
  .vh-sm-75 {
    height: 75vh !important;
  }
  .vh-sm-100 {
    height: 100vh !important;
  }
  .min-vh-sm-25 {
    min-height: 25vh !important;
  }
  .min-vh-sm-50 {
    min-height: 50vh !important;
  }
  .min-vh-sm-75 {
    min-height: 75vh !important;
  }
  .min-vh-sm-100 {
    min-height: 100vh !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.8rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-6 {
    margin: 4rem !important;
  }
  .m-sm-7 {
    margin: 5rem !important;
  }
  .m-sm-8 {
    margin: 7.5rem !important;
  }
  .m-sm-9 {
    margin: 10rem !important;
  }
  .m-sm-10 {
    margin: 12.5rem !important;
  }
  .m-sm-11 {
    margin: 15rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-sm-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-sm-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-sm-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-sm-10 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }
  .mx-sm-11 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-sm-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-sm-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-sm-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-sm-10 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }
  .my-sm-11 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.8rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-6 {
    margin-top: 4rem !important;
  }
  .mt-sm-7 {
    margin-top: 5rem !important;
  }
  .mt-sm-8 {
    margin-top: 7.5rem !important;
  }
  .mt-sm-9 {
    margin-top: 10rem !important;
  }
  .mt-sm-10 {
    margin-top: 12.5rem !important;
  }
  .mt-sm-11 {
    margin-top: 15rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-0 {
    margin-right: 0 !important;
  }
  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mr-sm-3 {
    margin-right: 1rem !important;
  }
  .mr-sm-4 {
    margin-right: 1.8rem !important;
  }
  .mr-sm-5 {
    margin-right: 3rem !important;
  }
  .mr-sm-6 {
    margin-right: 4rem !important;
  }
  .mr-sm-7 {
    margin-right: 5rem !important;
  }
  .mr-sm-8 {
    margin-right: 7.5rem !important;
  }
  .mr-sm-9 {
    margin-right: 10rem !important;
  }
  .mr-sm-10 {
    margin-right: 12.5rem !important;
  }
  .mr-sm-11 {
    margin-right: 15rem !important;
  }
  .mr-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.8rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 4rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 5rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 7.5rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 10rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 12.5rem !important;
  }
  .mb-sm-11 {
    margin-bottom: 15rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-0 {
    margin-left: 0 !important;
  }
  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ml-sm-3 {
    margin-left: 1rem !important;
  }
  .ml-sm-4 {
    margin-left: 1.8rem !important;
  }
  .ml-sm-5 {
    margin-left: 3rem !important;
  }
  .ml-sm-6 {
    margin-left: 4rem !important;
  }
  .ml-sm-7 {
    margin-left: 5rem !important;
  }
  .ml-sm-8 {
    margin-left: 7.5rem !important;
  }
  .ml-sm-9 {
    margin-left: 10rem !important;
  }
  .ml-sm-10 {
    margin-left: 12.5rem !important;
  }
  .ml-sm-11 {
    margin-left: 15rem !important;
  }
  .ml-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.8rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .m-sm-n6 {
    margin: -4rem !important;
  }
  .m-sm-n7 {
    margin: -5rem !important;
  }
  .m-sm-n8 {
    margin: -7.5rem !important;
  }
  .m-sm-n9 {
    margin: -10rem !important;
  }
  .m-sm-n10 {
    margin: -12.5rem !important;
  }
  .m-sm-n11 {
    margin: -15rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.8rem !important;
    margin-left: -1.8rem !important;
  }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-sm-n6 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-sm-n7 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-sm-n8 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-sm-n9 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-sm-n10 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }
  .mx-sm-n11 {
    margin-right: -15rem !important;
    margin-left: -15rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.8rem !important;
    margin-bottom: -1.8rem !important;
  }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-sm-n6 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-sm-n7 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-sm-n8 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-sm-n9 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-sm-n10 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }
  .my-sm-n11 {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.8rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .mt-sm-n6 {
    margin-top: -4rem !important;
  }
  .mt-sm-n7 {
    margin-top: -5rem !important;
  }
  .mt-sm-n8 {
    margin-top: -7.5rem !important;
  }
  .mt-sm-n9 {
    margin-top: -10rem !important;
  }
  .mt-sm-n10 {
    margin-top: -12.5rem !important;
  }
  .mt-sm-n11 {
    margin-top: -15rem !important;
  }
  .mr-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mr-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mr-sm-n3 {
    margin-right: -1rem !important;
  }
  .mr-sm-n4 {
    margin-right: -1.8rem !important;
  }
  .mr-sm-n5 {
    margin-right: -3rem !important;
  }
  .mr-sm-n6 {
    margin-right: -4rem !important;
  }
  .mr-sm-n7 {
    margin-right: -5rem !important;
  }
  .mr-sm-n8 {
    margin-right: -7.5rem !important;
  }
  .mr-sm-n9 {
    margin-right: -10rem !important;
  }
  .mr-sm-n10 {
    margin-right: -12.5rem !important;
  }
  .mr-sm-n11 {
    margin-right: -15rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.8rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -4rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -5rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -7.5rem !important;
  }
  .mb-sm-n9 {
    margin-bottom: -10rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -12.5rem !important;
  }
  .mb-sm-n11 {
    margin-bottom: -15rem !important;
  }
  .ml-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ml-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ml-sm-n3 {
    margin-left: -1rem !important;
  }
  .ml-sm-n4 {
    margin-left: -1.8rem !important;
  }
  .ml-sm-n5 {
    margin-left: -3rem !important;
  }
  .ml-sm-n6 {
    margin-left: -4rem !important;
  }
  .ml-sm-n7 {
    margin-left: -5rem !important;
  }
  .ml-sm-n8 {
    margin-left: -7.5rem !important;
  }
  .ml-sm-n9 {
    margin-left: -10rem !important;
  }
  .ml-sm-n10 {
    margin-left: -12.5rem !important;
  }
  .ml-sm-n11 {
    margin-left: -15rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.8rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .p-sm-6 {
    padding: 4rem !important;
  }
  .p-sm-7 {
    padding: 5rem !important;
  }
  .p-sm-8 {
    padding: 7.5rem !important;
  }
  .p-sm-9 {
    padding: 10rem !important;
  }
  .p-sm-10 {
    padding: 12.5rem !important;
  }
  .p-sm-11 {
    padding: 15rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-sm-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-sm-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-sm-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-sm-10 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }
  .px-sm-11 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-sm-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-sm-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-sm-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-sm-10 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .py-sm-11 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.8rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pt-sm-6 {
    padding-top: 4rem !important;
  }
  .pt-sm-7 {
    padding-top: 5rem !important;
  }
  .pt-sm-8 {
    padding-top: 7.5rem !important;
  }
  .pt-sm-9 {
    padding-top: 10rem !important;
  }
  .pt-sm-10 {
    padding-top: 12.5rem !important;
  }
  .pt-sm-11 {
    padding-top: 15rem !important;
  }
  .pr-sm-0 {
    padding-right: 0 !important;
  }
  .pr-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pr-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pr-sm-3 {
    padding-right: 1rem !important;
  }
  .pr-sm-4 {
    padding-right: 1.8rem !important;
  }
  .pr-sm-5 {
    padding-right: 3rem !important;
  }
  .pr-sm-6 {
    padding-right: 4rem !important;
  }
  .pr-sm-7 {
    padding-right: 5rem !important;
  }
  .pr-sm-8 {
    padding-right: 7.5rem !important;
  }
  .pr-sm-9 {
    padding-right: 10rem !important;
  }
  .pr-sm-10 {
    padding-right: 12.5rem !important;
  }
  .pr-sm-11 {
    padding-right: 15rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.8rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 4rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 5rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 7.5rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 10rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 12.5rem !important;
  }
  .pb-sm-11 {
    padding-bottom: 15rem !important;
  }
  .pl-sm-0 {
    padding-left: 0 !important;
  }
  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }
  .pl-sm-2 {
    padding-left: 0.5rem !important;
  }
  .pl-sm-3 {
    padding-left: 1rem !important;
  }
  .pl-sm-4 {
    padding-left: 1.8rem !important;
  }
  .pl-sm-5 {
    padding-left: 3rem !important;
  }
  .pl-sm-6 {
    padding-left: 4rem !important;
  }
  .pl-sm-7 {
    padding-left: 5rem !important;
  }
  .pl-sm-8 {
    padding-left: 7.5rem !important;
  }
  .pl-sm-9 {
    padding-left: 10rem !important;
  }
  .pl-sm-10 {
    padding-left: 12.5rem !important;
  }
  .pl-sm-11 {
    padding-left: 15rem !important;
  }
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
  .rounded-sm-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-sm-top-lg {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .rounded-sm-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .rounded-sm-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-sm-right-lg {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .rounded-sm-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .rounded-sm-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-sm-bottom-lg {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .rounded-sm-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .rounded-sm-left {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-sm-left-lg {
    border-bottom-left-radius: 0.375rem !important;
    border-top-left-radius: 0.375rem !important;
  }
  .rounded-sm-left-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .opacity-sm-0 {
    opacity: 0 !important;
  }
  .opacity-sm-25 {
    opacity: 0.25 !important;
  }
  .opacity-sm-50 {
    opacity: 0.5 !important;
  }
  .opacity-sm-75 {
    opacity: 0.75 !important;
  }
  .opacity-sm-85 {
    opacity: 0.85 !important;
  }
  .opacity-sm-100 {
    opacity: 1 !important;
  }
  .fs-sm--2 {
    font-size: 0.69444rem !important;
  }
  .fs-sm--1 {
    font-size: 0.83333rem !important;
  }
  .fs-sm-0 {
    font-size: 1rem !important;
  }
  .fs-sm-1 {
    font-size: 1.2rem !important;
  }
  .fs-sm-2 {
    font-size: 1.44rem !important;
  }
  .fs-sm-3 {
    font-size: 1.728rem !important;
  }
  .fs-sm-4 {
    font-size: 2.0736rem !important;
  }
  .fs-sm-5 {
    font-size: 2.48832rem !important;
  }
  .fs-sm-6 {
    font-size: 2.98598rem !important;
  }
  .fs-sm-7 {
    font-size: 3.58318rem !important;
  }
  .fs-sm-8 {
    font-size: 4.29982rem !important;
  }
  .max-vh-sm-25 {
    max-height: 25vh !important;
  }
  .max-vh-sm-50 {
    max-height: 50vh !important;
  }
  .max-vh-sm-75 {
    max-height: 75vh !important;
  }
  .max-vh-sm-100 {
    max-height: 100vh !important;
  }
  .border-sm-x {
    border-left: 1px solid #d8e2ef !important;
    border-right: 1px solid #d8e2ef !important;
  }
  .border-sm-x-0 {
    border-left: 0 !important;
    border-right: 0 !important;
  }
  .border-sm-y {
    border-top: 1px solid #d8e2ef !important;
    border-bottom: 1px solid #d8e2ef !important;
  }
  .border-sm-y-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }
  .border-sm-dashed {
    border: 1px dashed #d8e2ef !important;
  }
  .border-sm-dashed-top {
    border-top: 1px dashed #d8e2ef !important;
  }
  .border-sm-dashed-right {
    border-right: 1px dashed #d8e2ef !important;
  }
  .border-sm-dashed-left {
    border-left: 1px dashed #d8e2ef !important;
  }
  .border-sm-dashed-bottom {
    border-bottom: 1px dashed #d8e2ef !important;
  }
  .border-sm-dashed-x {
    border-left: 1px dashed #d8e2ef !important;
    border-right: 1px dashed #d8e2ef !important;
  }
  .border-sm-dashed-y {
    border-top: 1px dashed #d8e2ef !important;
    border-bottom: 1px dashed #d8e2ef !important;
  }
  .rounded-sm-0 {
    border-radius: 0 !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .position-md-static {
    position: static !important;
  }
  .position-md-absolute {
    position: absolute !important;
  }
  .position-md-relative {
    position: relative !important;
  }
  .position-md-fixed {
    position: fixed !important;
  }
  .position-md-sticky {
    position: sticky !important;
  }
  .translate-md-middle {
    transform: translateX(-50%) translateY(-50%) !important;
  }
  .translate-md-middle-x {
    transform: translateX(-50%) !important;
  }
  .translate-md-middle-y {
    transform: translateY(-50%) !important;
  }
  .border-md {
    border: 1px solid #d8e2ef !important;
  }
  .border-md-0 {
    border: 0 !important;
  }
  .border-md-top {
    border-top: 1px solid #d8e2ef !important;
  }
  .border-md-top-0 {
    border-top: 0 !important;
  }
  .border-md-right {
    border-right: 1px solid #d8e2ef !important;
  }
  .border-md-right-0 {
    border-right: 0 !important;
  }
  .border-md-bottom {
    border-bottom: 1px solid #d8e2ef !important;
  }
  .border-md-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-md-left {
    border-left: 1px solid #d8e2ef !important;
  }
  .border-md-left-0 {
    border-left: 0 !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .vw-md-25 {
    width: 25vw !important;
  }
  .vw-md-50 {
    width: 50vw !important;
  }
  .vw-md-75 {
    width: 75vw !important;
  }
  .vw-md-100 {
    width: 100vw !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-auto {
    height: auto !important;
  }
  .vh-md-25 {
    height: 25vh !important;
  }
  .vh-md-50 {
    height: 50vh !important;
  }
  .vh-md-75 {
    height: 75vh !important;
  }
  .vh-md-100 {
    height: 100vh !important;
  }
  .min-vh-md-25 {
    min-height: 25vh !important;
  }
  .min-vh-md-50 {
    min-height: 50vh !important;
  }
  .min-vh-md-75 {
    min-height: 75vh !important;
  }
  .min-vh-md-100 {
    min-height: 100vh !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.8rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-6 {
    margin: 4rem !important;
  }
  .m-md-7 {
    margin: 5rem !important;
  }
  .m-md-8 {
    margin: 7.5rem !important;
  }
  .m-md-9 {
    margin: 10rem !important;
  }
  .m-md-10 {
    margin: 12.5rem !important;
  }
  .m-md-11 {
    margin: 15rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-md-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-md-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-md-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-md-10 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }
  .mx-md-11 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-md-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-md-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-md-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-md-10 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }
  .my-md-11 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.8rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-6 {
    margin-top: 4rem !important;
  }
  .mt-md-7 {
    margin-top: 5rem !important;
  }
  .mt-md-8 {
    margin-top: 7.5rem !important;
  }
  .mt-md-9 {
    margin-top: 10rem !important;
  }
  .mt-md-10 {
    margin-top: 12.5rem !important;
  }
  .mt-md-11 {
    margin-top: 15rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .mr-md-0 {
    margin-right: 0 !important;
  }
  .mr-md-1 {
    margin-right: 0.25rem !important;
  }
  .mr-md-2 {
    margin-right: 0.5rem !important;
  }
  .mr-md-3 {
    margin-right: 1rem !important;
  }
  .mr-md-4 {
    margin-right: 1.8rem !important;
  }
  .mr-md-5 {
    margin-right: 3rem !important;
  }
  .mr-md-6 {
    margin-right: 4rem !important;
  }
  .mr-md-7 {
    margin-right: 5rem !important;
  }
  .mr-md-8 {
    margin-right: 7.5rem !important;
  }
  .mr-md-9 {
    margin-right: 10rem !important;
  }
  .mr-md-10 {
    margin-right: 12.5rem !important;
  }
  .mr-md-11 {
    margin-right: 15rem !important;
  }
  .mr-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.8rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-6 {
    margin-bottom: 4rem !important;
  }
  .mb-md-7 {
    margin-bottom: 5rem !important;
  }
  .mb-md-8 {
    margin-bottom: 7.5rem !important;
  }
  .mb-md-9 {
    margin-bottom: 10rem !important;
  }
  .mb-md-10 {
    margin-bottom: 12.5rem !important;
  }
  .mb-md-11 {
    margin-bottom: 15rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-0 {
    margin-left: 0 !important;
  }
  .ml-md-1 {
    margin-left: 0.25rem !important;
  }
  .ml-md-2 {
    margin-left: 0.5rem !important;
  }
  .ml-md-3 {
    margin-left: 1rem !important;
  }
  .ml-md-4 {
    margin-left: 1.8rem !important;
  }
  .ml-md-5 {
    margin-left: 3rem !important;
  }
  .ml-md-6 {
    margin-left: 4rem !important;
  }
  .ml-md-7 {
    margin-left: 5rem !important;
  }
  .ml-md-8 {
    margin-left: 7.5rem !important;
  }
  .ml-md-9 {
    margin-left: 10rem !important;
  }
  .ml-md-10 {
    margin-left: 12.5rem !important;
  }
  .ml-md-11 {
    margin-left: 15rem !important;
  }
  .ml-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.8rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .m-md-n6 {
    margin: -4rem !important;
  }
  .m-md-n7 {
    margin: -5rem !important;
  }
  .m-md-n8 {
    margin: -7.5rem !important;
  }
  .m-md-n9 {
    margin: -10rem !important;
  }
  .m-md-n10 {
    margin: -12.5rem !important;
  }
  .m-md-n11 {
    margin: -15rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.8rem !important;
    margin-left: -1.8rem !important;
  }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-md-n6 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-md-n7 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-md-n8 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-md-n9 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-md-n10 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }
  .mx-md-n11 {
    margin-right: -15rem !important;
    margin-left: -15rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.8rem !important;
    margin-bottom: -1.8rem !important;
  }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-md-n6 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-md-n7 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-md-n8 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-md-n9 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-md-n10 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }
  .my-md-n11 {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.8rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .mt-md-n6 {
    margin-top: -4rem !important;
  }
  .mt-md-n7 {
    margin-top: -5rem !important;
  }
  .mt-md-n8 {
    margin-top: -7.5rem !important;
  }
  .mt-md-n9 {
    margin-top: -10rem !important;
  }
  .mt-md-n10 {
    margin-top: -12.5rem !important;
  }
  .mt-md-n11 {
    margin-top: -15rem !important;
  }
  .mr-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mr-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mr-md-n3 {
    margin-right: -1rem !important;
  }
  .mr-md-n4 {
    margin-right: -1.8rem !important;
  }
  .mr-md-n5 {
    margin-right: -3rem !important;
  }
  .mr-md-n6 {
    margin-right: -4rem !important;
  }
  .mr-md-n7 {
    margin-right: -5rem !important;
  }
  .mr-md-n8 {
    margin-right: -7.5rem !important;
  }
  .mr-md-n9 {
    margin-right: -10rem !important;
  }
  .mr-md-n10 {
    margin-right: -12.5rem !important;
  }
  .mr-md-n11 {
    margin-right: -15rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.8rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -4rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -5rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -7.5rem !important;
  }
  .mb-md-n9 {
    margin-bottom: -10rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -12.5rem !important;
  }
  .mb-md-n11 {
    margin-bottom: -15rem !important;
  }
  .ml-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ml-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ml-md-n3 {
    margin-left: -1rem !important;
  }
  .ml-md-n4 {
    margin-left: -1.8rem !important;
  }
  .ml-md-n5 {
    margin-left: -3rem !important;
  }
  .ml-md-n6 {
    margin-left: -4rem !important;
  }
  .ml-md-n7 {
    margin-left: -5rem !important;
  }
  .ml-md-n8 {
    margin-left: -7.5rem !important;
  }
  .ml-md-n9 {
    margin-left: -10rem !important;
  }
  .ml-md-n10 {
    margin-left: -12.5rem !important;
  }
  .ml-md-n11 {
    margin-left: -15rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.8rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .p-md-6 {
    padding: 4rem !important;
  }
  .p-md-7 {
    padding: 5rem !important;
  }
  .p-md-8 {
    padding: 7.5rem !important;
  }
  .p-md-9 {
    padding: 10rem !important;
  }
  .p-md-10 {
    padding: 12.5rem !important;
  }
  .p-md-11 {
    padding: 15rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-md-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-md-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-md-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-md-10 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }
  .px-md-11 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-md-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-md-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-md-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-md-10 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .py-md-11 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.8rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pt-md-6 {
    padding-top: 4rem !important;
  }
  .pt-md-7 {
    padding-top: 5rem !important;
  }
  .pt-md-8 {
    padding-top: 7.5rem !important;
  }
  .pt-md-9 {
    padding-top: 10rem !important;
  }
  .pt-md-10 {
    padding-top: 12.5rem !important;
  }
  .pt-md-11 {
    padding-top: 15rem !important;
  }
  .pr-md-0 {
    padding-right: 0 !important;
  }
  .pr-md-1 {
    padding-right: 0.25rem !important;
  }
  .pr-md-2 {
    padding-right: 0.5rem !important;
  }
  .pr-md-3 {
    padding-right: 1rem !important;
  }
  .pr-md-4 {
    padding-right: 1.8rem !important;
  }
  .pr-md-5 {
    padding-right: 3rem !important;
  }
  .pr-md-6 {
    padding-right: 4rem !important;
  }
  .pr-md-7 {
    padding-right: 5rem !important;
  }
  .pr-md-8 {
    padding-right: 7.5rem !important;
  }
  .pr-md-9 {
    padding-right: 10rem !important;
  }
  .pr-md-10 {
    padding-right: 12.5rem !important;
  }
  .pr-md-11 {
    padding-right: 15rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.8rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pb-md-6 {
    padding-bottom: 4rem !important;
  }
  .pb-md-7 {
    padding-bottom: 5rem !important;
  }
  .pb-md-8 {
    padding-bottom: 7.5rem !important;
  }
  .pb-md-9 {
    padding-bottom: 10rem !important;
  }
  .pb-md-10 {
    padding-bottom: 12.5rem !important;
  }
  .pb-md-11 {
    padding-bottom: 15rem !important;
  }
  .pl-md-0 {
    padding-left: 0 !important;
  }
  .pl-md-1 {
    padding-left: 0.25rem !important;
  }
  .pl-md-2 {
    padding-left: 0.5rem !important;
  }
  .pl-md-3 {
    padding-left: 1rem !important;
  }
  .pl-md-4 {
    padding-left: 1.8rem !important;
  }
  .pl-md-5 {
    padding-left: 3rem !important;
  }
  .pl-md-6 {
    padding-left: 4rem !important;
  }
  .pl-md-7 {
    padding-left: 5rem !important;
  }
  .pl-md-8 {
    padding-left: 7.5rem !important;
  }
  .pl-md-9 {
    padding-left: 10rem !important;
  }
  .pl-md-10 {
    padding-left: 12.5rem !important;
  }
  .pl-md-11 {
    padding-left: 15rem !important;
  }
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
  .rounded-md-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-md-top-lg {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .rounded-md-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .rounded-md-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-md-right-lg {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .rounded-md-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .rounded-md-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-md-bottom-lg {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .rounded-md-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .rounded-md-left {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-md-left-lg {
    border-bottom-left-radius: 0.375rem !important;
    border-top-left-radius: 0.375rem !important;
  }
  .rounded-md-left-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .opacity-md-0 {
    opacity: 0 !important;
  }
  .opacity-md-25 {
    opacity: 0.25 !important;
  }
  .opacity-md-50 {
    opacity: 0.5 !important;
  }
  .opacity-md-75 {
    opacity: 0.75 !important;
  }
  .opacity-md-85 {
    opacity: 0.85 !important;
  }
  .opacity-md-100 {
    opacity: 1 !important;
  }
  .fs-md--2 {
    font-size: 0.69444rem !important;
  }
  .fs-md--1 {
    font-size: 0.83333rem !important;
  }
  .fs-md-0 {
    font-size: 1rem !important;
  }
  .fs-md-1 {
    font-size: 1.2rem !important;
  }
  .fs-md-2 {
    font-size: 1.44rem !important;
  }
  .fs-md-3 {
    font-size: 1.728rem !important;
  }
  .fs-md-4 {
    font-size: 2.0736rem !important;
  }
  .fs-md-5 {
    font-size: 2.48832rem !important;
  }
  .fs-md-6 {
    font-size: 2.98598rem !important;
  }
  .fs-md-7 {
    font-size: 3.58318rem !important;
  }
  .fs-md-8 {
    font-size: 4.29982rem !important;
  }
  .max-vh-md-25 {
    max-height: 25vh !important;
  }
  .max-vh-md-50 {
    max-height: 50vh !important;
  }
  .max-vh-md-75 {
    max-height: 75vh !important;
  }
  .max-vh-md-100 {
    max-height: 100vh !important;
  }
  .border-md-x {
    border-left: 1px solid #d8e2ef !important;
    border-right: 1px solid #d8e2ef !important;
  }
  .border-md-x-0 {
    border-left: 0 !important;
    border-right: 0 !important;
  }
  .border-md-y {
    border-top: 1px solid #d8e2ef !important;
    border-bottom: 1px solid #d8e2ef !important;
  }
  .border-md-y-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }
  .border-md-dashed {
    border: 1px dashed #d8e2ef !important;
  }
  .border-md-dashed-top {
    border-top: 1px dashed #d8e2ef !important;
  }
  .border-md-dashed-right {
    border-right: 1px dashed #d8e2ef !important;
  }
  .border-md-dashed-left {
    border-left: 1px dashed #d8e2ef !important;
  }
  .border-md-dashed-bottom {
    border-bottom: 1px dashed #d8e2ef !important;
  }
  .border-md-dashed-x {
    border-left: 1px dashed #d8e2ef !important;
    border-right: 1px dashed #d8e2ef !important;
  }
  .border-md-dashed-y {
    border-top: 1px dashed #d8e2ef !important;
    border-bottom: 1px dashed #d8e2ef !important;
  }
  .rounded-md-0 {
    border-radius: 0 !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .position-lg-static {
    position: static !important;
  }
  .position-lg-absolute {
    position: absolute !important;
  }
  .position-lg-relative {
    position: relative !important;
  }
  .position-lg-fixed {
    position: fixed !important;
  }
  .position-lg-sticky {
    position: sticky !important;
  }
  .translate-lg-middle {
    transform: translateX(-50%) translateY(-50%) !important;
  }
  .translate-lg-middle-x {
    transform: translateX(-50%) !important;
  }
  .translate-lg-middle-y {
    transform: translateY(-50%) !important;
  }
  .border-lg {
    border: 1px solid #d8e2ef !important;
  }
  .border-lg-0 {
    border: 0 !important;
  }
  .border-lg-top {
    border-top: 1px solid #d8e2ef !important;
  }
  .border-lg-top-0 {
    border-top: 0 !important;
  }
  .border-lg-right {
    border-right: 1px solid #d8e2ef !important;
  }
  .border-lg-right-0 {
    border-right: 0 !important;
  }
  .border-lg-bottom {
    border-bottom: 1px solid #d8e2ef !important;
  }
  .border-lg-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-lg-left {
    border-left: 1px solid #d8e2ef !important;
  }
  .border-lg-left-0 {
    border-left: 0 !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .vw-lg-25 {
    width: 25vw !important;
  }
  .vw-lg-50 {
    width: 50vw !important;
  }
  .vw-lg-75 {
    width: 75vw !important;
  }
  .vw-lg-100 {
    width: 100vw !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
  .vh-lg-25 {
    height: 25vh !important;
  }
  .vh-lg-50 {
    height: 50vh !important;
  }
  .vh-lg-75 {
    height: 75vh !important;
  }
  .vh-lg-100 {
    height: 100vh !important;
  }
  .min-vh-lg-25 {
    min-height: 25vh !important;
  }
  .min-vh-lg-50 {
    min-height: 50vh !important;
  }
  .min-vh-lg-75 {
    min-height: 75vh !important;
  }
  .min-vh-lg-100 {
    min-height: 100vh !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.8rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-6 {
    margin: 4rem !important;
  }
  .m-lg-7 {
    margin: 5rem !important;
  }
  .m-lg-8 {
    margin: 7.5rem !important;
  }
  .m-lg-9 {
    margin: 10rem !important;
  }
  .m-lg-10 {
    margin: 12.5rem !important;
  }
  .m-lg-11 {
    margin: 15rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-lg-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-lg-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-lg-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-lg-10 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }
  .mx-lg-11 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-lg-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-lg-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-lg-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-lg-10 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }
  .my-lg-11 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.8rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-6 {
    margin-top: 4rem !important;
  }
  .mt-lg-7 {
    margin-top: 5rem !important;
  }
  .mt-lg-8 {
    margin-top: 7.5rem !important;
  }
  .mt-lg-9 {
    margin-top: 10rem !important;
  }
  .mt-lg-10 {
    margin-top: 12.5rem !important;
  }
  .mt-lg-11 {
    margin-top: 15rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-0 {
    margin-right: 0 !important;
  }
  .mr-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mr-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mr-lg-3 {
    margin-right: 1rem !important;
  }
  .mr-lg-4 {
    margin-right: 1.8rem !important;
  }
  .mr-lg-5 {
    margin-right: 3rem !important;
  }
  .mr-lg-6 {
    margin-right: 4rem !important;
  }
  .mr-lg-7 {
    margin-right: 5rem !important;
  }
  .mr-lg-8 {
    margin-right: 7.5rem !important;
  }
  .mr-lg-9 {
    margin-right: 10rem !important;
  }
  .mr-lg-10 {
    margin-right: 12.5rem !important;
  }
  .mr-lg-11 {
    margin-right: 15rem !important;
  }
  .mr-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.8rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 7.5rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 10rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 12.5rem !important;
  }
  .mb-lg-11 {
    margin-bottom: 15rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-0 {
    margin-left: 0 !important;
  }
  .ml-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ml-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ml-lg-3 {
    margin-left: 1rem !important;
  }
  .ml-lg-4 {
    margin-left: 1.8rem !important;
  }
  .ml-lg-5 {
    margin-left: 3rem !important;
  }
  .ml-lg-6 {
    margin-left: 4rem !important;
  }
  .ml-lg-7 {
    margin-left: 5rem !important;
  }
  .ml-lg-8 {
    margin-left: 7.5rem !important;
  }
  .ml-lg-9 {
    margin-left: 10rem !important;
  }
  .ml-lg-10 {
    margin-left: 12.5rem !important;
  }
  .ml-lg-11 {
    margin-left: 15rem !important;
  }
  .ml-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.8rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .m-lg-n6 {
    margin: -4rem !important;
  }
  .m-lg-n7 {
    margin: -5rem !important;
  }
  .m-lg-n8 {
    margin: -7.5rem !important;
  }
  .m-lg-n9 {
    margin: -10rem !important;
  }
  .m-lg-n10 {
    margin: -12.5rem !important;
  }
  .m-lg-n11 {
    margin: -15rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.8rem !important;
    margin-left: -1.8rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-lg-n6 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-lg-n7 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-lg-n8 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-lg-n9 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-lg-n10 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }
  .mx-lg-n11 {
    margin-right: -15rem !important;
    margin-left: -15rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.8rem !important;
    margin-bottom: -1.8rem !important;
  }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-lg-n6 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-lg-n7 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-lg-n8 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-lg-n9 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-lg-n10 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }
  .my-lg-n11 {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.8rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .mt-lg-n6 {
    margin-top: -4rem !important;
  }
  .mt-lg-n7 {
    margin-top: -5rem !important;
  }
  .mt-lg-n8 {
    margin-top: -7.5rem !important;
  }
  .mt-lg-n9 {
    margin-top: -10rem !important;
  }
  .mt-lg-n10 {
    margin-top: -12.5rem !important;
  }
  .mt-lg-n11 {
    margin-top: -15rem !important;
  }
  .mr-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mr-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mr-lg-n3 {
    margin-right: -1rem !important;
  }
  .mr-lg-n4 {
    margin-right: -1.8rem !important;
  }
  .mr-lg-n5 {
    margin-right: -3rem !important;
  }
  .mr-lg-n6 {
    margin-right: -4rem !important;
  }
  .mr-lg-n7 {
    margin-right: -5rem !important;
  }
  .mr-lg-n8 {
    margin-right: -7.5rem !important;
  }
  .mr-lg-n9 {
    margin-right: -10rem !important;
  }
  .mr-lg-n10 {
    margin-right: -12.5rem !important;
  }
  .mr-lg-n11 {
    margin-right: -15rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.8rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -4rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -5rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -7.5rem !important;
  }
  .mb-lg-n9 {
    margin-bottom: -10rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -12.5rem !important;
  }
  .mb-lg-n11 {
    margin-bottom: -15rem !important;
  }
  .ml-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ml-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ml-lg-n3 {
    margin-left: -1rem !important;
  }
  .ml-lg-n4 {
    margin-left: -1.8rem !important;
  }
  .ml-lg-n5 {
    margin-left: -3rem !important;
  }
  .ml-lg-n6 {
    margin-left: -4rem !important;
  }
  .ml-lg-n7 {
    margin-left: -5rem !important;
  }
  .ml-lg-n8 {
    margin-left: -7.5rem !important;
  }
  .ml-lg-n9 {
    margin-left: -10rem !important;
  }
  .ml-lg-n10 {
    margin-left: -12.5rem !important;
  }
  .ml-lg-n11 {
    margin-left: -15rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.8rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .p-lg-6 {
    padding: 4rem !important;
  }
  .p-lg-7 {
    padding: 5rem !important;
  }
  .p-lg-8 {
    padding: 7.5rem !important;
  }
  .p-lg-9 {
    padding: 10rem !important;
  }
  .p-lg-10 {
    padding: 12.5rem !important;
  }
  .p-lg-11 {
    padding: 15rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-lg-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-lg-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-lg-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-lg-10 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }
  .px-lg-11 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-lg-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-lg-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-lg-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-lg-10 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .py-lg-11 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.8rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pt-lg-6 {
    padding-top: 4rem !important;
  }
  .pt-lg-7 {
    padding-top: 5rem !important;
  }
  .pt-lg-8 {
    padding-top: 7.5rem !important;
  }
  .pt-lg-9 {
    padding-top: 10rem !important;
  }
  .pt-lg-10 {
    padding-top: 12.5rem !important;
  }
  .pt-lg-11 {
    padding-top: 15rem !important;
  }
  .pr-lg-0 {
    padding-right: 0 !important;
  }
  .pr-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pr-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pr-lg-3 {
    padding-right: 1rem !important;
  }
  .pr-lg-4 {
    padding-right: 1.8rem !important;
  }
  .pr-lg-5 {
    padding-right: 3rem !important;
  }
  .pr-lg-6 {
    padding-right: 4rem !important;
  }
  .pr-lg-7 {
    padding-right: 5rem !important;
  }
  .pr-lg-8 {
    padding-right: 7.5rem !important;
  }
  .pr-lg-9 {
    padding-right: 10rem !important;
  }
  .pr-lg-10 {
    padding-right: 12.5rem !important;
  }
  .pr-lg-11 {
    padding-right: 15rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.8rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 4rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 7.5rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 10rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 12.5rem !important;
  }
  .pb-lg-11 {
    padding-bottom: 15rem !important;
  }
  .pl-lg-0 {
    padding-left: 0 !important;
  }
  .pl-lg-1 {
    padding-left: 0.25rem !important;
  }
  .pl-lg-2 {
    padding-left: 0.5rem !important;
  }
  .pl-lg-3 {
    padding-left: 1rem !important;
  }
  .pl-lg-4 {
    padding-left: 1.8rem !important;
  }
  .pl-lg-5 {
    padding-left: 3rem !important;
  }
  .pl-lg-6 {
    padding-left: 4rem !important;
  }
  .pl-lg-7 {
    padding-left: 5rem !important;
  }
  .pl-lg-8 {
    padding-left: 7.5rem !important;
  }
  .pl-lg-9 {
    padding-left: 10rem !important;
  }
  .pl-lg-10 {
    padding-left: 12.5rem !important;
  }
  .pl-lg-11 {
    padding-left: 15rem !important;
  }
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
  .rounded-lg-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-lg-top-lg {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .rounded-lg-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .rounded-lg-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-lg-right-lg {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .rounded-lg-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .rounded-lg-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-lg-bottom-lg {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .rounded-lg-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .rounded-lg-left {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-lg-left-lg {
    border-bottom-left-radius: 0.375rem !important;
    border-top-left-radius: 0.375rem !important;
  }
  .rounded-lg-left-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .opacity-lg-0 {
    opacity: 0 !important;
  }
  .opacity-lg-25 {
    opacity: 0.25 !important;
  }
  .opacity-lg-50 {
    opacity: 0.5 !important;
  }
  .opacity-lg-75 {
    opacity: 0.75 !important;
  }
  .opacity-lg-85 {
    opacity: 0.85 !important;
  }
  .opacity-lg-100 {
    opacity: 1 !important;
  }
  .fs-lg--2 {
    font-size: 0.69444rem !important;
  }
  .fs-lg--1 {
    font-size: 0.83333rem !important;
  }
  .fs-lg-0 {
    font-size: 1rem !important;
  }
  .fs-lg-1 {
    font-size: 1.2rem !important;
  }
  .fs-lg-2 {
    font-size: 1.44rem !important;
  }
  .fs-lg-3 {
    font-size: 1.728rem !important;
  }
  .fs-lg-4 {
    font-size: 2.0736rem !important;
  }
  .fs-lg-5 {
    font-size: 2.48832rem !important;
  }
  .fs-lg-6 {
    font-size: 2.98598rem !important;
  }
  .fs-lg-7 {
    font-size: 3.58318rem !important;
  }
  .fs-lg-8 {
    font-size: 4.29982rem !important;
  }
  .max-vh-lg-25 {
    max-height: 25vh !important;
  }
  .max-vh-lg-50 {
    max-height: 50vh !important;
  }
  .max-vh-lg-75 {
    max-height: 75vh !important;
  }
  .max-vh-lg-100 {
    max-height: 100vh !important;
  }
  .border-lg-x {
    border-left: 1px solid #d8e2ef !important;
    border-right: 1px solid #d8e2ef !important;
  }
  .border-lg-x-0 {
    border-left: 0 !important;
    border-right: 0 !important;
  }
  .border-lg-y {
    border-top: 1px solid #d8e2ef !important;
    border-bottom: 1px solid #d8e2ef !important;
  }
  .border-lg-y-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }
  .border-lg-dashed {
    border: 1px dashed #d8e2ef !important;
  }
  .border-lg-dashed-top {
    border-top: 1px dashed #d8e2ef !important;
  }
  .border-lg-dashed-right {
    border-right: 1px dashed #d8e2ef !important;
  }
  .border-lg-dashed-left {
    border-left: 1px dashed #d8e2ef !important;
  }
  .border-lg-dashed-bottom {
    border-bottom: 1px dashed #d8e2ef !important;
  }
  .border-lg-dashed-x {
    border-left: 1px dashed #d8e2ef !important;
    border-right: 1px dashed #d8e2ef !important;
  }
  .border-lg-dashed-y {
    border-top: 1px dashed #d8e2ef !important;
    border-bottom: 1px dashed #d8e2ef !important;
  }
  .rounded-lg-0 {
    border-radius: 0 !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .position-xl-static {
    position: static !important;
  }
  .position-xl-absolute {
    position: absolute !important;
  }
  .position-xl-relative {
    position: relative !important;
  }
  .position-xl-fixed {
    position: fixed !important;
  }
  .position-xl-sticky {
    position: sticky !important;
  }
  .translate-xl-middle {
    transform: translateX(-50%) translateY(-50%) !important;
  }
  .translate-xl-middle-x {
    transform: translateX(-50%) !important;
  }
  .translate-xl-middle-y {
    transform: translateY(-50%) !important;
  }
  .border-xl {
    border: 1px solid #d8e2ef !important;
  }
  .border-xl-0 {
    border: 0 !important;
  }
  .border-xl-top {
    border-top: 1px solid #d8e2ef !important;
  }
  .border-xl-top-0 {
    border-top: 0 !important;
  }
  .border-xl-right {
    border-right: 1px solid #d8e2ef !important;
  }
  .border-xl-right-0 {
    border-right: 0 !important;
  }
  .border-xl-bottom {
    border-bottom: 1px solid #d8e2ef !important;
  }
  .border-xl-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-xl-left {
    border-left: 1px solid #d8e2ef !important;
  }
  .border-xl-left-0 {
    border-left: 0 !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .vw-xl-25 {
    width: 25vw !important;
  }
  .vw-xl-50 {
    width: 50vw !important;
  }
  .vw-xl-75 {
    width: 75vw !important;
  }
  .vw-xl-100 {
    width: 100vw !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
  .vh-xl-25 {
    height: 25vh !important;
  }
  .vh-xl-50 {
    height: 50vh !important;
  }
  .vh-xl-75 {
    height: 75vh !important;
  }
  .vh-xl-100 {
    height: 100vh !important;
  }
  .min-vh-xl-25 {
    min-height: 25vh !important;
  }
  .min-vh-xl-50 {
    min-height: 50vh !important;
  }
  .min-vh-xl-75 {
    min-height: 75vh !important;
  }
  .min-vh-xl-100 {
    min-height: 100vh !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.8rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-6 {
    margin: 4rem !important;
  }
  .m-xl-7 {
    margin: 5rem !important;
  }
  .m-xl-8 {
    margin: 7.5rem !important;
  }
  .m-xl-9 {
    margin: 10rem !important;
  }
  .m-xl-10 {
    margin: 12.5rem !important;
  }
  .m-xl-11 {
    margin: 15rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xl-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xl-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-xl-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xl-10 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }
  .mx-xl-11 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xl-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xl-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-xl-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xl-10 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }
  .my-xl-11 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.8rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-6 {
    margin-top: 4rem !important;
  }
  .mt-xl-7 {
    margin-top: 5rem !important;
  }
  .mt-xl-8 {
    margin-top: 7.5rem !important;
  }
  .mt-xl-9 {
    margin-top: 10rem !important;
  }
  .mt-xl-10 {
    margin-top: 12.5rem !important;
  }
  .mt-xl-11 {
    margin-top: 15rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-0 {
    margin-right: 0 !important;
  }
  .mr-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mr-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mr-xl-3 {
    margin-right: 1rem !important;
  }
  .mr-xl-4 {
    margin-right: 1.8rem !important;
  }
  .mr-xl-5 {
    margin-right: 3rem !important;
  }
  .mr-xl-6 {
    margin-right: 4rem !important;
  }
  .mr-xl-7 {
    margin-right: 5rem !important;
  }
  .mr-xl-8 {
    margin-right: 7.5rem !important;
  }
  .mr-xl-9 {
    margin-right: 10rem !important;
  }
  .mr-xl-10 {
    margin-right: 12.5rem !important;
  }
  .mr-xl-11 {
    margin-right: 15rem !important;
  }
  .mr-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.8rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 7.5rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 10rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 12.5rem !important;
  }
  .mb-xl-11 {
    margin-bottom: 15rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-0 {
    margin-left: 0 !important;
  }
  .ml-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ml-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ml-xl-3 {
    margin-left: 1rem !important;
  }
  .ml-xl-4 {
    margin-left: 1.8rem !important;
  }
  .ml-xl-5 {
    margin-left: 3rem !important;
  }
  .ml-xl-6 {
    margin-left: 4rem !important;
  }
  .ml-xl-7 {
    margin-left: 5rem !important;
  }
  .ml-xl-8 {
    margin-left: 7.5rem !important;
  }
  .ml-xl-9 {
    margin-left: 10rem !important;
  }
  .ml-xl-10 {
    margin-left: 12.5rem !important;
  }
  .ml-xl-11 {
    margin-left: 15rem !important;
  }
  .ml-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.8rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .m-xl-n6 {
    margin: -4rem !important;
  }
  .m-xl-n7 {
    margin: -5rem !important;
  }
  .m-xl-n8 {
    margin: -7.5rem !important;
  }
  .m-xl-n9 {
    margin: -10rem !important;
  }
  .m-xl-n10 {
    margin: -12.5rem !important;
  }
  .m-xl-n11 {
    margin: -15rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.8rem !important;
    margin-left: -1.8rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xl-n6 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-xl-n7 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-xl-n8 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-xl-n9 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-xl-n10 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }
  .mx-xl-n11 {
    margin-right: -15rem !important;
    margin-left: -15rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.8rem !important;
    margin-bottom: -1.8rem !important;
  }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xl-n6 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-xl-n7 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-xl-n8 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-xl-n9 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-xl-n10 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }
  .my-xl-n11 {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.8rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xl-n6 {
    margin-top: -4rem !important;
  }
  .mt-xl-n7 {
    margin-top: -5rem !important;
  }
  .mt-xl-n8 {
    margin-top: -7.5rem !important;
  }
  .mt-xl-n9 {
    margin-top: -10rem !important;
  }
  .mt-xl-n10 {
    margin-top: -12.5rem !important;
  }
  .mt-xl-n11 {
    margin-top: -15rem !important;
  }
  .mr-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mr-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mr-xl-n3 {
    margin-right: -1rem !important;
  }
  .mr-xl-n4 {
    margin-right: -1.8rem !important;
  }
  .mr-xl-n5 {
    margin-right: -3rem !important;
  }
  .mr-xl-n6 {
    margin-right: -4rem !important;
  }
  .mr-xl-n7 {
    margin-right: -5rem !important;
  }
  .mr-xl-n8 {
    margin-right: -7.5rem !important;
  }
  .mr-xl-n9 {
    margin-right: -10rem !important;
  }
  .mr-xl-n10 {
    margin-right: -12.5rem !important;
  }
  .mr-xl-n11 {
    margin-right: -15rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.8rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -4rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -5rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -7.5rem !important;
  }
  .mb-xl-n9 {
    margin-bottom: -10rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -12.5rem !important;
  }
  .mb-xl-n11 {
    margin-bottom: -15rem !important;
  }
  .ml-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ml-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ml-xl-n3 {
    margin-left: -1rem !important;
  }
  .ml-xl-n4 {
    margin-left: -1.8rem !important;
  }
  .ml-xl-n5 {
    margin-left: -3rem !important;
  }
  .ml-xl-n6 {
    margin-left: -4rem !important;
  }
  .ml-xl-n7 {
    margin-left: -5rem !important;
  }
  .ml-xl-n8 {
    margin-left: -7.5rem !important;
  }
  .ml-xl-n9 {
    margin-left: -10rem !important;
  }
  .ml-xl-n10 {
    margin-left: -12.5rem !important;
  }
  .ml-xl-n11 {
    margin-left: -15rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.8rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .p-xl-6 {
    padding: 4rem !important;
  }
  .p-xl-7 {
    padding: 5rem !important;
  }
  .p-xl-8 {
    padding: 7.5rem !important;
  }
  .p-xl-9 {
    padding: 10rem !important;
  }
  .p-xl-10 {
    padding: 12.5rem !important;
  }
  .p-xl-11 {
    padding: 15rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xl-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xl-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-xl-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-xl-10 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }
  .px-xl-11 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xl-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xl-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-xl-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-xl-10 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .py-xl-11 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.8rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pt-xl-6 {
    padding-top: 4rem !important;
  }
  .pt-xl-7 {
    padding-top: 5rem !important;
  }
  .pt-xl-8 {
    padding-top: 7.5rem !important;
  }
  .pt-xl-9 {
    padding-top: 10rem !important;
  }
  .pt-xl-10 {
    padding-top: 12.5rem !important;
  }
  .pt-xl-11 {
    padding-top: 15rem !important;
  }
  .pr-xl-0 {
    padding-right: 0 !important;
  }
  .pr-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pr-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pr-xl-3 {
    padding-right: 1rem !important;
  }
  .pr-xl-4 {
    padding-right: 1.8rem !important;
  }
  .pr-xl-5 {
    padding-right: 3rem !important;
  }
  .pr-xl-6 {
    padding-right: 4rem !important;
  }
  .pr-xl-7 {
    padding-right: 5rem !important;
  }
  .pr-xl-8 {
    padding-right: 7.5rem !important;
  }
  .pr-xl-9 {
    padding-right: 10rem !important;
  }
  .pr-xl-10 {
    padding-right: 12.5rem !important;
  }
  .pr-xl-11 {
    padding-right: 15rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.8rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 4rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 5rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 7.5rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 10rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 12.5rem !important;
  }
  .pb-xl-11 {
    padding-bottom: 15rem !important;
  }
  .pl-xl-0 {
    padding-left: 0 !important;
  }
  .pl-xl-1 {
    padding-left: 0.25rem !important;
  }
  .pl-xl-2 {
    padding-left: 0.5rem !important;
  }
  .pl-xl-3 {
    padding-left: 1rem !important;
  }
  .pl-xl-4 {
    padding-left: 1.8rem !important;
  }
  .pl-xl-5 {
    padding-left: 3rem !important;
  }
  .pl-xl-6 {
    padding-left: 4rem !important;
  }
  .pl-xl-7 {
    padding-left: 5rem !important;
  }
  .pl-xl-8 {
    padding-left: 7.5rem !important;
  }
  .pl-xl-9 {
    padding-left: 10rem !important;
  }
  .pl-xl-10 {
    padding-left: 12.5rem !important;
  }
  .pl-xl-11 {
    padding-left: 15rem !important;
  }
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
  .rounded-xl-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-xl-top-lg {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .rounded-xl-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .rounded-xl-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-xl-right-lg {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .rounded-xl-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .rounded-xl-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-xl-bottom-lg {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .rounded-xl-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .rounded-xl-left {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-xl-left-lg {
    border-bottom-left-radius: 0.375rem !important;
    border-top-left-radius: 0.375rem !important;
  }
  .rounded-xl-left-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .opacity-xl-0 {
    opacity: 0 !important;
  }
  .opacity-xl-25 {
    opacity: 0.25 !important;
  }
  .opacity-xl-50 {
    opacity: 0.5 !important;
  }
  .opacity-xl-75 {
    opacity: 0.75 !important;
  }
  .opacity-xl-85 {
    opacity: 0.85 !important;
  }
  .opacity-xl-100 {
    opacity: 1 !important;
  }
  .fs-xl--2 {
    font-size: 0.69444rem !important;
  }
  .fs-xl--1 {
    font-size: 0.83333rem !important;
  }
  .fs-xl-0 {
    font-size: 1rem !important;
  }
  .fs-xl-1 {
    font-size: 1.2rem !important;
  }
  .fs-xl-2 {
    font-size: 1.44rem !important;
  }
  .fs-xl-3 {
    font-size: 1.728rem !important;
  }
  .fs-xl-4 {
    font-size: 2.0736rem !important;
  }
  .fs-xl-5 {
    font-size: 2.48832rem !important;
  }
  .fs-xl-6 {
    font-size: 2.98598rem !important;
  }
  .fs-xl-7 {
    font-size: 3.58318rem !important;
  }
  .fs-xl-8 {
    font-size: 4.29982rem !important;
  }
  .max-vh-xl-25 {
    max-height: 25vh !important;
  }
  .max-vh-xl-50 {
    max-height: 50vh !important;
  }
  .max-vh-xl-75 {
    max-height: 75vh !important;
  }
  .max-vh-xl-100 {
    max-height: 100vh !important;
  }
  .border-xl-x {
    border-left: 1px solid #d8e2ef !important;
    border-right: 1px solid #d8e2ef !important;
  }
  .border-xl-x-0 {
    border-left: 0 !important;
    border-right: 0 !important;
  }
  .border-xl-y {
    border-top: 1px solid #d8e2ef !important;
    border-bottom: 1px solid #d8e2ef !important;
  }
  .border-xl-y-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }
  .border-xl-dashed {
    border: 1px dashed #d8e2ef !important;
  }
  .border-xl-dashed-top {
    border-top: 1px dashed #d8e2ef !important;
  }
  .border-xl-dashed-right {
    border-right: 1px dashed #d8e2ef !important;
  }
  .border-xl-dashed-left {
    border-left: 1px dashed #d8e2ef !important;
  }
  .border-xl-dashed-bottom {
    border-bottom: 1px dashed #d8e2ef !important;
  }
  .border-xl-dashed-x {
    border-left: 1px dashed #d8e2ef !important;
    border-right: 1px dashed #d8e2ef !important;
  }
  .border-xl-dashed-y {
    border-top: 1px dashed #d8e2ef !important;
    border-bottom: 1px dashed #d8e2ef !important;
  }
  .rounded-xl-0 {
    border-radius: 0 !important;
  }
}

@media (min-width: 1540px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .position-xxl-static {
    position: static !important;
  }
  .position-xxl-absolute {
    position: absolute !important;
  }
  .position-xxl-relative {
    position: relative !important;
  }
  .position-xxl-fixed {
    position: fixed !important;
  }
  .position-xxl-sticky {
    position: sticky !important;
  }
  .translate-xxl-middle {
    transform: translateX(-50%) translateY(-50%) !important;
  }
  .translate-xxl-middle-x {
    transform: translateX(-50%) !important;
  }
  .translate-xxl-middle-y {
    transform: translateY(-50%) !important;
  }
  .border-xxl {
    border: 1px solid #d8e2ef !important;
  }
  .border-xxl-0 {
    border: 0 !important;
  }
  .border-xxl-top {
    border-top: 1px solid #d8e2ef !important;
  }
  .border-xxl-top-0 {
    border-top: 0 !important;
  }
  .border-xxl-right {
    border-right: 1px solid #d8e2ef !important;
  }
  .border-xxl-right-0 {
    border-right: 0 !important;
  }
  .border-xxl-bottom {
    border-bottom: 1px solid #d8e2ef !important;
  }
  .border-xxl-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-xxl-left {
    border-left: 1px solid #d8e2ef !important;
  }
  .border-xxl-left-0 {
    border-left: 0 !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .vw-xxl-25 {
    width: 25vw !important;
  }
  .vw-xxl-50 {
    width: 50vw !important;
  }
  .vw-xxl-75 {
    width: 75vw !important;
  }
  .vw-xxl-100 {
    width: 100vw !important;
  }
  .h-xxl-25 {
    height: 25% !important;
  }
  .h-xxl-50 {
    height: 50% !important;
  }
  .h-xxl-75 {
    height: 75% !important;
  }
  .h-xxl-100 {
    height: 100% !important;
  }
  .h-xxl-auto {
    height: auto !important;
  }
  .vh-xxl-25 {
    height: 25vh !important;
  }
  .vh-xxl-50 {
    height: 50vh !important;
  }
  .vh-xxl-75 {
    height: 75vh !important;
  }
  .vh-xxl-100 {
    height: 100vh !important;
  }
  .min-vh-xxl-25 {
    min-height: 25vh !important;
  }
  .min-vh-xxl-50 {
    min-height: 50vh !important;
  }
  .min-vh-xxl-75 {
    min-height: 75vh !important;
  }
  .min-vh-xxl-100 {
    min-height: 100vh !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.8rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-6 {
    margin: 4rem !important;
  }
  .m-xxl-7 {
    margin: 5rem !important;
  }
  .m-xxl-8 {
    margin: 7.5rem !important;
  }
  .m-xxl-9 {
    margin: 10rem !important;
  }
  .m-xxl-10 {
    margin: 12.5rem !important;
  }
  .m-xxl-11 {
    margin: 15rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xxl-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xxl-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-xxl-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xxl-10 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }
  .mx-xxl-11 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xxl-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xxl-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-xxl-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xxl-10 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }
  .my-xxl-11 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.8rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-6 {
    margin-top: 4rem !important;
  }
  .mt-xxl-7 {
    margin-top: 5rem !important;
  }
  .mt-xxl-8 {
    margin-top: 7.5rem !important;
  }
  .mt-xxl-9 {
    margin-top: 10rem !important;
  }
  .mt-xxl-10 {
    margin-top: 12.5rem !important;
  }
  .mt-xxl-11 {
    margin-top: 15rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-0 {
    margin-right: 0 !important;
  }
  .mr-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .mr-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mr-xxl-3 {
    margin-right: 1rem !important;
  }
  .mr-xxl-4 {
    margin-right: 1.8rem !important;
  }
  .mr-xxl-5 {
    margin-right: 3rem !important;
  }
  .mr-xxl-6 {
    margin-right: 4rem !important;
  }
  .mr-xxl-7 {
    margin-right: 5rem !important;
  }
  .mr-xxl-8 {
    margin-right: 7.5rem !important;
  }
  .mr-xxl-9 {
    margin-right: 10rem !important;
  }
  .mr-xxl-10 {
    margin-right: 12.5rem !important;
  }
  .mr-xxl-11 {
    margin-right: 15rem !important;
  }
  .mr-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.8rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 7.5rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 10rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 12.5rem !important;
  }
  .mb-xxl-11 {
    margin-bottom: 15rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-0 {
    margin-left: 0 !important;
  }
  .ml-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ml-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ml-xxl-3 {
    margin-left: 1rem !important;
  }
  .ml-xxl-4 {
    margin-left: 1.8rem !important;
  }
  .ml-xxl-5 {
    margin-left: 3rem !important;
  }
  .ml-xxl-6 {
    margin-left: 4rem !important;
  }
  .ml-xxl-7 {
    margin-left: 5rem !important;
  }
  .ml-xxl-8 {
    margin-left: 7.5rem !important;
  }
  .ml-xxl-9 {
    margin-left: 10rem !important;
  }
  .ml-xxl-10 {
    margin-left: 12.5rem !important;
  }
  .ml-xxl-11 {
    margin-left: 15rem !important;
  }
  .ml-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.8rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .m-xxl-n6 {
    margin: -4rem !important;
  }
  .m-xxl-n7 {
    margin: -5rem !important;
  }
  .m-xxl-n8 {
    margin: -7.5rem !important;
  }
  .m-xxl-n9 {
    margin: -10rem !important;
  }
  .m-xxl-n10 {
    margin: -12.5rem !important;
  }
  .m-xxl-n11 {
    margin: -15rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.8rem !important;
    margin-left: -1.8rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xxl-n6 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-xxl-n7 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-xxl-n8 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-xxl-n9 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-xxl-n10 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }
  .mx-xxl-n11 {
    margin-right: -15rem !important;
    margin-left: -15rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.8rem !important;
    margin-bottom: -1.8rem !important;
  }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xxl-n6 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-xxl-n7 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-xxl-n8 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-xxl-n9 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-xxl-n10 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }
  .my-xxl-n11 {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.8rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -4rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -5rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -7.5rem !important;
  }
  .mt-xxl-n9 {
    margin-top: -10rem !important;
  }
  .mt-xxl-n10 {
    margin-top: -12.5rem !important;
  }
  .mt-xxl-n11 {
    margin-top: -15rem !important;
  }
  .mr-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .mr-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mr-xxl-n3 {
    margin-right: -1rem !important;
  }
  .mr-xxl-n4 {
    margin-right: -1.8rem !important;
  }
  .mr-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mr-xxl-n6 {
    margin-right: -4rem !important;
  }
  .mr-xxl-n7 {
    margin-right: -5rem !important;
  }
  .mr-xxl-n8 {
    margin-right: -7.5rem !important;
  }
  .mr-xxl-n9 {
    margin-right: -10rem !important;
  }
  .mr-xxl-n10 {
    margin-right: -12.5rem !important;
  }
  .mr-xxl-n11 {
    margin-right: -15rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.8rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -4rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -5rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -7.5rem !important;
  }
  .mb-xxl-n9 {
    margin-bottom: -10rem !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -12.5rem !important;
  }
  .mb-xxl-n11 {
    margin-bottom: -15rem !important;
  }
  .ml-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ml-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ml-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ml-xxl-n4 {
    margin-left: -1.8rem !important;
  }
  .ml-xxl-n5 {
    margin-left: -3rem !important;
  }
  .ml-xxl-n6 {
    margin-left: -4rem !important;
  }
  .ml-xxl-n7 {
    margin-left: -5rem !important;
  }
  .ml-xxl-n8 {
    margin-left: -7.5rem !important;
  }
  .ml-xxl-n9 {
    margin-left: -10rem !important;
  }
  .ml-xxl-n10 {
    margin-left: -12.5rem !important;
  }
  .ml-xxl-n11 {
    margin-left: -15rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.8rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .p-xxl-6 {
    padding: 4rem !important;
  }
  .p-xxl-7 {
    padding: 5rem !important;
  }
  .p-xxl-8 {
    padding: 7.5rem !important;
  }
  .p-xxl-9 {
    padding: 10rem !important;
  }
  .p-xxl-10 {
    padding: 12.5rem !important;
  }
  .p-xxl-11 {
    padding: 15rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xxl-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xxl-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-xxl-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-xxl-10 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }
  .px-xxl-11 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xxl-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xxl-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-xxl-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-xxl-10 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .py-xxl-11 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.8rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pt-xxl-6 {
    padding-top: 4rem !important;
  }
  .pt-xxl-7 {
    padding-top: 5rem !important;
  }
  .pt-xxl-8 {
    padding-top: 7.5rem !important;
  }
  .pt-xxl-9 {
    padding-top: 10rem !important;
  }
  .pt-xxl-10 {
    padding-top: 12.5rem !important;
  }
  .pt-xxl-11 {
    padding-top: 15rem !important;
  }
  .pr-xxl-0 {
    padding-right: 0 !important;
  }
  .pr-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pr-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pr-xxl-3 {
    padding-right: 1rem !important;
  }
  .pr-xxl-4 {
    padding-right: 1.8rem !important;
  }
  .pr-xxl-5 {
    padding-right: 3rem !important;
  }
  .pr-xxl-6 {
    padding-right: 4rem !important;
  }
  .pr-xxl-7 {
    padding-right: 5rem !important;
  }
  .pr-xxl-8 {
    padding-right: 7.5rem !important;
  }
  .pr-xxl-9 {
    padding-right: 10rem !important;
  }
  .pr-xxl-10 {
    padding-right: 12.5rem !important;
  }
  .pr-xxl-11 {
    padding-right: 15rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.8rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 4rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 5rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 7.5rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 10rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 12.5rem !important;
  }
  .pb-xxl-11 {
    padding-bottom: 15rem !important;
  }
  .pl-xxl-0 {
    padding-left: 0 !important;
  }
  .pl-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .pl-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .pl-xxl-3 {
    padding-left: 1rem !important;
  }
  .pl-xxl-4 {
    padding-left: 1.8rem !important;
  }
  .pl-xxl-5 {
    padding-left: 3rem !important;
  }
  .pl-xxl-6 {
    padding-left: 4rem !important;
  }
  .pl-xxl-7 {
    padding-left: 5rem !important;
  }
  .pl-xxl-8 {
    padding-left: 7.5rem !important;
  }
  .pl-xxl-9 {
    padding-left: 10rem !important;
  }
  .pl-xxl-10 {
    padding-left: 12.5rem !important;
  }
  .pl-xxl-11 {
    padding-left: 15rem !important;
  }
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
  .rounded-xxl-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-xxl-top-lg {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .rounded-xxl-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .rounded-xxl-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-xxl-right-lg {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .rounded-xxl-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .rounded-xxl-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-xxl-bottom-lg {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .rounded-xxl-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .rounded-xxl-left {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-xxl-left-lg {
    border-bottom-left-radius: 0.375rem !important;
    border-top-left-radius: 0.375rem !important;
  }
  .rounded-xxl-left-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .opacity-xxl-0 {
    opacity: 0 !important;
  }
  .opacity-xxl-25 {
    opacity: 0.25 !important;
  }
  .opacity-xxl-50 {
    opacity: 0.5 !important;
  }
  .opacity-xxl-75 {
    opacity: 0.75 !important;
  }
  .opacity-xxl-85 {
    opacity: 0.85 !important;
  }
  .opacity-xxl-100 {
    opacity: 1 !important;
  }
  .fs-xxl--2 {
    font-size: 0.69444rem !important;
  }
  .fs-xxl--1 {
    font-size: 0.83333rem !important;
  }
  .fs-xxl-0 {
    font-size: 1rem !important;
  }
  .fs-xxl-1 {
    font-size: 1.2rem !important;
  }
  .fs-xxl-2 {
    font-size: 1.44rem !important;
  }
  .fs-xxl-3 {
    font-size: 1.728rem !important;
  }
  .fs-xxl-4 {
    font-size: 2.0736rem !important;
  }
  .fs-xxl-5 {
    font-size: 2.48832rem !important;
  }
  .fs-xxl-6 {
    font-size: 2.98598rem !important;
  }
  .fs-xxl-7 {
    font-size: 3.58318rem !important;
  }
  .fs-xxl-8 {
    font-size: 4.29982rem !important;
  }
  .max-vh-xxl-25 {
    max-height: 25vh !important;
  }
  .max-vh-xxl-50 {
    max-height: 50vh !important;
  }
  .max-vh-xxl-75 {
    max-height: 75vh !important;
  }
  .max-vh-xxl-100 {
    max-height: 100vh !important;
  }
  .border-xxl-x {
    border-left: 1px solid #d8e2ef !important;
    border-right: 1px solid #d8e2ef !important;
  }
  .border-xxl-x-0 {
    border-left: 0 !important;
    border-right: 0 !important;
  }
  .border-xxl-y {
    border-top: 1px solid #d8e2ef !important;
    border-bottom: 1px solid #d8e2ef !important;
  }
  .border-xxl-y-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }
  .border-xxl-dashed {
    border: 1px dashed #d8e2ef !important;
  }
  .border-xxl-dashed-top {
    border-top: 1px dashed #d8e2ef !important;
  }
  .border-xxl-dashed-right {
    border-right: 1px dashed #d8e2ef !important;
  }
  .border-xxl-dashed-left {
    border-left: 1px dashed #d8e2ef !important;
  }
  .border-xxl-dashed-bottom {
    border-bottom: 1px dashed #d8e2ef !important;
  }
  .border-xxl-dashed-x {
    border-left: 1px dashed #d8e2ef !important;
    border-right: 1px dashed #d8e2ef !important;
  }
  .border-xxl-dashed-y {
    border-top: 1px dashed #d8e2ef !important;
    border-bottom: 1px dashed #d8e2ef !important;
  }
  .rounded-xxl-0 {
    border-radius: 0 !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}

/*-----------------------------------------------
|   Theme Styles
-----------------------------------------------*/
/*-----------------------------------------------
|   Reboot
-----------------------------------------------*/
::placeholder {
  opacity: 1;
  color: #b6c1d2 !important;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 6.3125rem;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

pre, code {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

section {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

@media (min-width: 992px) {
  section {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
}

input,
button,
select,
optgroup,
textarea,
label,
.alert,
.badge,
.blockquote-footer,
.btn,
.navbar,
.pagination,
.valid-feedback,
.invalid-feedback {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

pre {
  margin: 0;
  transform: translate3d(0, 0, 0);
}

strong {
  font-weight: 600;
}

label {
  font-weight: 500 !important;
  margin-bottom: 0.5rem;
}

/* -------------------------------------------------------------------------- */
/*                                   Navbar                                   */
/* -------------------------------------------------------------------------- */
.navbar .dropdown-menu {
  overflow: visible;
  left: -0.5625rem;
}

.navbar .dropdown-menu:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 1rem;
  height: 1rem;
  background: #000;
  top: -0.375rem;
  left: 1.8rem;
  transform: rotate(45deg);
  background: inherit;
  border-radius: 0.125rem;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: #f9fafd;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.navbar .dropdown-menu.dropdown-menu-right {
  left: auto;
  right: -0.5625rem;
}

.navbar .dropdown-menu.dropdown-menu-right:after {
  right: 0.95rem;
  left: auto;
}

.navbar .dropdown-item {
  font-weight: 500;
}

.navbar .dropdown-item:hover, .navbar .dropdown-item:focus {
  background-color: initial;
  color: #232e3c;
}

.navbar-toggler-humburger-icon {
  height: 2.5rem;
  width: 2.5rem;
  padding: 0.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.navbar-toggler-humburger-icon:hover, .navbar-toggler-humburger-icon:focus {
  background-color: #d8e2ef;
}

.navbar-toggle-icon {
  position: relative;
  height: 0.125rem;
  width: 1.25rem;
  transition: all 0.2s ease-in-out;
  display: block;
  top: -0.3125rem;
  padding-right: 0;
}

.navbar-toggle-icon .toggle-line {
  display: block;
  height: 100%;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  background-color: #5e6e82;
}

.navbar-toggle-icon:after, .navbar-toggle-icon:before {
  content: "";
  position: absolute;
  height: 0.125rem;
  background-color: #5e6e82;
  left: 0;
  transition: all 0.2s ease-in-out;
  border-radius: 0.25rem;
  width: 100%;
}

.navbar-toggle-icon:before {
  top: 0.3125rem;
}

.navbar-toggle-icon:after {
  top: 0.625rem;
}

.navbar-glass {
  background-color: rgba(237, 242, 249, 0.96);
}

.navbar-brand {
  font-weight: 800;
}

.navbar-glass-shadow {
  box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.2) !important;
}

/* -------------------------------------------------------------------------- */
/*                                    Badge                                   */
/* -------------------------------------------------------------------------- */
.badge-soft-primary {
  color: #1c4f93;
  background-color: #d5e5fa;
}

.badge-soft-primary[href]:hover, .badge-soft-primary[href]:focus {
  text-decoration: none;
  color: #1c4f93;
  background-color: #2b2e32;
}

.badge-soft-secondary {
  color: #7d899b;
  background-color: #e3e6ea;
}

.badge-soft-secondary[href]:hover, .badge-soft-secondary[href]:focus {
  text-decoration: none;
  color: #7d899b;
  background-color: #2d2e2f;
}

.badge-soft-success {
  color: #00864e;
  background-color: #ccf6e4;
}

.badge-soft-success[href]:hover, .badge-soft-success[href]:focus {
  text-decoration: none;
  color: #00864e;
  background-color: #29312e;
}

.badge-soft-info {
  color: #1978a2;
  background-color: #d4f2ff;
}

.badge-soft-info[href]:hover, .badge-soft-info[href]:focus {
  text-decoration: none;
  color: #1978a2;
  background-color: #2a3033;
}

.badge-soft-warning {
  color: #9d5228;
  background-color: #fde6d8;
}

.badge-soft-warning[href]:hover, .badge-soft-warning[href]:focus {
  text-decoration: none;
  color: #9d5228;
  background-color: #332e2b;
}

.badge-soft-danger {
  color: #932338;
  background-color: #fad7dd;
}

.badge-soft-danger[href]:hover, .badge-soft-danger[href]:focus {
  text-decoration: none;
  color: #932338;
  background-color: #322b2c;
}

.badge-soft-light {
  color: #9fa0a2;
  background-color: #fefeff;
}

.badge-soft-light[href]:hover, .badge-soft-light[href]:focus {
  text-decoration: none;
  color: #9fa0a2;
  background-color: #333333;
}

.badge-soft-dark {
  color: #070f19;
  background-color: #ced1d4;
}

.badge-soft-dark[href]:hover, .badge-soft-dark[href]:focus {
  text-decoration: none;
  color: #070f19;
  background-color: #292a2a;
}

/*-----------------------------------------------
|   Card
-----------------------------------------------*/
.card {
  box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
}

.card-link {
  font-size: 0.83333rem;
  font-weight: 600;
}

.card-title {
  font-weight: 600;
  font-size: 1.2rem;
}

.card-body:last-child {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

/*-----------------------------------------------
|   Card spacer
-----------------------------------------------*/
.pl-card,
.px-card,
.p-card {
  padding-left: 1.25rem !important;
}

.pr-card,
.px-card,
.p-card {
  padding-right: 1.25rem !important;
}

.pt-card,
.py-card,
.p-card {
  padding-top: 1.25rem !important;
}

.pb-card,
.py-card,
.p-card {
  padding-bottom: 1.25rem !important;
}

.mt-card {
  margin-top: 1.25rem !important;
}

.mr-card {
  margin-right: 1.25rem !important;
}

/* -------------------------------------------------------------------------- */
/*                               Browser Fixing                               */
/* -------------------------------------------------------------------------- */
.firefox .dropcap:first-letter {
  margin-top: 0.175em;
}

.windows.chrome .btn-close {
  background-clip: unset;
}

/*-----------------------------------------------
|   Button
-----------------------------------------------*/
button.disabled, button:disabled,
.btn.disabled,
.btn:disabled {
  cursor: not-allowed;
}

/*-----------------------------------------------
|   Button Reveal
-----------------------------------------------*/
.btn-reveal-trigger:hover .btn-reveal, .btn-reveal-trigger:focus .btn-reveal {
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
}

.btn-reveal-trigger:hover .btn-reveal:hover, .btn-reveal-trigger:hover .btn-reveal:focus, .btn-reveal-trigger:hover .btn-reveal:active, .btn-reveal-trigger:focus .btn-reveal:hover, .btn-reveal-trigger:focus .btn-reveal:focus, .btn-reveal-trigger:focus .btn-reveal:active {
  color: #0b1727 !important;
}

.btn-reveal-trigger .btn-reveal:active:focus, .btn-reveal-trigger .btn-reveal:active, .btn-reveal-trigger .btn-reveal:focus {
  box-shadow: none;
  color: #0b1727 !important;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

.btn-reveal-trigger .dropdown-toggle:after {
  display: none;
}

/*-----------------------------------------------
|   Falcon buttons
-----------------------------------------------*/
.btn-falcon-primary {
  color: #2c7be5;
  background-color: #fff;
  background-image: var(--bs-gradient);
  border-color: #fff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.08) !important;
}

.btn-falcon-primary:hover {
  color: #2c7be5;
  background-color: #fff;
  background-image: var(--bs-gradient);
  border-color: #fff;
}

.btn-check:focus + .btn-falcon-primary, .btn-falcon-primary:focus {
  color: #2c7be5;
  background-color: #fff;
  background-image: var(--bs-gradient);
  border-color: #fff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0 rgba(223, 235, 251, 0.5);
}

.btn-check:checked + .btn-falcon-primary,
.btn-check:active + .btn-falcon-primary, .btn-falcon-primary:active, .btn-falcon-primary.active,
.show > .btn-falcon-primary.dropdown-toggle {
  color: #4d5969;
  background-color: #d5e5fa;
  background-image: none;
  border-color: white;
}

.btn-check:checked + .btn-falcon-primary:focus,
.btn-check:active + .btn-falcon-primary:focus, .btn-falcon-primary:active:focus, .btn-falcon-primary.active:focus,
.show > .btn-falcon-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(223, 235, 251, 0.5);
}

.btn-falcon-primary:disabled, .btn-falcon-primary.disabled {
  color: #4d5969;
  background-color: #fff;
  background-image: none;
  border-color: #fff;
}

.btn-falcon-primary:hover:not(.disabled):not(:disabled), .btn-falcon-primary:focus:not(.disabled):not(:disabled) {
  color: #1966cc !important;
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.1), 0 3px 9px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
}

.btn-falcon-primary:active {
  box-shadow: none !important;
  color: #1966cc !important;
}

.btn-falcon-success {
  color: #00d27a;
  background-color: #fff;
  background-image: var(--bs-gradient);
  border-color: #fff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.08) !important;
}

.btn-falcon-success:hover {
  color: #00d27a;
  background-color: #fff;
  background-image: var(--bs-gradient);
  border-color: #fff;
}

.btn-check:focus + .btn-falcon-success, .btn-falcon-success:focus {
  color: #00d27a;
  background-color: #fff;
  background-image: var(--bs-gradient);
  border-color: #fff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0 rgba(217, 248, 235, 0.5);
}

.btn-check:checked + .btn-falcon-success,
.btn-check:active + .btn-falcon-success, .btn-falcon-success:active, .btn-falcon-success.active,
.show > .btn-falcon-success.dropdown-toggle {
  color: #4d5969;
  background-color: #ccf6e4;
  background-image: none;
  border-color: white;
}

.btn-check:checked + .btn-falcon-success:focus,
.btn-check:active + .btn-falcon-success:focus, .btn-falcon-success:active:focus, .btn-falcon-success.active:focus,
.show > .btn-falcon-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(217, 248, 235, 0.5);
}

.btn-falcon-success:disabled, .btn-falcon-success.disabled {
  color: #4d5969;
  background-color: #fff;
  background-image: none;
  border-color: #fff;
}

.btn-falcon-success:hover:not(.disabled):not(:disabled), .btn-falcon-success:focus:not(.disabled):not(:disabled) {
  color: #00a761 !important;
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.1), 0 3px 9px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
}

.btn-falcon-success:active {
  box-shadow: none !important;
  color: #00a761 !important;
}

.btn-falcon-info {
  color: #27bcfd;
  background-color: #fff;
  background-image: var(--bs-gradient);
  border-color: #fff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.08) !important;
}

.btn-falcon-info:hover {
  color: #27bcfd;
  background-color: #fff;
  background-image: var(--bs-gradient);
  border-color: #fff;
}

.btn-check:focus + .btn-falcon-info, .btn-falcon-info:focus {
  color: #27bcfd;
  background-color: #fff;
  background-image: var(--bs-gradient);
  border-color: #fff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0 rgba(223, 245, 255, 0.5);
}

.btn-check:checked + .btn-falcon-info,
.btn-check:active + .btn-falcon-info, .btn-falcon-info:active, .btn-falcon-info.active,
.show > .btn-falcon-info.dropdown-toggle {
  color: #4d5969;
  background-color: #d4f2ff;
  background-image: none;
  border-color: white;
}

.btn-check:checked + .btn-falcon-info:focus,
.btn-check:active + .btn-falcon-info:focus, .btn-falcon-info:active:focus, .btn-falcon-info.active:focus,
.show > .btn-falcon-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(223, 245, 255, 0.5);
}

.btn-falcon-info:disabled, .btn-falcon-info.disabled {
  color: #4d5969;
  background-color: #fff;
  background-image: none;
  border-color: #fff;
}

.btn-falcon-info:hover:not(.disabled):not(:disabled), .btn-falcon-info:focus:not(.disabled):not(:disabled) {
  color: #02acf6 !important;
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.1), 0 3px 9px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
}

.btn-falcon-info:active {
  box-shadow: none !important;
  color: #02acf6 !important;
}

.btn-falcon-warning {
  color: #f5803e;
  background-color: #fff;
  background-image: var(--bs-gradient);
  border-color: #fff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.08) !important;
}

.btn-falcon-warning:hover {
  color: #f5803e;
  background-color: #fff;
  background-image: var(--bs-gradient);
  border-color: #fff;
}

.btn-check:focus + .btn-falcon-warning, .btn-falcon-warning:focus {
  color: #f5803e;
  background-color: #fff;
  background-image: var(--bs-gradient);
  border-color: #fff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0 rgba(254, 236, 226, 0.5);
}

.btn-check:checked + .btn-falcon-warning,
.btn-check:active + .btn-falcon-warning, .btn-falcon-warning:active, .btn-falcon-warning.active,
.show > .btn-falcon-warning.dropdown-toggle {
  color: #4d5969;
  background-color: #fde6d8;
  background-image: none;
  border-color: white;
}

.btn-check:checked + .btn-falcon-warning:focus,
.btn-check:active + .btn-falcon-warning:focus, .btn-falcon-warning:active:focus, .btn-falcon-warning.active:focus,
.show > .btn-falcon-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(254, 236, 226, 0.5);
}

.btn-falcon-warning:disabled, .btn-falcon-warning.disabled {
  color: #4d5969;
  background-color: #fff;
  background-image: none;
  border-color: #fff;
}

.btn-falcon-warning:hover:not(.disabled):not(:disabled), .btn-falcon-warning:focus:not(.disabled):not(:disabled) {
  color: #f36515 !important;
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.1), 0 3px 9px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
}

.btn-falcon-warning:active {
  box-shadow: none !important;
  color: #f36515 !important;
}

.btn-falcon-danger {
  color: #e63757;
  background-color: #fff;
  background-image: var(--bs-gradient);
  border-color: #fff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.08) !important;
}

.btn-falcon-danger:hover {
  color: #e63757;
  background-color: #fff;
  background-image: var(--bs-gradient);
  border-color: #fff;
}

.btn-check:focus + .btn-falcon-danger, .btn-falcon-danger:focus {
  color: #e63757;
  background-color: #fff;
  background-image: var(--bs-gradient);
  border-color: #fff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0 rgba(251, 225, 230, 0.5);
}

.btn-check:checked + .btn-falcon-danger,
.btn-check:active + .btn-falcon-danger, .btn-falcon-danger:active, .btn-falcon-danger.active,
.show > .btn-falcon-danger.dropdown-toggle {
  color: #4d5969;
  background-color: #fad7dd;
  background-image: none;
  border-color: white;
}

.btn-check:checked + .btn-falcon-danger:focus,
.btn-check:active + .btn-falcon-danger:focus, .btn-falcon-danger:active:focus, .btn-falcon-danger.active:focus,
.show > .btn-falcon-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(251, 225, 230, 0.5);
}

.btn-falcon-danger:disabled, .btn-falcon-danger.disabled {
  color: #4d5969;
  background-color: #fff;
  background-image: none;
  border-color: #fff;
}

.btn-falcon-danger:hover:not(.disabled):not(:disabled), .btn-falcon-danger:focus:not(.disabled):not(:disabled) {
  color: #d71b3d !important;
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.1), 0 3px 9px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
}

.btn-falcon-danger:active {
  box-shadow: none !important;
  color: #d71b3d !important;
}

.btn-falcon-default {
  color: #4d5969;
  background-color: #fff;
  background-image: var(--bs-gradient);
  border-color: #fff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.08) !important;
}

.btn-falcon-default:hover {
  color: #4d5969;
  background-color: #fff;
  background-image: var(--bs-gradient);
  border-color: #fff;
}

.btn-check:focus + .btn-falcon-default, .btn-falcon-default:focus {
  color: #4d5969;
  background-color: #fff;
  background-image: var(--bs-gradient);
  border-color: #fff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0 rgba(228, 230, 233, 0.5);
}

.btn-check:checked + .btn-falcon-default,
.btn-check:active + .btn-falcon-default, .btn-falcon-default:active, .btn-falcon-default.active,
.show > .btn-falcon-default.dropdown-toggle {
  color: #4d5969;
  background-color: #e6e6e6;
  background-image: none;
  border-color: #dfdfdf;
}

.btn-check:checked + .btn-falcon-default:focus,
.btn-check:active + .btn-falcon-default:focus, .btn-falcon-default:active:focus, .btn-falcon-default.active:focus,
.show > .btn-falcon-default.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(228, 230, 233, 0.5);
}

.btn-falcon-default:disabled, .btn-falcon-default.disabled {
  color: #4d5969;
  background-color: #fff;
  background-image: none;
  border-color: #fff;
}

.btn-falcon-default:hover:not(.disabled):not(:disabled), .btn-falcon-default:focus:not(.disabled):not(:disabled) {
  color: #010305 !important;
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.1), 0 3px 9px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
}

.btn-falcon-default:active {
  box-shadow: none !important;
  color: #010305 !important;
}

/*-----------------------------------------------
|   Brand Outline Buttons
-----------------------------------------------*/
.btn-outline-facebook {
  color: #3c5a99;
  border-color: #3c5a99;
}

.btn-outline-facebook:hover {
  color: #fff;
  background-color: #3c5a99;
  border-color: #3c5a99;
}

.btn-check:focus + .btn-outline-facebook, .btn-outline-facebook:focus {
  box-shadow: 0 0 0 0 rgba(60, 90, 153, 0.5);
}

.btn-check:checked + .btn-outline-facebook,
.btn-check:active + .btn-outline-facebook, .btn-outline-facebook:active, .btn-outline-facebook.active, .btn-outline-facebook.dropdown-toggle.show {
  color: #fff;
  background-color: #3c5a99;
  border-color: #3c5a99;
}

.btn-check:checked + .btn-outline-facebook:focus,
.btn-check:active + .btn-outline-facebook:focus, .btn-outline-facebook:active:focus, .btn-outline-facebook.active:focus, .btn-outline-facebook.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(60, 90, 153, 0.5);
}

.btn-outline-facebook:disabled, .btn-outline-facebook.disabled {
  color: #3c5a99;
  background-color: transparent;
}

.btn-outline-twitter {
  color: #1da1f2;
  border-color: #1da1f2;
}

.btn-outline-twitter:hover {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2;
}

.btn-check:focus + .btn-outline-twitter, .btn-outline-twitter:focus {
  box-shadow: 0 0 0 0 rgba(29, 161, 242, 0.5);
}

.btn-check:checked + .btn-outline-twitter,
.btn-check:active + .btn-outline-twitter, .btn-outline-twitter:active, .btn-outline-twitter.active, .btn-outline-twitter.dropdown-toggle.show {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2;
}

.btn-check:checked + .btn-outline-twitter:focus,
.btn-check:active + .btn-outline-twitter:focus, .btn-outline-twitter:active:focus, .btn-outline-twitter.active:focus, .btn-outline-twitter.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(29, 161, 242, 0.5);
}

.btn-outline-twitter:disabled, .btn-outline-twitter.disabled {
  color: #1da1f2;
  background-color: transparent;
}

.btn-outline-google-plus {
  color: #dd4b39;
  border-color: #dd4b39;
}

.btn-outline-google-plus:hover {
  color: #fff;
  background-color: #dd4b39;
  border-color: #dd4b39;
}

.btn-check:focus + .btn-outline-google-plus, .btn-outline-google-plus:focus {
  box-shadow: 0 0 0 0 rgba(221, 75, 57, 0.5);
}

.btn-check:checked + .btn-outline-google-plus,
.btn-check:active + .btn-outline-google-plus, .btn-outline-google-plus:active, .btn-outline-google-plus.active, .btn-outline-google-plus.dropdown-toggle.show {
  color: #fff;
  background-color: #dd4b39;
  border-color: #dd4b39;
}

.btn-check:checked + .btn-outline-google-plus:focus,
.btn-check:active + .btn-outline-google-plus:focus, .btn-outline-google-plus:active:focus, .btn-outline-google-plus.active:focus, .btn-outline-google-plus.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(221, 75, 57, 0.5);
}

.btn-outline-google-plus:disabled, .btn-outline-google-plus.disabled {
  color: #dd4b39;
  background-color: transparent;
}

/*-----------------------------------------------
|   Profile Page Introduction
-----------------------------------------------*/
.btn-intro-collapse .less {
  display: none;
}

.btn-intro-collapse[aria-expanded="true"] .less {
  display: inline;
}

.btn-intro-collapse[aria-expanded="true"] .full {
  display: none;
}

/*-----------------------------------------------
|   Documentation link and Prism
-----------------------------------------------*/
.code-block {
  padding: 0;
  overflow: auto;
}

.code-block pre[class*='language-'] {
  padding: 1.6rem;
  border: 0;
  margin: 0;
  border-radius: 0;
}

:not(pre) > code[class*='language-'], pre[class*='language-'] {
  background-color: #232e3c;
  border: 1px solid #edf2f9;
  border-radius: 0.25rem;
}

code.language-html:first-child {
  display: block;
}

code[class*='language-'], pre[class*='language-'] {
  color: #d8e2ef;
  font-family: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

pre[class*="language-"] {
  margin: 0;
}

.contains-anchor a {
  opacity: 0;
  transition-property: opacity;
}

.contains-anchor:hover a, .contains-anchor:focus a {
  opacity: 1;
  text-decoration: none;
}

.token.property, .token.tag, .token.constant, .token.symbol, .token.deleted {
  color: #e63757;
}

.token.punctuation {
  color: #b6c1d2;
}

.components-nav {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.85rem;
}

/*-----------------------------------------------
|   Component examples
-----------------------------------------------*/
/*-----------------------------------------------
|   Borders
-----------------------------------------------*/
.component-example {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.component-example [class^='border'] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  margin: .75rem;
  background-color: #f9fafd;
}

/*-----------------------------------------------
|   Utilities
-----------------------------------------------*/
.border-component [class^=border],
.border-component [class^="rounded-"] {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  margin: .25rem;
}

.border-component [class^=border] {
  background-color: #edf2f9;
}

.border-component [class^="rounded-"] {
  background-color: #344050;
}

#loaders [class^="spinner"] {
  margin-right: 0.5rem;
}

/*-----------------------------------------------
|   Hover Box
-----------------------------------------------*/
.hoverbox {
  position: relative;
  overflow: hidden;
}

.hoverbox .hoverbox-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  margin: 0 !important;
  display: flex;
}

.hoverbox .hoverbox-primary-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
}

.hoverbox:hover .hover-box-content-initial, .hoverbox:focus .hover-box-content-initial {
  opacity: 0;
}

.hoverbox:hover .hoverbox-content, .hoverbox:focus .hoverbox-content {
  opacity: 1;
}

.hoverbox:hover .as-hoverbox-content, .hoverbox:focus .as-hoverbox-content {
  z-index: 1;
}

.hoverbox-content-gradient {
  background: linear-gradient(transparent, #000);
}

/*-----------------------------------------------
|   Object fit and Z-index
-----------------------------------------------*/
.fit-cover {
  object-fit: cover;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index--1 {
  z-index: -1;
}

/*-----------------------------------------------
|   Sidebar
-----------------------------------------------*/
.sticky-sidebar {
  position: sticky;
  z-index: 1015;
  top: 5.3125rem;
}

/*-----------------------------------------------
|   Custom circle
-----------------------------------------------*/
.circle-dashed {
  border: 1px dashed #2c7be5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

/*-----------------------------------------------
|   Miscellaneous
-----------------------------------------------*/
.hover-text-decoration-none:hover, .hover-text-decoration-none:focus {
  text-decoration: none;
}

.resize-none {
  resize: none;
}

.collapsed .collapse-icon {
  transition: all 0.2s ease-in-out;
  transform: rotate(0deg);
}

.collapse-icon {
  transition: all 0.2s ease-in-out;
  transform: rotate(90deg);
}

[data-offset-top] *,
[data-toggle="collapse"] *,
[data-toggle="tooltip"] *,
[data-toggle="popover"] * {
  pointer-events: none;
}

/*-----------------------------------------------
|   Outline
-----------------------------------------------*/
.outline-none {
  outline: none;
  box-shadow: none;
}

.outline-none:hover, .outline-none:focus {
  outline: none;
  box-shadow: none;
}

/*-----------------------------------------------
|   Vertical Line (used in kanban header)
-----------------------------------------------*/
.vertical-line:after {
  position: absolute;
  content: "";
  height: 75%;
  width: 1px;
  background: #d8e2ef;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.vertical-line.vertical-line-400:after {
  background-color: #b6c1d2;
}

/* -------------------------------------------------------------------------- */
/*                                 Transition                                 */
/* -------------------------------------------------------------------------- */
.transition-base {
  transition: all 0.2s ease-in-out;
}

.transition-none {
  transition: none;
}

.fsp-75 {
  font-size: 75%;
}

/* -------------------------------------------------------------------------- */
/*                                    Width                                   */
/* -------------------------------------------------------------------------- */
.min-w-0 {
  min-width: 0;
}

/* -------------------------------------------------------------------------- */
/*                                  Anchor JS                                 */
/* -------------------------------------------------------------------------- */
.anchorjs-link {
  text-decoration: none !important;
}

/* -------------------------------------------------------------------------- */
/*                                   Echarts                                  */
/* -------------------------------------------------------------------------- */
.echart-bar-weekly-sales {
  width: 8.5rem;
}

.echart-line-total-order {
  width: 8.625rem;
  min-height: 5rem;
}

.echart-line-total-sales {
  min-height: 18.4375rem;
}

@media (min-width: 992px) {
  .echart-line-total-sales {
    min-height: 250px;
  }
}

.echart-bar-top-products {
  min-height: 18.4375rem;
}

@media (min-width: 1540px) {
  .echart-bar-top-products {
    min-height: 250px;
  }
}

.echart-market-share {
  width: 6.625rem;
  height: 6.625rem;
}

.echart-world-map {
  min-height: 26.25rem;
}

/* -------------------------------------------------------------------------- */
/*                             Progressbar Circle                             */
/* -------------------------------------------------------------------------- */
.progress-circle {
  height: 15.625rem;
  position: relative;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.progress-circle .progressbar-text {
  text-align: center;
  color: #0b1727 !important;
  margin-bottom: 0;
  line-height: 1;
}

.progress-circle .progressbar-text span {
  font-size: 1rem;
  display: block;
}

.progress-circle .progressbar-text .value {
  font-weight: 600;
  font-size: 2.98598rem;
}

.progress-circle .progressbar-text b {
  font-size: 2.0736rem;
  font-weight: 600;
}

.progress-circle svg {
  height: 100%;
  display: block;
}

.progress-circle-dashboard {
  height: 9.375rem;
  width: 9.375rem;
}

.progress-circle-dashboard .progressbar-text {
  color: #9da9bb !important;
}

.progress-circle-dashboard .progressbar-text .value {
  font-size: 1.728rem;
  font-weight: 500 !important;
}

.progress-circle-dashboard .progressbar-text .value b {
  font-size: 1.728rem;
}

/* -------------------------------------------------------------------------- */
/*                                  Flatpickr                                 */
/* -------------------------------------------------------------------------- */
.flatpickr-calendar {
  background-color: #fff !important;
  box-shadow: none !important;
  border: 1px solid #d8e2ef !important;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff !important;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #d8e2ef !important;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff !important;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #d8e2ef !important;
}

.flatpickr-input:disabled, .flatpickr-input[readonly] {
  background-color: #fff;
}

.flatpickr-time input:hover, .flatpickr-time input:focus {
  background-color: #f9fafd !important;
}

.flatpickr-day.endRange, .flatpickr-day.startRange {
  background-color: #2c7be5 !important;
  border: 0;
}

.flatpickr-day.endRange:hover, .flatpickr-day.endRange:focus, .flatpickr-day.startRange:hover, .flatpickr-day.startRange:focus {
  background-color: #2c7be5 !important;
}

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  background-color: #edf2f9;
  border-color: #edf2f9;
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  background-color: #fff;
  color: #000;
}

.flatpickr-day.selected {
  background-color: #2c7be5;
  border-color: #2c7be5;
}

.flatpickr-day.selected:hover, .flatpickr-day.selected:focus {
  background-color: #2c7be5;
  border-color: #2c7be5;
}

.flatpickr-time input.flatpickr-hour {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 5px;
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  border-top: 0 !important;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: #fff !important;
}

span.flatpickr-weekday,
.flatpickr-weekdaycontainer,
.flatpickr-monthDropdown-months,
.flatpickr-months .flatpickr-month {
  background-color: #fff !important;
}

.flatpickr-wrapper {
  display: block;
}

/* -------------------------------------------------------------------------- */
/*                                    Plyr                                    */
/* -------------------------------------------------------------------------- */
.plyr {
  border-radius: 0.375rem;
}

.plyr__control--overlaid, .plyr__control--overlaid:hover, .plyr__control--overlaid:focus {
  background-color: #e63757;
}

.plyr--video .plyr__controls .plyr__control.plyr__tab-focus, .plyr--video .plyr__controls .plyr__control:hover, .plyr--video .plyr__controls .plyr__control[aria-expanded=true], .plyr__menu__container label.plyr__control input[type=radio]:checked + span {
  background-color: #e63757;
}

.plyr--full-ui input[type=range] {
  color: #e63757;
}

/* -------------------------------------------------------------------------- */
/*                                  Dropzone                                  */
/* -------------------------------------------------------------------------- */
.dropzone {
  position: relative;
  background-color: #fff;
  border: 0;
  background-color: transparent;
  border-radius: 0.375rem;
}

.dropzone.dropzone-has-default .dz-message {
  border-color: transparent;
  transition: all 0.2s ease-in-out;
}

.dropzone.dropzone-has-default .dz-message:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.dropzone .dz-preview {
  min-height: auto;
}

.dropzone .dz-remove {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  color: #fff;
  z-index: 1000;
  cursor: pointer;
  opacity: 0;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
}

.dropzone .dz-remove * {
  cursor: pointer;
}

.dropzone .dz-message {
  position: relative;
  padding: 5rem 2rem;
  margin: 0;
  border: 2px dashed #d8e2ef;
  border-radius: 0.375rem;
}

.dropzone.dropzone-single.dz-file-processing .dz-message {
  display: none;
}

.dropzone.dropzone-single.dz-file-processing.dz-file-complete .dz-message {
  display: block;
}

.dropzone.dropzone-single.dz-file-processing.dz-file-complete .dz-default-image {
  opacity: 0;
}

.dropzone.dropzone-single .dz-processing .dz-message-text {
  opacity: 0 !important;
}

.dropzone.dropzone-single .dz-progress {
  opacity: 1;
  transition: opacity 0.4s ease-in;
  animation: none !important;
  width: 9.375rem !important;
  height: 0.5rem !important;
  margin: 0 !important;
  transform: translateX(-50%) !important;
  top: auto !important;
  bottom: 1.5rem;
}

.dropzone.dropzone-single.dz-max-files-reached .dz-message {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  border-color: transparent;
}

.dropzone.dropzone-single.dz-max-files-reached .dz-remove {
  opacity: 1;
}

.dropzone.dropzone-single.dz-max-files-reached:hover .dz-message, .dropzone.dropzone-single.dz-max-files-reached:focus .dz-message {
  opacity: 1;
}

.dropzone.dropzone-single .dz-processing .dz-progress {
  opacity: 1;
}

.dropzone.dropzone-single .dz-complete .dz-progress {
  opacity: 0;
}

.dropzone .dz-preview-single {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
}

.dropzone .dz-preview-single:hover {
  z-index: auto;
}

.dropzone .dz-preview-single .dz-preview-cover {
  position: absolute;
  height: 100%;
  width: 100%;
  border: 0 !important;
  overflow: hidden;
}

.dropzone .dz-preview-single .dz-preview-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.375rem;
}

.dropzone.dropzone-multiple {
  border: 0;
  background: transparent;
}

.dropzone.dropzone-multiple .dz-message {
  padding: 4rem 2rem;
  border: 2px dashed #d8e2ef;
  border-radius: 0.375rem;
}

.dropzone.dropzone-multiple .btn * {
  cursor: pointer;
}

.dropzone.dropzone-multiple .media:first-child {
  margin-top: 1.8rem;
}

.dropzone.dropzone-multiple .dz-image {
  border-radius: 0.25rem;
  height: 2.5rem;
  width: 2.5rem;
  object-fit: cover;
  margin-right: 1rem;
  border: 1px solid #d8e2ef;
}

.dropzone.dropzone-multiple .dz-progress {
  opacity: 0;
  position: relative !important;
  transition: opacity 0.4s ease-in;
  animation: none !important;
  width: 6.25rem !important;
  height: 0.5rem !important;
  margin-top: 0;
  margin-left: 1rem !important;
  transform: none !important;
  top: auto !important;
  left: auto !important;
}

.dropzone.dropzone-multiple .dz-complete .dz-progress {
  transition-delay: 1s;
  opacity: 1;
}

.dropzone.dz-drag-hover .dz-message {
  border-color: #2c7be5;
}

.dropzone .dz-progress {
  background: #edf2f9 !important;
}

.dropzone .dz-progress .dz-upload {
  background: #2c7be5 !important;
}

.dropzone-area {
  border: 2px dashed #d8e2ef;
  border-radius: 0.25rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* -------------------------------------------------------------------------- */
/*                                   Choices                                  */
/* -------------------------------------------------------------------------- */
.choices {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

.choices .choices__inner {
  border-radius: 0.25rem;
  min-height: calc(1.5em + 0.625rem + 2px);
  line-height: 1.5rem !important;
  font-size: 1rem;
  background-color: #fff !important;
  background-size: 16px 12px;
  border: 1px solid #d8e2ef;
  padding: 0 2rem 0.3125rem 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

.choices .choices__input--cloned {
  padding: 0;
  font-size: 1rem;
  color: #000;
  background-color: #fff !important;
  margin-top: 5px !important;
  vertical-align: top;
  margin-bottom: 0;
}

.choices .choices__list--dropdown {
  border: 1px solid #d8e2ef !important;
  box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07) !important;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.choices .choices__list--dropdown .choices__item--selectable {
  padding: 2px 1rem;
  padding-right: 20px;
  font-size: 1rem !important;
  color: #000;
}

.choices .choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: #2c7be5;
  color: #fff;
}

.choices .choices__list {
  margin-top: 0 !important;
}

.choices .choices__list--multiple .choices__item {
  padding: 0 8px !important;
  font-size: 13px !important;
  border: 0;
  background-color: #edf2f9;
  border-radius: 4px;
  margin-bottom: 0 !important;
  margin-top: 5px !important;
  color: #000;
}

.choices .choices__button {
  background-color: red !important;
  border-left: #000 !important;
}

.choices .choices__list--single {
  padding: 0 !important;
  margin-top: 5px !important;
}

.choices .choices__list--single .choices__button {
  background-color: transparent !important;
}

.choices[data-type*=select-multiple] .choices__button, .choices[data-type*=text] .choices__button {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  background-color: transparent !important;
  padding-left: 5px !important;
  width: 5px !important;
  background-size: 6px !important;
}

.choices[data-type*=select-one] .choices__input {
  padding-left: 1rem;
  padding-right: 1rem;
}

.choices[data-type*=select-one] .choices__inner {
  padding-bottom: 5px !important;
}

.is-focused .choices__inner,
.is-open .choices__inner {
  border-radius: 0.25rem !important;
  border-color: #d8e2ef !important;
}

/* -------------------------------------------------------------------------- */
/*                                   Leaflet                                  */
/* -------------------------------------------------------------------------- */
.leaflet-bar {
  box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
}

.marker-cluster-small {
  background-color: rgba(44, 123, 229, 0.2);
}

.marker-cluster-small div {
  margin: 5px;
  background-color: #2c7be5;
  color: #fff;
}

.marker-cluster-medium {
  background-color: rgba(245, 128, 62, 0.2);
}

.marker-cluster-medium div {
  margin: 5px;
  background-color: #f5803e;
  color: #fff;
}

.marker-cluster-large {
  background-color: rgba(0, 210, 122, 0.2);
}

.marker-cluster-large div {
  margin: 5px;
  background-color: #00d27a;
  color: #fff;
}

.leaflet-popup-content-wrapper {
  box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.175);
  border-radius: 0.25rem;
}

.leaflet-popup-close-button {
  right: 3px !important;
  top: 3px !important;
}

.leaflet-marker-icon {
  height: auto !important;
  width: auto !important;
}

.leaflet-control-attribution {
  display: none;
}

.leaflet-top, .leaflet-bottom {
  z-index: 999;
}

.leaflet-left .leaflet-control {
  margin-left: 1.25rem;
}

.leaflet-right .leaflet-control {
  margin-right: 1.25rem;
}

.leaflet-top .leaflet-control {
  margin-top: 1.25rem;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 1.25rem;
}

/*-----------------------------------------------
|   Tinymce
-----------------------------------------------*/
.tox-editor-container .tox-toolbar {
  border: 0 !important;
}

.tinymce-mobile-toolbar,
.tox-edit-area,
.tox-tinymce {
  border-color: #edf2f9 !important;
}

.tox-tbtn:not([disabled="disabled"]) {
  color: #5e6e82 !important;
}

.tox .tox-tbtn--enabled,
.tox .tox-tbtn:active,
.tox .tox-tbtn:hover,
.tox .tox-tbtn:focus {
  background-color: #d8e2ef !important;
}

.tox .tox-tbtn:not([disabled="disabled"]) svg {
  fill: #5e6e82 !important;
}

.tinymce-mobile-toolstrip .tinymce-mobile-toolbar:not(.tinymce-mobile-context-toolbar) .tinymce-mobile-toolbar-group .tinymce-mobile-toolbar-group-item.tinymce-mobile-toolbar-button.tinymce-mobile-toolbar-button-selected {
  background-color: #d8e2ef !important;
  color: #5e6e82 !important;
  border-radius: 0.25rem;
}

.tox-collection__item--active {
  background-color: #d8e2ef !important;
  color: #5e6e82 !important;
}

.tox .tox-tbtn--select {
  padding: 0 10px !important;
}

.tinymce-mobile-icon-full-dot:before {
  color: #d8e2ef;
}

.tinymce-mobile-icon:not(.tinymce-mobile-icon-back),
.tinymce-mobile-icon-large-font:before,
.tinymce-mobile-icon-style-formats:before,
.tox .tox-collection__item {
  color: #5e6e82 !important;
}

.tinymce-mobile-toolbar-button svg,
.tox-collection__item-caret svg {
  fill: #5e6e82 !important;
}

.tox .tox-toolbar__group:not(:last-of-type) {
  border-right: 1px solid #edf2f9 !important;
}

.tinymce-mobile-toolstrip .tinymce-mobile-toolbar:not(.tinymce-mobile-context-toolbar) .tinymce-mobile-toolbar-group:first-of-type,
.tinymce-mobile-toolstrip .tinymce-mobile-toolbar:not(.tinymce-mobile-context-toolbar) .tinymce-mobile-toolbar-group:last-of-type {
  background-color: #2c7be5 !important;
}

.tinymce-mobile-disabled-mask {
  background-color: #edf2f9 !important;
}

.tinymce-mobile-outer-container:not(.tinymce-mobile-fullscreen-maximized) .tinymce-mobile-editor-socket {
  height: 50vh !important;
}

.tox .tox-edit-area__iframe {
  background-color: #fff !important;
}

.tox .tox-menu,
.tox .tox-dialog {
  border-radius: 0.25rem !important;
  border-color: #d8e2ef !important;
  background-color: #fff !important;
}

.tox .tox-menu__header, .tox .tox-menu__footer,
.tox .tox-dialog__header,
.tox .tox-dialog__footer {
  background-color: #fff !important;
  border-color: #d8e2ef !important;
}

.tox .tox-toolbar {
  background-color: #fff !important;
}

.tox .tox-selectfield select,
.tox .tox-textarea,
.tox .tox-textfield,
.tox .tox-toolbar-textfield {
  border-color: #d8e2ef !important;
}

.tox .tox-insert-table-picker > div {
  border-color: #d8e2ef !important;
}

/* -------------------------------------------------------------------------- */
/*                                  Glightbox                                 */
/* -------------------------------------------------------------------------- */
.glightbox-open {
  overflow: unset !important;
}

.gscrollbar-fixer {
  margin-right: 0 !important;
}

/* -------------------------------------------------------------------------- */
/*                                 Google Map                                 */
/* -------------------------------------------------------------------------- */
.googlemap .gm-style-iw.gm-style-iw-c {
  box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07) !important;
  padding: 1rem !important;
}

.googlemap .gm-style-iw.gm-style-iw-c button[title="Close"] {
  margin-top: 1rem !important;
  margin-right: 0.5rem !important;
}

html[dir="rtl"] .googlemap .gm-style-iw.gm-style-iw-c button[title="Close"] {
  left: unset !important;
  right: 0 !important;
}

[data-list] .sort[data-sort] {
  white-space: nowrap;
  cursor: pointer;
}

[data-list] .sort[data-sort]::after {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgNyA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNi41MjU1NiAzLjVDNi45NDkzNyAzLjUgNy4xNjEyOCAzLjA2MjUgNi44NTUxOSAyLjc5MTY3TDMuODQxNCAwLjEyNUMzLjY1MzA0IC0wLjA0MTY2NjcgMy4zNDY5NiAtMC4wNDE2NjY3IDMuMTU4NTkgMC4xMjVMMC4xNDQ4MDkgMi43OTE2N0MtMC4xNjEyNzkgMy4wNjI1IDAuMDUwNjI3OSAzLjUgMC40NzQ0NDEgMy41SDYuNTI1NTZaIiBmaWxsPSIjQjZDMkQyIi8+CjxwYXRoIGQ9Ik0wLjQ3NDQ0MiA1LjVDMC4wNTA2MjgyIDUuNSAtMC4xNjEyNzkgNS45Mzc1IDAuMTQ0ODA5IDYuMjA4MzNMMy4xNTg1OSA4Ljg3NUMzLjM0Njk2IDkuMDQxNjcgMy42NTMwNCA5LjA0MTY3IDMuODQxNDEgOC44NzVMNi44NTUxOSA2LjIwODMzQzcuMTYxMjggNS45Mzc1IDYuOTQ5MzcgNS41IDYuNTI1NTYgNS41TDAuNDc0NDQyIDUuNVoiIGZpbGw9IiNCNkMyRDIiLz4KPC9zdmc+Cg==");
  margin-left: .25rem;
}

[data-list] .sort[data-sort].asc::after {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI0IiB2aWV3Qm94PSIwIDAgNyA0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMC40NzQ0NDIgMC41MDAwMDFDMC4wNTA2MjgyIDAuNTAwMDAxIC0wLjE2MTI3OSAwLjkzNzUgMC4xNDQ4MDkgMS4yMDgzM0wzLjE1ODU5IDMuODc1QzMuMzQ2OTYgNC4wNDE2NyAzLjY1MzA0IDQuMDQxNjcgMy44NDE0MSAzLjg3NUw2Ljg1NTE5IDEuMjA4MzNDNy4xNjEyOCAwLjkzNzUwMSA2Ljk0OTM3IDAuNTAwMDAxIDYuNTI1NTYgMC41MDAwMDFMMC40NzQ0NDIgMC41MDAwMDFaIiBmaWxsPSIjQjZDMkQyIi8+Cjwvc3ZnPgo=") !important;
  margin-top: -1px !important;
}

[data-list] .sort[data-sort].desc::after {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI0IiB2aWV3Qm94PSIwIDAgNyA0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNi41MjU1NiAzLjVDNi45NDkzNyAzLjUgNy4xNjEyOCAzLjA2MjUgNi44NTUxOSAyLjc5MTY3TDMuODQxNCAwLjEyNUMzLjY1MzA0IC0wLjA0MTY2NjcgMy4zNDY5NiAtMC4wNDE2NjY3IDMuMTU4NTkgMC4xMjVMMC4xNDQ4MDkgMi43OTE2N0MtMC4xNjEyNzkgMy4wNjI1IDAuMDUwNjI3OSAzLjUgMC40NzQ0NDEgMy41SDYuNTI1NTZaIiBmaWxsPSIjQjZDMkQyIi8+Cjwvc3ZnPgo=") !important;
  margin-top: -1px !important;
}

.theme-slider .swiper-nav {
  margin-top: 0;
  cursor: pointer;
}

.theme-slider .swiper-nav [class*=swiper-] {
  opacity: 0;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  font-size: modular-scale(2);
  transition: opacity 0.4s ease-in-out;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  text-align: center;
  color: #5e6e82;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.8) !important;
  box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
  z-index: 99;
}

.theme-slider .swiper-nav [class*=swiper-]:hover, .theme-slider .swiper-nav [class*=swiper-]:focus {
  background-color: #fff;
  color: #5e6e82;
  opacity: 1 !important;
}

.theme-slider .swiper-nav .swiper-button-prev {
  left: 0.5rem;
}

.theme-slider .swiper-nav .swiper-button-next {
  right: 0.5rem;
}

.theme-slider:hover .swiper-nav [class*=swiper-], .theme-slider:focus .swiper-nav [class*=swiper-] {
  opacity: 0.5;
}

.theme-slider .swiper-button-prev:after,
.theme-slider .swiper-button-next:after {
  font-family: swiper-icons;
  font-size: 1rem;
  font-weight: 800;
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: initial;
  line-height: 1;
}

.theme-slider.swiper-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.theme-slider.swiper-wrapper .swiper-slide {
  display: flex;
  height: auto !important;
}

.star-rating .star-value {
  background: url("../img/icons/star_on.svg") !important;
}

:root {
  --fc-button-bg-color: #344050;
  --fc-button-border-color: #344050;
  --fc-button-hover-bg-color: #0b1727;
  --fc-button-hover-border-color: #0b1727;
  --fc-button-active-bg-color: #0b1727;
  --fc-button-active-border-color: #0b1727;
}

#appCalendar {
  min-height: calc(100vh - 4.3125rem);
}

.fc .fc-button .fc-icon {
  line-height: 1rem;
  font-size: 1.2em;
}

.fc .fc-scrollgrid {
  border-color: #edf2f9;
}

.fc.fc-theme-standard a:not([href]) {
  color: inherit;
}

.fc.fc-theme-standard .fc-list,
.fc.fc-theme-standard td, .fc.fc-theme-standard th {
  border-color: #edf2f9;
}

.fc .fc-col-header {
  background-color: #f9fafd;
}

.fc .fc-col-header th {
  border-bottom-width: 1px;
}

.fc .fc-col-header-cell-cushion {
  text-decoration: none !important;
}

.fc .fc-daygrid-day-frame {
  border: 2px solid transparent;
  padding: 2px !important;
  transition: all 0.2s ease-in-out;
}

.fc .fc-daygrid-day-frame:active {
  background-color: rgba(44, 123, 229, 0.1) !important;
}

.fc .fc-daygrid-day-top {
  justify-content: center;
  margin-bottom: 0.25rem;
}

.fc .fc-daygrid-day-number {
  width: 30px;
  height: 30px;
  background-color: #f9fafd;
  text-align: center;
  text-decoration: none !important;
  border-radius: 50%;
  line-height: 30px;
  padding: 0 !important;
  font-size: 0.83333rem;
  transition: all 0.2s ease-in-out;
}

.fc .fc-daygrid-day-number:hover, .fc .fc-daygrid-day-number:focus {
  background-color: #edf2f9;
}

.fc .fc-daygrid-event {
  border-radius: 0.25rem !important;
  margin-top: 0;
  margin-bottom: 0.25rem !important;
  padding: 0.25rem 0.5rem !important;
  border: 0 !important;
  font-size: 0.69444rem;
}

.fc .fc-h-event {
  background-color: #e6effc;
}

.fc .fc-h-event .fc-event-main {
  color: #1862c6;
}

.fc .fc-h-event .fc-event-time,
.fc .fc-h-event .fc-event-title {
  font-weight: 600 !important;
}

.fc .fc-event-title {
  font-weight: normal !important;
}

.fc .fc-daygrid-event-dot {
  border-color: #d8e2ef !important;
}

.fc .fc-day-today:not(.fc-popover) {
  background-color: transparent !important;
}

.fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-frame {
  border: 2px solid rgba(44, 123, 229, 0.5);
}

.fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number {
  background-color: #2c7be5 !important;
  color: #fff;
}

.fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number:hover, .fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number:focus {
  background-color: #1862c6 !important;
}

.fc.fc-direction-rtl .fc-daygrid-event.fc-event-start, .fc.fc-direction-rtl .fc-daygrid-event.fc-event-end, .fc.fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc.fc-direction-ltr .fc-daygrid-event.fc-event-end {
  margin-left: 0;
  margin-right: 0;
}

.fc .fc-popover {
  border-color: #d8e2ef;
  box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
}

.fc .fc-popover .fc-popover-title {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.fc .fc-popover .fc-daygrid-event {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 2px !important;
}

.fc .fc-popover-header {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  font-weight: 600;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.fc .fc-daygrid-more-link {
  display: block;
  text-align: center;
  color: #9da9bb !important;
  font-size: 0.69444rem;
}

.fc .fc-daygrid-more-link:hover, .fc .fc-daygrid-more-link:focus {
  text-decoration: none;
  color: #748194 !important;
}

.fc .fc-daygrid-dot-event {
  color: #9da9bb !important;
}

.fc .fc-daygrid-dot-event:hover, .fc .fc-daygrid-dot-event:focus {
  background-color: #edf2f9 !important;
}

.fc .fc-day:not(.fc-popover) .fc-daygrid-dot-event {
  display: flex;
  justify-content: center;
}

.fc .fc-day:not(.fc-popover) .fc-daygrid-dot-event .fc-event-time,
.fc .fc-day:not(.fc-popover) .fc-daygrid-dot-event .fc-event-title {
  display: none;
}

.fc .fc-list-event:hover td {
  background-color: unset;
}

.fc .fc-dayGridMonth-view .fc-event-time {
  display: none;
}

.fc .fc-timeGridDay-view .fc-scrollgrid-sync-inner {
  text-align: left;
}

.fc .fc-timeGridDay-view .fc-daygrid-day-events,
.fc .fc-timeGridWeek-view .fc-daygrid-day-events {
  margin-bottom: 0;
}

.fc .fc-timeGridDay-view .fc-v-event .fc-event-main,
.fc .fc-timeGridWeek-view .fc-v-event .fc-event-main {
  padding-left: 1rem;
  color: #9da9bb;
}

.fc .fc-timeGridDay-view .fc-v-event .fc-event-main:after,
.fc .fc-timeGridWeek-view .fc-v-event .fc-event-main:after {
  content: '';
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #b6c1d2;
  top: 5px;
  left: 0;
}

.fc .fc-timeGridDay-view .fc-timegrid-event,
.fc .fc-timeGridWeek-view .fc-timegrid-event {
  padding: 0.5rem;
  background-color: transparent;
  border: 0;
  border-radius: 0.375rem;
}

.fc .fc-timeGridDay-view .fc-timegrid-event:hover, .fc .fc-timeGridDay-view .fc-timegrid-event:focus,
.fc .fc-timeGridWeek-view .fc-timegrid-event:hover,
.fc .fc-timeGridWeek-view .fc-timegrid-event:focus {
  background-color: #edf2f9;
}

.fc .fc-timeGridDay-view .fc-timegrid-slot,
.fc .fc-timeGridWeek-view .fc-timegrid-slot {
  height: 2rem;
}

.fc .fc-timeGridDay-view .fc-timegrid-slot-lane,
.fc .fc-timeGridWeek-view .fc-timegrid-slot-lane {
  cursor: pointer;
}

.fc .fc-timeGridDay-view .fc-timegrid-slot-lane:active,
.fc .fc-timeGridWeek-view .fc-timegrid-slot-lane:active {
  background-color: #f9fafd;
}

.fc .fc-timeGridDay-view .fc-timegrid-col,
.fc .fc-timeGridWeek-view .fc-timegrid-col {
  padding: 6px !important;
}

.fc .fc-list .fc-list-day-cushion {
  padding: 0.5rem 1.25rem;
  background-color: #fff;
}

.fc .fc-list .fc-list-day:not(:first-child) .fc-list-day-cushion {
  margin-top: 1.8rem;
}

.fc .fc-list .fc-list-event-time {
  padding-left: 1.25rem;
}

.fc .fc-list .fc-list-event-title {
  padding-right: 1.25rem;
}

.fc .fc-list-empty {
  background-color: #f9fafd;
}

.fc .fc-list-event-dot {
  border-color: #d8e2ef;
}

.fc-timegrid .event-bg-soft-primary {
  border: 0 !important;
}

.fc-timegrid .event-bg-soft-primary .fc-event-main:after {
  background-color: #2c7be5 !important;
}

.bg-soft-primary .fc-event-main, .fc-timegrid .event-bg-soft-primary .fc-event-main {
  color: #1862c6 !important;
}

.bg-soft-primary .fc-event-main:after, .fc-timegrid .event-bg-soft-primary .fc-event-main:after {
  background-color: #1862c6 !important;
}

.bg-soft-primary .fc-list-event-time, .fc-timegrid .event-bg-soft-primary .fc-list-event-time,
.bg-soft-primary .fc-list-event-title,
.fc-timegrid .event-bg-soft-primary .fc-list-event-title {
  color: #1862c6 !important;
  font-weight: 600 !important;
}

.bg-soft-primary .fc-list-event-dot, .fc-timegrid .event-bg-soft-primary .fc-list-event-dot {
  border-color: #1862c6;
}

.fc-timegrid .event-bg-soft-secondary {
  border: 0 !important;
}

.fc-timegrid .event-bg-soft-secondary .fc-event-main:after {
  background-color: #748194 !important;
}

.bg-soft-secondary .fc-event-main, .fc-timegrid .event-bg-soft-secondary .fc-event-main {
  color: #5d6878 !important;
}

.bg-soft-secondary .fc-event-main:after, .fc-timegrid .event-bg-soft-secondary .fc-event-main:after {
  background-color: #5d6878 !important;
}

.bg-soft-secondary .fc-list-event-time, .fc-timegrid .event-bg-soft-secondary .fc-list-event-time,
.bg-soft-secondary .fc-list-event-title,
.fc-timegrid .event-bg-soft-secondary .fc-list-event-title {
  color: #5d6878 !important;
  font-weight: 600 !important;
}

.bg-soft-secondary .fc-list-event-dot, .fc-timegrid .event-bg-soft-secondary .fc-list-event-dot {
  border-color: #5d6878;
}

.fc-timegrid .event-bg-soft-success {
  border: 0 !important;
}

.fc-timegrid .event-bg-soft-success .fc-event-main:after {
  background-color: #00d27a !important;
}

.bg-soft-success .fc-event-main, .fc-timegrid .event-bg-soft-success .fc-event-main {
  color: #009f5c !important;
}

.bg-soft-success .fc-event-main:after, .fc-timegrid .event-bg-soft-success .fc-event-main:after {
  background-color: #009f5c !important;
}

.bg-soft-success .fc-list-event-time, .fc-timegrid .event-bg-soft-success .fc-list-event-time,
.bg-soft-success .fc-list-event-title,
.fc-timegrid .event-bg-soft-success .fc-list-event-title {
  color: #009f5c !important;
  font-weight: 600 !important;
}

.bg-soft-success .fc-list-event-dot, .fc-timegrid .event-bg-soft-success .fc-list-event-dot {
  border-color: #009f5c;
}

.fc-timegrid .event-bg-soft-info {
  border: 0 !important;
}

.fc-timegrid .event-bg-soft-info .fc-event-main:after {
  background-color: #27bcfd !important;
}

.bg-soft-info .fc-event-main, .fc-timegrid .event-bg-soft-info .fc-event-main {
  color: #02a7ef !important;
}

.bg-soft-info .fc-event-main:after, .fc-timegrid .event-bg-soft-info .fc-event-main:after {
  background-color: #02a7ef !important;
}

.bg-soft-info .fc-list-event-time, .fc-timegrid .event-bg-soft-info .fc-list-event-time,
.bg-soft-info .fc-list-event-title,
.fc-timegrid .event-bg-soft-info .fc-list-event-title {
  color: #02a7ef !important;
  font-weight: 600 !important;
}

.bg-soft-info .fc-list-event-dot, .fc-timegrid .event-bg-soft-info .fc-list-event-dot {
  border-color: #02a7ef;
}

.fc-timegrid .event-bg-soft-warning {
  border: 0 !important;
}

.fc-timegrid .event-bg-soft-warning .fc-event-main:after {
  background-color: #f5803e !important;
}

.bg-soft-warning .fc-event-main, .fc-timegrid .event-bg-soft-warning .fc-event-main {
  color: #f2600e !important;
}

.bg-soft-warning .fc-event-main:after, .fc-timegrid .event-bg-soft-warning .fc-event-main:after {
  background-color: #f2600e !important;
}

.bg-soft-warning .fc-list-event-time, .fc-timegrid .event-bg-soft-warning .fc-list-event-time,
.bg-soft-warning .fc-list-event-title,
.fc-timegrid .event-bg-soft-warning .fc-list-event-title {
  color: #f2600e !important;
  font-weight: 600 !important;
}

.bg-soft-warning .fc-list-event-dot, .fc-timegrid .event-bg-soft-warning .fc-list-event-dot {
  border-color: #f2600e;
}

.fc-timegrid .event-bg-soft-danger {
  border: 0 !important;
}

.fc-timegrid .event-bg-soft-danger .fc-event-main:after {
  background-color: #e63757 !important;
}

.bg-soft-danger .fc-event-main, .fc-timegrid .event-bg-soft-danger .fc-event-main {
  color: #d01a3b !important;
}

.bg-soft-danger .fc-event-main:after, .fc-timegrid .event-bg-soft-danger .fc-event-main:after {
  background-color: #d01a3b !important;
}

.bg-soft-danger .fc-list-event-time, .fc-timegrid .event-bg-soft-danger .fc-list-event-time,
.bg-soft-danger .fc-list-event-title,
.fc-timegrid .event-bg-soft-danger .fc-list-event-title {
  color: #d01a3b !important;
  font-weight: 600 !important;
}

.bg-soft-danger .fc-list-event-dot, .fc-timegrid .event-bg-soft-danger .fc-list-event-dot {
  border-color: #d01a3b;
}

.fc-timegrid .event-bg-soft-light {
  border: 0 !important;
}

.fc-timegrid .event-bg-soft-light .fc-event-main:after {
  background-color: #f9fafd !important;
}

.bg-soft-light .fc-event-main, .fc-timegrid .event-bg-soft-light .fc-event-main {
  color: #d3daf0 !important;
}

.bg-soft-light .fc-event-main:after, .fc-timegrid .event-bg-soft-light .fc-event-main:after {
  background-color: #d3daf0 !important;
}

.bg-soft-light .fc-list-event-time, .fc-timegrid .event-bg-soft-light .fc-list-event-time,
.bg-soft-light .fc-list-event-title,
.fc-timegrid .event-bg-soft-light .fc-list-event-title {
  color: #d3daf0 !important;
  font-weight: 600 !important;
}

.bg-soft-light .fc-list-event-dot, .fc-timegrid .event-bg-soft-light .fc-list-event-dot {
  border-color: #d3daf0;
}

.fc-timegrid .event-bg-soft-dark {
  border: 0 !important;
}

.fc-timegrid .event-bg-soft-dark .fc-event-main:after {
  background-color: #0b1727 !important;
}

.bg-soft-dark .fc-event-main, .fc-timegrid .event-bg-soft-dark .fc-event-main {
  color: black !important;
}

.bg-soft-dark .fc-event-main:after, .fc-timegrid .event-bg-soft-dark .fc-event-main:after {
  background-color: black !important;
}

.bg-soft-dark .fc-list-event-time, .fc-timegrid .event-bg-soft-dark .fc-list-event-time,
.bg-soft-dark .fc-list-event-title,
.fc-timegrid .event-bg-soft-dark .fc-list-event-title {
  color: black !important;
  font-weight: 600 !important;
}

.bg-soft-dark .fc-list-event-dot, .fc-timegrid .event-bg-soft-dark .fc-list-event-dot {
  border-color: black;
}

[data-fc-view]:not(.active) .icon-check {
  opacity: 0;
}

.timeline li {
  position: relative;
  padding-left: 1.5rem;
}

.timeline li:after {
  position: absolute;
  content: '';
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #edf2f9;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.timeline li:not(:last-child):before {
  position: absolute;
  content: '';
  height: 100%;
  width: 1px;
  background-color: #edf2f9;
  top: 50%;
  left: 5px;
}

.windows.chrome .fc-scroller {
  overflow: hidden auto !important;
  overflow: auto;
}

.windows.chrome .fc-scroller::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
  background-color: transparent;
}

.windows.chrome .fc-scroller::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(116, 129, 148, 0.3);
}

.windows.firefox .fc-scroller {
  overflow: hidden auto !important;
  scrollbar-color: rgba(116, 129, 148, 0.3) transparent;
  scrollbar-width: thin;
}

@media (min-width: 768px) {
  .fc .fc-daygrid-day-frame {
    padding: 6px !important;
  }
  .fc .fc-day:not(.fc-popover) .fc-daygrid-dot-event .fc-event-time,
  .fc .fc-day:not(.fc-popover) .fc-daygrid-dot-event .fc-event-title {
    display: block;
  }
  .fc .fc-daygrid-more-link {
    margin-left: 10px;
    text-align: left;
    font-size: 0.83333rem;
  }
  .fc .fc-daygrid-event {
    font-size: 0.83333rem;
  }
}

.ie .fc-daygrid-event {
  overflow: hidden;
}

.safari .fc-dayGridMonth-view .fc-daygrid-day {
  position: relative;
}

.safari .fc-dayGridMonth-view .fc-daygrid-day .fc-daygrid-day-frame {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

/*-----------------------------------------------
|   Lottie
-----------------------------------------------*/
.lottie {
  width: 5rem;
  height: 5rem;
}

.ie .wizard-lottie {
  height: 37.5rem;
}

/*-----------------------------------------------
|   Table
-----------------------------------------------*/
.table tfoot > tr > th:first-child,
.table thead > tr > th:first-child,
.table tr th:first-child,
.table tr td:first-child {
  padding-left: 1.25rem;
}

.table tfoot > tr > th:last-child,
.table thead > tr > th:last-child,
.table tr th:last-child,
.table tr td:last-child {
  padding-right: 1.25rem;
}

.white-space-nowrap {
  width: 1px;
  white-space: nowrap;
}

th {
  font-weight: 600;
}

td .dropdown-toggle:after {
  display: none;
}

.table-dashboard {
  overflow: hidden;
}

.table-dashboard th {
  border-bottom-width: 1px !important;
}

/*-----------------------------------------------
|   Headers
-----------------------------------------------*/
h5, .h5, .h5 {
  margin-bottom: 0.25rem;
}

/*-----------------------------------------------
|   Drop cap
-----------------------------------------------*/
.dropcap::first-letter {
  font-size: 3em;
  float: left;
  line-height: .92;
  margin-right: 0.375rem;
}

/*-----------------------------------------------
|   Letter Spacing
-----------------------------------------------*/
.ls {
  letter-spacing: 0.04em;
}

.ls-2 {
  letter-spacing: 0.25em;
}

/*-----------------------------------------------
|   List
-----------------------------------------------*/
.bullet-inside {
  list-style-position: inside;
}

.style-check li {
  position: relative;
  list-style-type: none;
  padding-left: .25rem;
}

.style-check li:before {
  content: url(data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZGF0YS1mYS1wcm9jZXNzZWQ9IiIgZGF0YS1wcmVmaXg9ImZhbCIgZGF0YS1pY29uPSJjaGVjayIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtY2hlY2sgZmEtdy0xNCIgc3R5bGU9ImZvbnQtc2l6ZTogNDhweDsiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTQxMy41MDUgOTEuOTUxTDEzMy40OSAzNzEuOTY2bC05OC45OTUtOTguOTk1Yy00LjY4Ni00LjY4Ni0xMi4yODQtNC42ODYtMTYuOTcxIDBMNi4yMTEgMjg0LjI4NGMtNC42ODYgNC42ODYtNC42ODYgMTIuMjg0IDAgMTYuOTcxbDExOC43OTQgMTE4Ljc5NGM0LjY4NiA0LjY4NiAxMi4yODQgNC42ODYgMTYuOTcxIDBsMjk5LjgxMy0yOTkuODEzYzQuNjg2LTQuNjg2IDQuNjg2LTEyLjI4NCAwLTE2Ljk3MWwtMTEuMzE0LTExLjMxNGMtNC42ODYtNC42ODYtMTIuMjg0LTQuNjg2LTE2Ljk3IDB6IiBjbGFzcz0iIj48L3BhdGg+PC9zdmc+);
  padding-right: 0.1875rem;
  position: absolute;
  top: 0.0625rem;
  left: -1rem;
  background-repeat: no-repeat;
  width: 1rem;
}

/*-----------------------------------------------
|   Blockquote
-----------------------------------------------*/
.blockquote-content {
  font-style: italic;
  position: relative;
}

.blockquote-content:before {
  position: absolute;
  left: -3rem;
  top: -0.5rem;
  line-height: 1;
  content: "“";
  display: inline-block;
  color: #d8e2ef;
  font-size: 2.98598rem;
}

.blockquote-footer::before {
  transform: translateY(0.125rem);
  font-weight: 400;
  display: inline-block;
}

/*-----------------------------------------------
|   Cursor
-----------------------------------------------*/
.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

/* -------------------------------------------------------------------------- */
/*                                    Text                                    */
/* -------------------------------------------------------------------------- */
.text-smallcaps {
  font-variant: small-caps;
}

.text-superscript {
  vertical-align: super;
}

.text-word-break {
  word-break: break-word;
}

/*-----------------------------------------------
|   Font family
-----------------------------------------------*/
.font-sans-serif {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.font-base {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

/*-----------------------------------------------
|   Error Page
-----------------------------------------------*/
.fs-error {
  font-size: 7rem;
}

@media (min-width: 576px) {
  .fs-error {
    font-size: 10rem;
  }
}

/*-----------------------------------------------
|   Text alignment
-----------------------------------------------*/
.text-justify {
  text-align: justify !important;
}

/*-----------------------------------------------
|   Backgrounds
-----------------------------------------------*/
.bg-holder {
  position: absolute;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  will-change: transform, opacity, filter;
  backface-visibility: hidden;
  background-repeat: no-repeat;
  z-index: 0;
}

.bg-holder.bg-right {
  left: auto;
  right: 0;
}

.bg-holder.overlay:before {
  position: absolute;
  content: "";
  background: rgba(0, 0, 0, 0.25);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bg-holder.overlay-0:before {
  background: rgba(0, 0, 0, 0.7);
}

.bg-holder.overlay-1:before {
  background: rgba(0, 0, 0, 0.55);
}

.bg-holder.overlay-2:before {
  background: rgba(0, 0, 0, 0.4);
}

.bg-holder .bg-video {
  position: absolute;
  display: block;
  z-index: -1;
  top: 0;
  left: 0;
  object-fit: cover;
  height: 100%;
  min-width: 100%;
}

.bg-holder .bg-youtube {
  position: absolute !important;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-glass {
  background-color: rgba(255, 255, 255, 0.9);
}

.bg-card {
  background-size: contain;
  background-position: right;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

[dir="rtl"] .bg-card {
  background-position: left;
  transform: scaleX(-1);
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

/*-----------------------------------------------
|   Background gradient
-----------------------------------------------*/
.bg-line-chart-gradient {
  background-image: linear-gradient(-45deg, #014ba7, #0183d0);
  background-position: center;
}

.bg-card-gradient {
  background-image: linear-gradient(-45deg, #1970e2, #4695ff);
  background-position: center;
}

.bg-auth-circle-shape,
.bg-auth-circle-shape-2 {
  display: none;
}

@media (min-width: 992px) {
  .bg-auth-circle-shape,
  .bg-auth-circle-shape-2 {
    display: block;
  }
}

.bg-auth-circle-shape {
  position: absolute;
  right: -8.75rem;
  top: -5.125rem;
}

.bg-auth-circle-shape-2 {
  position: absolute;
  left: -6.25rem;
  bottom: -2.4375rem;
}

.bg-auth-card-shape {
  background-position: 0 100%;
}

@media (min-width: 768px) {
  .bg-auth-card-shape {
    background-position: 0 133%;
  }
}

.bg-circle-shape {
  position: relative;
  overflow: hidden;
  background-color: #4695ff;
}

.bg-circle-shape:after, .bg-circle-shape:before {
  position: absolute;
  content: "";
  border-radius: 50%;
}

.bg-circle-shape:after {
  height: 15.625rem;
  width: 115%;
  background-image: linear-gradient(-45deg, #1970e2, #4695ff);
  left: 32%;
  top: -188%;
}

.bg-circle-shape:before {
  height: 332%;
  width: 45%;
  background-image: linear-gradient(-45deg, #1970e2, #4695ff);
  left: -9%;
  top: 0.5625rem;
}

.modal-shape-header {
  position: relative;
  overflow: hidden;
  background-color: #4494ff;
}

.modal-shape-header:after, .modal-shape-header:before {
  position: absolute;
  content: "";
  border-radius: 50%;
}

.modal-shape-header:after {
  height: 28.9375rem;
  width: 155%;
  background-image: linear-gradient(-45deg, #4c98ff, #0051bb);
  right: 23%;
  top: -357%;
}

.modal-shape-header:before {
  height: 289%;
  width: 45%;
  background-image: linear-gradient(-45deg, #1970e2, #4695ff);
  right: -10%;
  top: 2.5rem;
}

/* -------------------------------------------------------------------------- */
/*                                   Borders                                  */
/* -------------------------------------------------------------------------- */
.overflow-hidden[class*='rounded'] {
  mask-image: radial-gradient(#fff, #000);
}

.border-top-2 {
  border-top-width: 2px !important;
}

.border-right-2 {
  border-right-width: 2px !important;
}

.border-bottom-2 {
  border-bottom-width: 2px !important;
}

.border-left-2 {
  border-left-width: 2px !important;
}

/* -------------------------------------------------------------------------- */
/*                                  Position                                  */
/* -------------------------------------------------------------------------- */
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  position: relative;
}

.all-0, .ripple {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* -------------------------------------------------------------------------- */
/*                                    Flex                                    */
/* -------------------------------------------------------------------------- */
.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-between-center {
  justify-content: space-between;
  align-items: center;
}

.flex-end-center {
  justify-content: flex-end;
  align-items: center;
}

.flex-1 {
  flex: 1;
}

.link-black {
  color: #000;
}

.link-black:hover, .link-black:focus {
  color: black;
}

.link-100 {
  color: #f9fafd;
}

.link-100:hover, .link-100:focus {
  color: #99aadd;
}

.link-200 {
  color: #edf2f9;
}

.link-200:hover, .link-200:focus {
  color: #8dadd9;
}

.link-300 {
  color: #d8e2ef;
}

.link-300:hover, .link-300:focus {
  color: #7e9fca;
}

.link-400 {
  color: #b6c1d2;
}

.link-400:hover, .link-400:focus {
  color: #677ea1;
}

.link-500 {
  color: #9da9bb;
}

.link-500:hover, .link-500:focus {
  color: #596880;
}

.link-600 {
  color: #748194;
}

.link-600:hover, .link-600:focus {
  color: #3b434d;
}

.link-700 {
  color: #5e6e82;
}

.link-700:hover, .link-700:focus {
  color: #282f38;
}

.link-800 {
  color: #4d5969;
}

.link-800:hover, .link-800:focus {
  color: #171b1f;
}

.link-900 {
  color: #344050;
}

.link-900:hover, .link-900:focus {
  color: #020203;
}

.link-1000 {
  color: #232e3c;
}

.link-1000:hover, .link-1000:focus {
  color: black;
}

.link-1100 {
  color: #0b1727;
}

.link-1100:hover, .link-1100:focus {
  color: black;
}

.link-white {
  color: #fff;
}

.link-white:hover, .link-white:focus {
  color: #bfbfbf;
}

/*-----------------------------------------------
|   Dropdown
-----------------------------------------------*/
.dropdown-menu {
  font-size: 0.83333rem;
  overflow: hidden;
  border-radius: 0.375rem;
}

.dropdown-indicator {
  position: relative;
}

.dropdown-indicator:after {
  content: "";
  display: block;
  position: absolute;
  right: 5px;
  height: 0.4rem;
  width: 0.4rem;
  border-right: 1px solid #5e6e82;
  border-bottom: 1px solid #5e6e82;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  transition: all 0.2s ease-in-out;
  transform-origin: center;
  transition-property: transform, border-color;
}

.dropdown-indicator[aria-expanded="true"]:after {
  transform: translateY(-50%) rotate(225deg);
}

.dropdown-caret-none::after {
  display: none !important;
}

.dropdown-md {
  min-width: 16.625rem;
}

/* -------------------------------------------------------------------------- */
/*                               Navbar Vertical                              */
/* -------------------------------------------------------------------------- */
.navbar-vertical {
  position: fixed;
  display: inline-block;
  padding: 0;
  z-index: 1019;
  top: 4.3125rem;
  width: 100%;
  max-width: 100vw;
  flex-direction: column;
  margin: 0 -1rem;
  background-image: none;
}

.navbar-vertical + .content .navbar-top .navbar-toggler {
  display: flex;
}

.navbar-vertical .navbar-brand {
  display: none;
}

.navbar-vertical .toggle-icon-wrapper {
  margin-left: -0.75rem;
  padding-left: 0.125rem;
  margin-right: 1.25rem;
}

.navbar-vertical .navbar-collapse {
  overflow: hidden;
}

.navbar-vertical .navbar-collapse .navbar-vertical-content {
  padding: 0 1rem;
  flex-direction: column;
  max-height: calc(100vh - 4.3125rem);
}

.navbar-vertical .navbar-collapse .navbar-vertical-content > * {
  width: 100%;
  overflow: hidden;
}

.navbar-vertical .navbar-vertical-toggle {
  display: none;
}

.navbar-vertical .btn-purchase {
  margin-bottom: 1rem;
}

.navbar-vertical .dropdown-indicator {
  padding-right: 1rem !important;
}

.navbar-vertical .navbar-nav {
  font-size: 0.875rem;
  font-weight: 500;
}

.navbar-vertical .navbar-nav .nav-link {
  word-break: break-word;
  transition: all 0.2s ease-in-out;
}

.navbar-vertical .navbar-nav .nav-link-icon {
  width: 1.5rem;
  min-width: 1.5rem;
  font-size: 1rem;
}

.navbar-vertical .navbar-nav .nav {
  flex-flow: column nowrap;
  font-size: 0.8125rem;
}

.navbar-vertical .navbar-nav .nav .nav-item .nav-link {
  padding: 0.2rem 0.2rem 0.2rem 1.5rem;
}

.navbar-vertical .navbar-nav .nav .nav-item:last-child {
  margin-bottom: 0.35rem;
}

.navbar-vertical .navbar-nav .nav .nav .nav-item .nav-link {
  padding-left: 2.625rem;
}

.navbar-vertical .navbar-nav .nav .nav .nav .nav-item .nav-link {
  padding-left: 4.125rem;
}

.navbar-vertical.navbar-card, .navbar-vertical.navbar-vibrant, .navbar-vertical.navbar-inverted {
  padding-left: 1rem;
  padding-right: 1rem;
}

.navbar-vertical.navbar-card .navbar-collapse, .navbar-vertical.navbar-vibrant .navbar-collapse, .navbar-vertical.navbar-inverted .navbar-collapse {
  border-radius: 0.375rem;
}

.navbar-vertical.navbar-card .btn-purchase, .navbar-vertical.navbar-vibrant .btn-purchase, .navbar-vertical.navbar-inverted .btn-purchase {
  margin-bottom: 1rem;
}

.navbar-vertical.navbar-card .navbar-collapse,
.navbar-vertical.navbar-card .navbar-vertical-content, .navbar-vertical.navbar-vibrant .navbar-collapse,
.navbar-vertical.navbar-vibrant .navbar-vertical-content, .navbar-vertical.navbar-inverted .navbar-collapse,
.navbar-vertical.navbar-inverted .navbar-vertical-content {
  max-height: calc(100vh - 4.3125rem - 1rem) !important;
}

.navbar-vertical .navbar-collapse {
  background-color: rgba(237, 242, 249, 0.96);
}

.navbar-vertical .navbar-nav .nav-item .nav-link {
  color: #5e6e82;
}

.navbar-vertical .navbar-nav .nav-item .nav-link.dropdown-indicator:after {
  border-color: #5e6e82;
}

.navbar-vertical .navbar-nav .nav-item .nav-link:hover, .navbar-vertical .navbar-nav .nav-item .nav-link:focus {
  color: #232e3c;
}

.navbar-vertical .navbar-nav .nav-item .nav-link:hover.dropdown-indicator:after, .navbar-vertical .navbar-nav .nav-item .nav-link:focus.dropdown-indicator:after {
  border-color: #232e3c;
}

.navbar-vertical .navbar-nav .nav-item .nav-link.active {
  color: #2c7be5;
}

.navbar-vertical .navbar-nav .nav-item .nav-link.nav-link-disable {
  color: #b6c1d2 !important;
}

.navbar-vertical .navbar-vertical-hr {
  background-color: #d8e2ef;
}

.navbar-vertical.navbar-inverted .navbar-collapse {
  background-color: #232e3c;
}

.navbar-vertical.navbar-inverted .navbar-nav .nav-item .nav-link {
  color: #9da9bb;
}

.navbar-vertical.navbar-inverted .navbar-nav .nav-item .nav-link.dropdown-indicator:after {
  border-color: #9da9bb;
}

.navbar-vertical.navbar-inverted .navbar-nav .nav-item .nav-link:hover, .navbar-vertical.navbar-inverted .navbar-nav .nav-item .nav-link:focus {
  color: #edf2f9;
}

.navbar-vertical.navbar-inverted .navbar-nav .nav-item .nav-link:hover.dropdown-indicator:after, .navbar-vertical.navbar-inverted .navbar-nav .nav-item .nav-link:focus.dropdown-indicator:after {
  border-color: #edf2f9;
}

.navbar-vertical.navbar-inverted .navbar-nav .nav-item .nav-link.active {
  color: #edf2f9;
}

.navbar-vertical.navbar-inverted .navbar-nav .nav-item .nav-link.nav-link-disable {
  color: #5e6e82 !important;
}

.navbar-vertical.navbar-inverted .navbar-vertical-hr {
  background-color: rgba(255, 255, 255, 0.2);
}

.navbar-vertical.navbar-card .navbar-collapse {
  background-color: #fff;
  box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07) !important;
}

.navbar-vertical.navbar-card .navbar-nav .nav-item .nav-link {
  color: #5e6e82;
}

.navbar-vertical.navbar-card .navbar-nav .nav-item .nav-link.dropdown-indicator:after {
  border-color: #5e6e82;
}

.navbar-vertical.navbar-card .navbar-nav .nav-item .nav-link:hover, .navbar-vertical.navbar-card .navbar-nav .nav-item .nav-link:focus {
  color: #344050;
}

.navbar-vertical.navbar-card .navbar-nav .nav-item .nav-link:hover.dropdown-indicator:after, .navbar-vertical.navbar-card .navbar-nav .nav-item .nav-link:focus.dropdown-indicator:after {
  border-color: #344050;
}

.navbar-vertical.navbar-card .navbar-nav .nav-item .nav-link.active {
  color: #2c7be5;
}

.navbar-vertical.navbar-card .navbar-nav .nav-item .nav-link.nav-link-disable {
  color: #b6c1d2 !important;
}

.navbar-vertical.navbar-card .navbar-vertical-hr {
  background-color: #d8e2ef;
}

.navbar-vertical.navbar-vibrant .navbar-collapse {
  background-image: linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2), url(../img/generic/bg-navbar.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  backface-visibility: hidden;
}

.navbar-vertical.navbar-vibrant .navbar-nav .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-vertical.navbar-vibrant .navbar-nav .nav-item .nav-link.dropdown-indicator:after {
  border-color: rgba(255, 255, 255, 0.75);
}

.navbar-vertical.navbar-vibrant .navbar-nav .nav-item .nav-link:hover, .navbar-vertical.navbar-vibrant .navbar-nav .nav-item .nav-link:focus {
  color: #fff;
}

.navbar-vertical.navbar-vibrant .navbar-nav .nav-item .nav-link:hover.dropdown-indicator:after, .navbar-vertical.navbar-vibrant .navbar-nav .nav-item .nav-link:focus.dropdown-indicator:after {
  border-color: #fff;
}

.navbar-vertical.navbar-vibrant .navbar-nav .nav-item .nav-link.active {
  color: #fff;
}

.navbar-vertical.navbar-vibrant .navbar-nav .nav-item .nav-link.nav-link-disable {
  color: rgba(255, 255, 255, 0.45) !important;
}

.navbar-vertical.navbar-vibrant .navbar-vertical-hr {
  background-color: rgba(255, 255, 255, 0.2);
}

.windows.chrome .navbar-vertical .scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(116, 129, 148, 0.3);
}

.windows.firefox .navbar-vertical .scrollbar {
  scrollbar-color: rgba(116, 129, 148, 0.3) transparent;
}

.windows.chrome .navbar-vertical.navbar-inverted .scrollbar::-webkit-scrollbar-thumb {
  background-color: #b6c1d2;
}

.windows.firefox .navbar-vertical.navbar-inverted .scrollbar {
  scrollbar-color: #b6c1d2 transparent;
}

.windows.chrome .navbar-vertical.navbar-card .scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(116, 129, 148, 0.3);
}

.windows.firefox .navbar-vertical.navbar-card .scrollbar {
  scrollbar-color: rgba(116, 129, 148, 0.3) transparent;
}

.windows.chrome .navbar-vertical.navbar-vibrant .scrollbar::-webkit-scrollbar-thumb {
  background-color: #b6c1d2;
}

.windows.firefox .navbar-vertical.navbar-vibrant .scrollbar {
  scrollbar-color: #b6c1d2 transparent;
}

@media (min-width: 576px) {
  .container .navbar-vertical {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container .navbar-vertical {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container .navbar-vertical {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container .navbar-vertical {
    max-width: 1140px;
  }
}

@media (min-width: 1540px) {
  .container .navbar-vertical {
    max-width: 1480px;
  }
}

@media (min-width: 1540px) {
  .navbar-vertical.navbar-expand-xxl {
    max-width: 12.625rem;
    top: 0;
    height: 100vh;
    margin: 0;
  }
  .navbar-vertical.navbar-expand-xxl .nav-link {
    padding: 0.35rem 0;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-brand {
    display: block;
    text-align: center;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-collapse {
    margin-top: -0.3125rem;
    transition: width 0.2s ease;
    transition-property: width, box-shadow;
    display: inline-block !important;
    width: 12.625rem;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-vertical-content {
    width: 12.625rem;
    height: calc(100vh - 4.3125rem);
    padding: 0.5rem 0 0 0;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-vertical-toggle {
    display: flex;
  }
  .navbar-vertical.navbar-expand-xxl.navbar-card, .navbar-vertical.navbar-expand-xxl.navbar-vibrant, .navbar-vertical.navbar-expand-xxl.navbar-inverted {
    padding: 0;
    max-width: 14.5rem !important;
  }
  .navbar-vertical.navbar-expand-xxl.navbar-card .navbar-vertical-content, .navbar-vertical.navbar-expand-xxl.navbar-vibrant .navbar-vertical-content, .navbar-vertical.navbar-expand-xxl.navbar-inverted .navbar-vertical-content {
    padding: 0.5rem 1rem 0 1rem !important;
  }
  .navbar-vertical.navbar-expand-xxl.navbar-card .toggle-icon-wrapper, .navbar-vertical.navbar-expand-xxl.navbar-vibrant .toggle-icon-wrapper, .navbar-vertical.navbar-expand-xxl.navbar-inverted .toggle-icon-wrapper {
    margin-left: 0.25rem;
    margin-right: 1.5rem;
  }
  .navbar-vertical.navbar-expand-xxl.navbar-card .navbar-collapse,
  .navbar-vertical.navbar-expand-xxl.navbar-card .navbar-vertical-content, .navbar-vertical.navbar-expand-xxl.navbar-vibrant .navbar-collapse,
  .navbar-vertical.navbar-expand-xxl.navbar-vibrant .navbar-vertical-content, .navbar-vertical.navbar-expand-xxl.navbar-inverted .navbar-collapse,
  .navbar-vertical.navbar-expand-xxl.navbar-inverted .navbar-vertical-content {
    width: 14.5rem !important;
  }
  .navbar-vertical.navbar-expand-xxl.navbar-card + .content, .navbar-vertical.navbar-expand-xxl.navbar-vibrant + .content, .navbar-vertical.navbar-expand-xxl.navbar-inverted + .content {
    margin-left: 15.5rem;
  }
  .navbar-vertical.navbar-expand-xxl + .content {
    margin-left: 15.625rem;
  }
  .navbar-vertical.navbar-expand-xxl + .content .navbar-top {
    position: sticky;
    top: 0;
  }
  .navbar-vertical.navbar-expand-xxl + .content .navbar-top .navbar-toggler,
  .navbar-vertical.navbar-expand-xxl + .content .navbar-top .navbar-brand {
    display: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl {
    z-index: 1030;
    width: 3.125rem;
    height: 100vh;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .navbar-collapse {
    width: 3.125rem !important;
    margin-left: -1rem;
    overflow: hidden;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .navbar-vertical-content {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .dropdown-indicator:after,
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-link .badge,
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-link-text,
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .settings {
    opacity: 0;
    transition: all 0.5s ease;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .navbar-toggle-icon {
    padding-right: 0.3125rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .navbar-toggle-icon:after {
    width: 75%;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .navbar-toggle-icon:before {
    width: 50%;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl + .content {
    margin-left: 3.125rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl + .content .navbar-top {
    padding-left: 13.5rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl.navbar-card, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl.navbar-vibrant, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl.navbar-inverted {
    width: 4.125rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl.navbar-card .navbar-collapse, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl.navbar-vibrant .navbar-collapse, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl.navbar-inverted .navbar-collapse {
    width: 4.125rem;
    margin-left: 0;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl.navbar-card + .content, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl.navbar-vibrant + .content, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl.navbar-inverted + .content {
    margin-left: 4.125rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl.navbar-card + .content .navbar-top, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl.navbar-vibrant + .content .navbar-top, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl.navbar-inverted + .content .navbar-top {
    padding-left: 12.375rem;
  }
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-xxl .navbar-collapse,
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-xxl .nav.collapse.show,
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-xxl .nav-link .badge,
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-xxl .settings,
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-xxl .nav-link-text {
    display: none;
  }
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-xxl .navbar-vertical-divider {
    max-width: 1.125rem;
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl {
    width: 12.625rem;
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl .navbar-collapse {
    width: 12.625rem !important;
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl .navbar-collapse {
    box-shadow: 0.625rem 0 0.625rem -0.5625rem rgba(0, 0, 0, 0.2);
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl .dropdown-indicator:after,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl .nav-link .badge,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl .nav-link-text,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl .settings {
    opacity: 1;
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl.navbar-card, .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl.navbar-vibrant, .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl.navbar-inverted {
    width: 14.5rem;
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl.navbar-card .navbar-collapse,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl.navbar-card .navbar-vertical-content, .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl.navbar-vibrant .navbar-collapse,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl.navbar-vibrant .navbar-vertical-content, .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl.navbar-inverted .navbar-collapse,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xxl.navbar-inverted .navbar-vertical-content {
    width: 14.5rem !important;
  }
}

@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl {
    max-width: 12.625rem;
    top: 0;
    height: 100vh;
    margin: 0;
  }
  .navbar-vertical.navbar-expand-xl .nav-link {
    padding: 0.35rem 0;
  }
  .navbar-vertical.navbar-expand-xl .navbar-brand {
    display: block;
    text-align: center;
  }
  .navbar-vertical.navbar-expand-xl .navbar-collapse {
    margin-top: -0.3125rem;
    transition: width 0.2s ease;
    transition-property: width, box-shadow;
    display: inline-block !important;
    width: 12.625rem;
  }
  .navbar-vertical.navbar-expand-xl .navbar-vertical-content {
    width: 12.625rem;
    height: calc(100vh - 4.3125rem);
    padding: 0.5rem 0 0 0;
  }
  .navbar-vertical.navbar-expand-xl .navbar-vertical-toggle {
    display: flex;
  }
  .navbar-vertical.navbar-expand-xl.navbar-card, .navbar-vertical.navbar-expand-xl.navbar-vibrant, .navbar-vertical.navbar-expand-xl.navbar-inverted {
    padding: 0;
    max-width: 14.5rem !important;
  }
  .navbar-vertical.navbar-expand-xl.navbar-card .navbar-vertical-content, .navbar-vertical.navbar-expand-xl.navbar-vibrant .navbar-vertical-content, .navbar-vertical.navbar-expand-xl.navbar-inverted .navbar-vertical-content {
    padding: 0.5rem 1rem 0 1rem !important;
  }
  .navbar-vertical.navbar-expand-xl.navbar-card .toggle-icon-wrapper, .navbar-vertical.navbar-expand-xl.navbar-vibrant .toggle-icon-wrapper, .navbar-vertical.navbar-expand-xl.navbar-inverted .toggle-icon-wrapper {
    margin-left: 0.25rem;
    margin-right: 1.5rem;
  }
  .navbar-vertical.navbar-expand-xl.navbar-card .navbar-collapse,
  .navbar-vertical.navbar-expand-xl.navbar-card .navbar-vertical-content, .navbar-vertical.navbar-expand-xl.navbar-vibrant .navbar-collapse,
  .navbar-vertical.navbar-expand-xl.navbar-vibrant .navbar-vertical-content, .navbar-vertical.navbar-expand-xl.navbar-inverted .navbar-collapse,
  .navbar-vertical.navbar-expand-xl.navbar-inverted .navbar-vertical-content {
    width: 14.5rem !important;
  }
  .navbar-vertical.navbar-expand-xl.navbar-card + .content, .navbar-vertical.navbar-expand-xl.navbar-vibrant + .content, .navbar-vertical.navbar-expand-xl.navbar-inverted + .content {
    margin-left: 15.5rem;
  }
  .navbar-vertical.navbar-expand-xl + .content {
    margin-left: 15.625rem;
  }
  .navbar-vertical.navbar-expand-xl + .content .navbar-top {
    position: sticky;
    top: 0;
  }
  .navbar-vertical.navbar-expand-xl + .content .navbar-top .navbar-toggler,
  .navbar-vertical.navbar-expand-xl + .content .navbar-top .navbar-brand {
    display: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl {
    z-index: 1030;
    width: 3.125rem;
    height: 100vh;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .navbar-collapse {
    width: 3.125rem !important;
    margin-left: -1rem;
    overflow: hidden;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .navbar-vertical-content {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .dropdown-indicator:after,
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-link .badge,
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-link-text,
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .settings {
    opacity: 0;
    transition: all 0.5s ease;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .navbar-toggle-icon {
    padding-right: 0.3125rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .navbar-toggle-icon:after {
    width: 75%;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .navbar-toggle-icon:before {
    width: 50%;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl + .content {
    margin-left: 3.125rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl + .content .navbar-top {
    padding-left: 13.5rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl.navbar-card, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl.navbar-vibrant, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl.navbar-inverted {
    width: 4.125rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl.navbar-card .navbar-collapse, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl.navbar-vibrant .navbar-collapse, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl.navbar-inverted .navbar-collapse {
    width: 4.125rem;
    margin-left: 0;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl.navbar-card + .content, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl.navbar-vibrant + .content, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl.navbar-inverted + .content {
    margin-left: 4.125rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl.navbar-card + .content .navbar-top, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl.navbar-vibrant + .content .navbar-top, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl.navbar-inverted + .content .navbar-top {
    padding-left: 12.375rem;
  }
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-xl .navbar-collapse,
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-xl .nav.collapse.show,
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-xl .nav-link .badge,
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-xl .settings,
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-xl .nav-link-text {
    display: none;
  }
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-xl .navbar-vertical-divider {
    max-width: 1.125rem;
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl {
    width: 12.625rem;
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl .navbar-collapse {
    width: 12.625rem !important;
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl .navbar-collapse {
    box-shadow: 0.625rem 0 0.625rem -0.5625rem rgba(0, 0, 0, 0.2);
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl .dropdown-indicator:after,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl .nav-link .badge,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl .nav-link-text,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl .settings {
    opacity: 1;
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl.navbar-card, .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl.navbar-vibrant, .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl.navbar-inverted {
    width: 14.5rem;
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl.navbar-card .navbar-collapse,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl.navbar-card .navbar-vertical-content, .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl.navbar-vibrant .navbar-collapse,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl.navbar-vibrant .navbar-vertical-content, .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl.navbar-inverted .navbar-collapse,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xl.navbar-inverted .navbar-vertical-content {
    width: 14.5rem !important;
  }
}

@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg {
    max-width: 12.625rem;
    top: 0;
    height: 100vh;
    margin: 0;
  }
  .navbar-vertical.navbar-expand-lg .nav-link {
    padding: 0.35rem 0;
  }
  .navbar-vertical.navbar-expand-lg .navbar-brand {
    display: block;
    text-align: center;
  }
  .navbar-vertical.navbar-expand-lg .navbar-collapse {
    margin-top: -0.3125rem;
    transition: width 0.2s ease;
    transition-property: width, box-shadow;
    display: inline-block !important;
    width: 12.625rem;
  }
  .navbar-vertical.navbar-expand-lg .navbar-vertical-content {
    width: 12.625rem;
    height: calc(100vh - 4.3125rem);
    padding: 0.5rem 0 0 0;
  }
  .navbar-vertical.navbar-expand-lg .navbar-vertical-toggle {
    display: flex;
  }
  .navbar-vertical.navbar-expand-lg.navbar-card, .navbar-vertical.navbar-expand-lg.navbar-vibrant, .navbar-vertical.navbar-expand-lg.navbar-inverted {
    padding: 0;
    max-width: 14.5rem !important;
  }
  .navbar-vertical.navbar-expand-lg.navbar-card .navbar-vertical-content, .navbar-vertical.navbar-expand-lg.navbar-vibrant .navbar-vertical-content, .navbar-vertical.navbar-expand-lg.navbar-inverted .navbar-vertical-content {
    padding: 0.5rem 1rem 0 1rem !important;
  }
  .navbar-vertical.navbar-expand-lg.navbar-card .toggle-icon-wrapper, .navbar-vertical.navbar-expand-lg.navbar-vibrant .toggle-icon-wrapper, .navbar-vertical.navbar-expand-lg.navbar-inverted .toggle-icon-wrapper {
    margin-left: 0.25rem;
    margin-right: 1.5rem;
  }
  .navbar-vertical.navbar-expand-lg.navbar-card .navbar-collapse,
  .navbar-vertical.navbar-expand-lg.navbar-card .navbar-vertical-content, .navbar-vertical.navbar-expand-lg.navbar-vibrant .navbar-collapse,
  .navbar-vertical.navbar-expand-lg.navbar-vibrant .navbar-vertical-content, .navbar-vertical.navbar-expand-lg.navbar-inverted .navbar-collapse,
  .navbar-vertical.navbar-expand-lg.navbar-inverted .navbar-vertical-content {
    width: 14.5rem !important;
  }
  .navbar-vertical.navbar-expand-lg.navbar-card + .content, .navbar-vertical.navbar-expand-lg.navbar-vibrant + .content, .navbar-vertical.navbar-expand-lg.navbar-inverted + .content {
    margin-left: 15.5rem;
  }
  .navbar-vertical.navbar-expand-lg + .content {
    margin-left: 15.625rem;
  }
  .navbar-vertical.navbar-expand-lg + .content .navbar-top {
    position: sticky;
    top: 0;
  }
  .navbar-vertical.navbar-expand-lg + .content .navbar-top .navbar-toggler,
  .navbar-vertical.navbar-expand-lg + .content .navbar-top .navbar-brand {
    display: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg {
    z-index: 1030;
    width: 3.125rem;
    height: 100vh;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .navbar-collapse {
    width: 3.125rem !important;
    margin-left: -1rem;
    overflow: hidden;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .navbar-vertical-content {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .dropdown-indicator:after,
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-link .badge,
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-link-text,
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .settings {
    opacity: 0;
    transition: all 0.5s ease;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .navbar-toggle-icon {
    padding-right: 0.3125rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .navbar-toggle-icon:after {
    width: 75%;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .navbar-toggle-icon:before {
    width: 50%;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg + .content {
    margin-left: 3.125rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg + .content .navbar-top {
    padding-left: 13.5rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg.navbar-card, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg.navbar-vibrant, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg.navbar-inverted {
    width: 4.125rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg.navbar-card .navbar-collapse, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg.navbar-vibrant .navbar-collapse, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg.navbar-inverted .navbar-collapse {
    width: 4.125rem;
    margin-left: 0;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg.navbar-card + .content, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg.navbar-vibrant + .content, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg.navbar-inverted + .content {
    margin-left: 4.125rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg.navbar-card + .content .navbar-top, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg.navbar-vibrant + .content .navbar-top, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg.navbar-inverted + .content .navbar-top {
    padding-left: 12.375rem;
  }
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-lg .navbar-collapse,
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-lg .nav.collapse.show,
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-lg .nav-link .badge,
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-lg .settings,
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-lg .nav-link-text {
    display: none;
  }
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-lg .navbar-vertical-divider {
    max-width: 1.125rem;
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg {
    width: 12.625rem;
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg .navbar-collapse {
    width: 12.625rem !important;
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg .navbar-collapse {
    box-shadow: 0.625rem 0 0.625rem -0.5625rem rgba(0, 0, 0, 0.2);
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg .dropdown-indicator:after,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg .nav-link .badge,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg .nav-link-text,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg .settings {
    opacity: 1;
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg.navbar-card, .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg.navbar-vibrant, .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg.navbar-inverted {
    width: 14.5rem;
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg.navbar-card .navbar-collapse,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg.navbar-card .navbar-vertical-content, .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg.navbar-vibrant .navbar-collapse,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg.navbar-vibrant .navbar-vertical-content, .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg.navbar-inverted .navbar-collapse,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg.navbar-inverted .navbar-vertical-content {
    width: 14.5rem !important;
  }
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md {
    max-width: 12.625rem;
    top: 0;
    height: 100vh;
    margin: 0;
  }
  .navbar-vertical.navbar-expand-md .nav-link {
    padding: 0.35rem 0;
  }
  .navbar-vertical.navbar-expand-md .navbar-brand {
    display: block;
    text-align: center;
  }
  .navbar-vertical.navbar-expand-md .navbar-collapse {
    margin-top: -0.3125rem;
    transition: width 0.2s ease;
    transition-property: width, box-shadow;
    display: inline-block !important;
    width: 12.625rem;
  }
  .navbar-vertical.navbar-expand-md .navbar-vertical-content {
    width: 12.625rem;
    height: calc(100vh - 4.3125rem);
    padding: 0.5rem 0 0 0;
  }
  .navbar-vertical.navbar-expand-md .navbar-vertical-toggle {
    display: flex;
  }
  .navbar-vertical.navbar-expand-md.navbar-card, .navbar-vertical.navbar-expand-md.navbar-vibrant, .navbar-vertical.navbar-expand-md.navbar-inverted {
    padding: 0;
    max-width: 14.5rem !important;
  }
  .navbar-vertical.navbar-expand-md.navbar-card .navbar-vertical-content, .navbar-vertical.navbar-expand-md.navbar-vibrant .navbar-vertical-content, .navbar-vertical.navbar-expand-md.navbar-inverted .navbar-vertical-content {
    padding: 0.5rem 1rem 0 1rem !important;
  }
  .navbar-vertical.navbar-expand-md.navbar-card .toggle-icon-wrapper, .navbar-vertical.navbar-expand-md.navbar-vibrant .toggle-icon-wrapper, .navbar-vertical.navbar-expand-md.navbar-inverted .toggle-icon-wrapper {
    margin-left: 0.25rem;
    margin-right: 1.5rem;
  }
  .navbar-vertical.navbar-expand-md.navbar-card .navbar-collapse,
  .navbar-vertical.navbar-expand-md.navbar-card .navbar-vertical-content, .navbar-vertical.navbar-expand-md.navbar-vibrant .navbar-collapse,
  .navbar-vertical.navbar-expand-md.navbar-vibrant .navbar-vertical-content, .navbar-vertical.navbar-expand-md.navbar-inverted .navbar-collapse,
  .navbar-vertical.navbar-expand-md.navbar-inverted .navbar-vertical-content {
    width: 14.5rem !important;
  }
  .navbar-vertical.navbar-expand-md.navbar-card + .content, .navbar-vertical.navbar-expand-md.navbar-vibrant + .content, .navbar-vertical.navbar-expand-md.navbar-inverted + .content {
    margin-left: 15.5rem;
  }
  .navbar-vertical.navbar-expand-md + .content {
    margin-left: 15.625rem;
  }
  .navbar-vertical.navbar-expand-md + .content .navbar-top {
    position: sticky;
    top: 0;
  }
  .navbar-vertical.navbar-expand-md + .content .navbar-top .navbar-toggler,
  .navbar-vertical.navbar-expand-md + .content .navbar-top .navbar-brand {
    display: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md {
    z-index: 1030;
    width: 3.125rem;
    height: 100vh;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .navbar-collapse {
    width: 3.125rem !important;
    margin-left: -1rem;
    overflow: hidden;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .navbar-vertical-content {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .dropdown-indicator:after,
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-link .badge,
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-link-text,
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .settings {
    opacity: 0;
    transition: all 0.5s ease;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .navbar-toggle-icon {
    padding-right: 0.3125rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .navbar-toggle-icon:after {
    width: 75%;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .navbar-toggle-icon:before {
    width: 50%;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md + .content {
    margin-left: 3.125rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md + .content .navbar-top {
    padding-left: 13.5rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md.navbar-card, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md.navbar-vibrant, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md.navbar-inverted {
    width: 4.125rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md.navbar-card .navbar-collapse, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md.navbar-vibrant .navbar-collapse, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md.navbar-inverted .navbar-collapse {
    width: 4.125rem;
    margin-left: 0;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md.navbar-card + .content, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md.navbar-vibrant + .content, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md.navbar-inverted + .content {
    margin-left: 4.125rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md.navbar-card + .content .navbar-top, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md.navbar-vibrant + .content .navbar-top, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md.navbar-inverted + .content .navbar-top {
    padding-left: 12.375rem;
  }
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-md .navbar-collapse,
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-md .nav.collapse.show,
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-md .nav-link .badge,
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-md .settings,
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-md .nav-link-text {
    display: none;
  }
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-md .navbar-vertical-divider {
    max-width: 1.125rem;
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md {
    width: 12.625rem;
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md .navbar-collapse {
    width: 12.625rem !important;
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md .navbar-collapse {
    box-shadow: 0.625rem 0 0.625rem -0.5625rem rgba(0, 0, 0, 0.2);
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md .dropdown-indicator:after,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md .nav-link .badge,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md .nav-link-text,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md .settings {
    opacity: 1;
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md.navbar-card, .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md.navbar-vibrant, .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md.navbar-inverted {
    width: 14.5rem;
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md.navbar-card .navbar-collapse,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md.navbar-card .navbar-vertical-content, .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md.navbar-vibrant .navbar-collapse,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md.navbar-vibrant .navbar-vertical-content, .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md.navbar-inverted .navbar-collapse,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-md.navbar-inverted .navbar-vertical-content {
    width: 14.5rem !important;
  }
}

@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm {
    max-width: 12.625rem;
    top: 0;
    height: 100vh;
    margin: 0;
  }
  .navbar-vertical.navbar-expand-sm .nav-link {
    padding: 0.35rem 0;
  }
  .navbar-vertical.navbar-expand-sm .navbar-brand {
    display: block;
    text-align: center;
  }
  .navbar-vertical.navbar-expand-sm .navbar-collapse {
    margin-top: -0.3125rem;
    transition: width 0.2s ease;
    transition-property: width, box-shadow;
    display: inline-block !important;
    width: 12.625rem;
  }
  .navbar-vertical.navbar-expand-sm .navbar-vertical-content {
    width: 12.625rem;
    height: calc(100vh - 4.3125rem);
    padding: 0.5rem 0 0 0;
  }
  .navbar-vertical.navbar-expand-sm .navbar-vertical-toggle {
    display: flex;
  }
  .navbar-vertical.navbar-expand-sm.navbar-card, .navbar-vertical.navbar-expand-sm.navbar-vibrant, .navbar-vertical.navbar-expand-sm.navbar-inverted {
    padding: 0;
    max-width: 14.5rem !important;
  }
  .navbar-vertical.navbar-expand-sm.navbar-card .navbar-vertical-content, .navbar-vertical.navbar-expand-sm.navbar-vibrant .navbar-vertical-content, .navbar-vertical.navbar-expand-sm.navbar-inverted .navbar-vertical-content {
    padding: 0.5rem 1rem 0 1rem !important;
  }
  .navbar-vertical.navbar-expand-sm.navbar-card .toggle-icon-wrapper, .navbar-vertical.navbar-expand-sm.navbar-vibrant .toggle-icon-wrapper, .navbar-vertical.navbar-expand-sm.navbar-inverted .toggle-icon-wrapper {
    margin-left: 0.25rem;
    margin-right: 1.5rem;
  }
  .navbar-vertical.navbar-expand-sm.navbar-card .navbar-collapse,
  .navbar-vertical.navbar-expand-sm.navbar-card .navbar-vertical-content, .navbar-vertical.navbar-expand-sm.navbar-vibrant .navbar-collapse,
  .navbar-vertical.navbar-expand-sm.navbar-vibrant .navbar-vertical-content, .navbar-vertical.navbar-expand-sm.navbar-inverted .navbar-collapse,
  .navbar-vertical.navbar-expand-sm.navbar-inverted .navbar-vertical-content {
    width: 14.5rem !important;
  }
  .navbar-vertical.navbar-expand-sm.navbar-card + .content, .navbar-vertical.navbar-expand-sm.navbar-vibrant + .content, .navbar-vertical.navbar-expand-sm.navbar-inverted + .content {
    margin-left: 15.5rem;
  }
  .navbar-vertical.navbar-expand-sm + .content {
    margin-left: 15.625rem;
  }
  .navbar-vertical.navbar-expand-sm + .content .navbar-top {
    position: sticky;
    top: 0;
  }
  .navbar-vertical.navbar-expand-sm + .content .navbar-top .navbar-toggler,
  .navbar-vertical.navbar-expand-sm + .content .navbar-top .navbar-brand {
    display: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm {
    z-index: 1030;
    width: 3.125rem;
    height: 100vh;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .navbar-collapse {
    width: 3.125rem !important;
    margin-left: -1rem;
    overflow: hidden;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .navbar-vertical-content {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .dropdown-indicator:after,
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-link .badge,
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-link-text,
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .settings {
    opacity: 0;
    transition: all 0.5s ease;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .navbar-toggle-icon {
    padding-right: 0.3125rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .navbar-toggle-icon:after {
    width: 75%;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .navbar-toggle-icon:before {
    width: 50%;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm + .content {
    margin-left: 3.125rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm + .content .navbar-top {
    padding-left: 13.5rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm.navbar-card, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm.navbar-vibrant, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm.navbar-inverted {
    width: 4.125rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm.navbar-card .navbar-collapse, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm.navbar-vibrant .navbar-collapse, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm.navbar-inverted .navbar-collapse {
    width: 4.125rem;
    margin-left: 0;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm.navbar-card + .content, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm.navbar-vibrant + .content, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm.navbar-inverted + .content {
    margin-left: 4.125rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm.navbar-card + .content .navbar-top, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm.navbar-vibrant + .content .navbar-top, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm.navbar-inverted + .content .navbar-top {
    padding-left: 12.375rem;
  }
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-sm .navbar-collapse,
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-sm .nav.collapse.show,
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-sm .nav-link .badge,
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-sm .settings,
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-sm .nav-link-text {
    display: none;
  }
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-sm .navbar-vertical-divider {
    max-width: 1.125rem;
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm {
    width: 12.625rem;
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm .navbar-collapse {
    width: 12.625rem !important;
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm .navbar-collapse {
    box-shadow: 0.625rem 0 0.625rem -0.5625rem rgba(0, 0, 0, 0.2);
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm .dropdown-indicator:after,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm .nav-link .badge,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm .nav-link-text,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm .settings {
    opacity: 1;
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm.navbar-card, .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm.navbar-vibrant, .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm.navbar-inverted {
    width: 14.5rem;
  }
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm.navbar-card .navbar-collapse,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm.navbar-card .navbar-vertical-content, .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm.navbar-vibrant .navbar-collapse,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm.navbar-vibrant .navbar-vertical-content, .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm.navbar-inverted .navbar-collapse,
  .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-sm.navbar-inverted .navbar-vertical-content {
    width: 14.5rem !important;
  }
}

.navbar-vertical.navbar-expand-xs {
  max-width: 12.625rem;
  top: 0;
  height: 100vh;
  margin: 0;
}

.navbar-vertical.navbar-expand-xs .nav-link {
  padding: 0.35rem 0;
}

.navbar-vertical.navbar-expand-xs .navbar-brand {
  display: block;
  text-align: center;
}

.navbar-vertical.navbar-expand-xs .navbar-collapse {
  margin-top: -0.3125rem;
  transition: width 0.2s ease;
  transition-property: width, box-shadow;
  display: inline-block !important;
  width: 12.625rem;
}

.navbar-vertical.navbar-expand-xs .navbar-vertical-content {
  width: 12.625rem;
  height: calc(100vh - 4.3125rem);
  padding: 0.5rem 0 0 0;
}

.navbar-vertical.navbar-expand-xs .navbar-vertical-toggle {
  display: flex;
}

.navbar-vertical.navbar-expand-xs.navbar-card, .navbar-vertical.navbar-expand-xs.navbar-vibrant, .navbar-vertical.navbar-expand-xs.navbar-inverted {
  padding: 0;
  max-width: 14.5rem !important;
}

.navbar-vertical.navbar-expand-xs.navbar-card .navbar-vertical-content, .navbar-vertical.navbar-expand-xs.navbar-vibrant .navbar-vertical-content, .navbar-vertical.navbar-expand-xs.navbar-inverted .navbar-vertical-content {
  padding: 0.5rem 1rem 0 1rem !important;
}

.navbar-vertical.navbar-expand-xs.navbar-card .toggle-icon-wrapper, .navbar-vertical.navbar-expand-xs.navbar-vibrant .toggle-icon-wrapper, .navbar-vertical.navbar-expand-xs.navbar-inverted .toggle-icon-wrapper {
  margin-left: 0.25rem;
  margin-right: 1.5rem;
}

.navbar-vertical.navbar-expand-xs.navbar-card .navbar-collapse,
.navbar-vertical.navbar-expand-xs.navbar-card .navbar-vertical-content, .navbar-vertical.navbar-expand-xs.navbar-vibrant .navbar-collapse,
.navbar-vertical.navbar-expand-xs.navbar-vibrant .navbar-vertical-content, .navbar-vertical.navbar-expand-xs.navbar-inverted .navbar-collapse,
.navbar-vertical.navbar-expand-xs.navbar-inverted .navbar-vertical-content {
  width: 14.5rem !important;
}

.navbar-vertical.navbar-expand-xs.navbar-card + .content, .navbar-vertical.navbar-expand-xs.navbar-vibrant + .content, .navbar-vertical.navbar-expand-xs.navbar-inverted + .content {
  margin-left: 15.5rem;
}

.navbar-vertical.navbar-expand-xs + .content {
  margin-left: 15.625rem;
}

.navbar-vertical.navbar-expand-xs + .content .navbar-top {
  position: sticky;
  top: 0;
}

.navbar-vertical.navbar-expand-xs + .content .navbar-top .navbar-toggler,
.navbar-vertical.navbar-expand-xs + .content .navbar-top .navbar-brand {
  display: none;
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs {
  z-index: 1030;
  width: 3.125rem;
  height: 100vh;
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .navbar-collapse {
  width: 3.125rem !important;
  margin-left: -1rem;
  overflow: hidden;
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .navbar-vertical-content {
  padding-right: 1rem;
  padding-left: 1rem;
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .dropdown-indicator:after,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-link .badge,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-link-text,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .settings {
  opacity: 0;
  transition: all 0.5s ease;
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .navbar-toggle-icon {
  padding-right: 0.3125rem;
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .navbar-toggle-icon:after {
  width: 75%;
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .navbar-toggle-icon:before {
  width: 50%;
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs + .content {
  margin-left: 3.125rem;
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs + .content .navbar-top {
  padding-left: 13.5rem;
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs.navbar-card, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs.navbar-vibrant, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs.navbar-inverted {
  width: 4.125rem;
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs.navbar-card .navbar-collapse, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs.navbar-vibrant .navbar-collapse, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs.navbar-inverted .navbar-collapse {
  width: 4.125rem;
  margin-left: 0;
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs.navbar-card + .content, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs.navbar-vibrant + .content, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs.navbar-inverted + .content {
  margin-left: 4.125rem;
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs.navbar-card + .content .navbar-top, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs.navbar-vibrant + .content .navbar-top, .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs.navbar-inverted + .content .navbar-top {
  padding-left: 12.375rem;
}

.navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-xs .navbar-collapse,
.navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-xs .nav.collapse.show,
.navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-xs .nav-link .badge,
.navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-xs .settings,
.navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-xs .nav-link-text {
  display: none;
}

.navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover) .navbar-vertical.navbar-expand-xs .navbar-vertical-divider {
  max-width: 1.125rem;
}

.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs {
  width: 12.625rem;
}

.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs .navbar-collapse {
  width: 12.625rem !important;
}

.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs .navbar-collapse {
  box-shadow: 0.625rem 0 0.625rem -0.5625rem rgba(0, 0, 0, 0.2);
}

.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs .dropdown-indicator:after,
.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs .nav-link .badge,
.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs .nav-link-text,
.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs .settings {
  opacity: 1;
}

.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs.navbar-card, .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs.navbar-vibrant, .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs.navbar-inverted {
  width: 14.5rem;
}

.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs.navbar-card .navbar-collapse,
.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs.navbar-card .navbar-vertical-content, .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs.navbar-vibrant .navbar-collapse,
.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs.navbar-vibrant .navbar-vertical-content, .navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs.navbar-inverted .navbar-collapse,
.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-xs.navbar-inverted .navbar-vertical-content {
  width: 14.5rem !important;
}

/* -------------------------------------------------------------------------- */
/*                                 Navbar Top                                 */
/* -------------------------------------------------------------------------- */
.navbar-top {
  position: sticky;
  top: 0;
  font-size: 0.83333rem;
  font-weight: 600;
  margin-left: -1rem;
  margin-right: -1rem;
  z-index: 1020;
  background-image: none;
}

.navbar-top .navbar-nav-icons .dropdown-menu {
  position: absolute;
}

.navbar-top .navbar-collapse {
  overflow: auto;
  max-height: calc(100vh - 4.3125rem);
  margin: 0 -0.75rem;
  padding: 0 0.75rem;
  flex: 1 0 100%;
  order: 1;
}

.navbar-top .navbar-toggler {
  margin-left: -0.625rem;
}

.navbar-top[data-navbar-top="combo"] .navbar-collapse {
  width: auto;
}

.dropdown-menu-card {
  padding: 0;
}

.dropdown-menu-card .card {
  border: 0;
}

.navbar-toggler {
  border: 0;
  padding: 0;
}

.navbar-top .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after,
.navbar-standard .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after {
  display: none;
}

.settings-popover {
  position: relative;
  padding: 0;
  height: 1.875rem;
  width: 1.875rem;
  outline: none;
}

@media (min-width: 576px) {
  .navbar-expand-sm.navbar-top .navbar-collapse {
    max-height: none;
    margin: 0;
    padding: 0;
    order: 0;
    flex: 0 1 auto;
  }
  .navbar-expand-sm.navbar .max-h-dropdown {
    max-height: 75vh;
  }
  .navbar-expand-sm.navbar-standard .navbar-collapse {
    max-height: none;
  }
  .navbar-expand-sm.navbar-top .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after, .navbar-expand-sm.navbar-standard .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after {
    display: block;
  }
  .navbar.navbar-expand-sm:not(.navbar-vertical) .navbar-collapse {
    overflow: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md.navbar-top .navbar-collapse {
    max-height: none;
    margin: 0;
    padding: 0;
    order: 0;
    flex: 0 1 auto;
  }
  .navbar-expand-md.navbar .max-h-dropdown {
    max-height: 75vh;
  }
  .navbar-expand-md.navbar-standard .navbar-collapse {
    max-height: none;
  }
  .navbar-expand-md.navbar-top .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after, .navbar-expand-md.navbar-standard .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after {
    display: block;
  }
  .navbar.navbar-expand-md:not(.navbar-vertical) .navbar-collapse {
    overflow: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg.navbar-top .navbar-collapse {
    max-height: none;
    margin: 0;
    padding: 0;
    order: 0;
    flex: 0 1 auto;
  }
  .navbar-expand-lg.navbar .max-h-dropdown {
    max-height: 75vh;
  }
  .navbar-expand-lg.navbar-standard .navbar-collapse {
    max-height: none;
  }
  .navbar-expand-lg.navbar-top .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after, .navbar-expand-lg.navbar-standard .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after {
    display: block;
  }
  .navbar.navbar-expand-lg:not(.navbar-vertical) .navbar-collapse {
    overflow: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl.navbar-top .navbar-collapse {
    max-height: none;
    margin: 0;
    padding: 0;
    order: 0;
    flex: 0 1 auto;
  }
  .navbar-expand-xl.navbar .max-h-dropdown {
    max-height: 75vh;
  }
  .navbar-expand-xl.navbar-standard .navbar-collapse {
    max-height: none;
  }
  .navbar-expand-xl.navbar-top .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after, .navbar-expand-xl.navbar-standard .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after {
    display: block;
  }
  .navbar.navbar-expand-xl:not(.navbar-vertical) .navbar-collapse {
    overflow: visible;
  }
}

@media (min-width: 1540px) {
  .navbar-expand-xxl.navbar-top .navbar-collapse {
    max-height: none;
    margin: 0;
    padding: 0;
    order: 0;
    flex: 0 1 auto;
  }
  .navbar-expand-xxl.navbar .max-h-dropdown {
    max-height: 75vh;
  }
  .navbar-expand-xxl.navbar-standard .navbar-collapse {
    max-height: none;
  }
  .navbar-expand-xxl.navbar-top .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after, .navbar-expand-xxl.navbar-standard .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after {
    display: block;
  }
  .navbar.navbar-expand-xxl:not(.navbar-vertical) .navbar-collapse {
    overflow: visible;
  }
}

.img-dropdown {
  position: absolute;
  bottom: 0;
  right: 0;
}

html[dir="rtl"] .img-dropdown {
  transform: scaleX(-1);
}

@media (min-width: 768px) {
  .navbar-card-components {
    min-width: 28.8125rem;
  }
  .navbar-card-auth {
    min-width: 21rem;
  }
  .navbar-card-pages {
    min-width: 31.25rem;
  }
}

@media (min-width: 992px) {
  .navbar-card-login {
    min-width: 22rem;
  }
}

@media (min-width: 1540px) {
  .navbar-card-components {
    min-width: 53.125rem;
  }
}

@media (min-width: 1540px) {
  .navbar-card-auth {
    min-width: 40.625rem;
  }
}

/*-----------------------------------------------
|   Search box
-----------------------------------------------*/
.search-box {
  position: relative;
}

.search-box .search-input {
  width: 20rem;
  padding-left: 1.95rem;
  line-height: 1.7;
}

.search-box .search-box-icon {
  z-index: 1020;
  top: 50%;
  left: 0.9rem;
  transform: translateY(-48%);
}

.safari .search-box .search-input {
  line-height: 2;
}

/* -------------------------------------------------------------------------- */
/*                                   Avatar                                   */
/* -------------------------------------------------------------------------- */
.avatar {
  position: relative;
  display: inline-block;
}

.avatar .avatar-name {
  background-color: #344050;
  position: absolute;
  text-align: center;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
}

.avatar .avatar-name > span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.avatar .avatar-emoji {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar .avatar-emoji > span {
  transform: translateY(2%);
  display: inline-block;
}

.avatar img {
  display: block;
}

.avatar img,
.avatar .avatar-name {
  width: 100%;
  height: 100%;
}

.avatar img {
  object-fit: cover;
}

.avatar[class*="status-"]:before {
  content: "";
  position: absolute;
  border-radius: 100%;
  z-index: 1;
}

.avatar.status-online:before {
  background-color: #00d27a;
}

.avatar.status-offline:before {
  background-color: #748194;
}

.avatar.status-away:before {
  background-color: #f5803e;
}

.avatar.status-do-not-disturb:before {
  background-color: #e63757;
}

.avatar .avatar-button {
  background-color: #b6c1d2 !important;
  transition: all 0.2s ease-in-out;
  border: 1px solid #b6c1d2;
  font-size: 12px;
}

.avatar .avatar-button:hover, .avatar .avatar-button:focus {
  background-color: #9da9bb !important;
}

.avatar:hover .overlay-icon {
  opacity: 1;
}

.avatar-s {
  height: 1.25rem;
  width: 1.25rem;
}

.avatar-s .avatar-name {
  font-size: 0.41667rem;
}

.avatar-s .avatar-emoji {
  font-size: 1.25rem;
}

.avatar-s[class*="status-"]:before {
  border: 1px solid #fff;
  height: 9px;
  width: 9px;
  right: -1px;
  bottom: -1px;
}

.avatar-s .rounded-soft {
  border-radius: 0.20833rem !important;
}

.avatar-m {
  height: 1.5rem;
  width: 1.5rem;
}

.avatar-m .avatar-name {
  font-size: 0.5rem;
}

.avatar-m .avatar-emoji {
  font-size: 1.5rem;
}

.avatar-m[class*="status-"]:before {
  border: 1px solid #fff;
  height: 9px;
  width: 9px;
  right: -1px;
  bottom: -1px;
}

.avatar-m .rounded-soft {
  border-radius: 0.25rem !important;
}

.avatar-l {
  height: 1.75rem;
  width: 1.75rem;
}

.avatar-l .avatar-name {
  font-size: 0.58333rem;
}

.avatar-l .avatar-emoji {
  font-size: 1.75rem;
}

.avatar-l[class*="status-"]:before {
  border: 1px solid #fff;
  height: 9px;
  width: 9px;
  right: -1px;
  bottom: -1px;
}

.avatar-l .rounded-soft {
  border-radius: 0.29167rem !important;
}

.avatar-xl {
  height: 2rem;
  width: 2rem;
}

.avatar-xl .avatar-name {
  font-size: 0.66667rem;
}

.avatar-xl .avatar-emoji {
  font-size: 2rem;
}

.avatar-xl[class*="status-"]:before {
  border: 2px solid #fff;
  height: 13px;
  width: 13px;
  right: -2px;
  bottom: -2px;
}

.avatar-xl .rounded-soft {
  border-radius: 0.33333rem !important;
}

.avatar-2xl {
  height: 2.5rem;
  width: 2.5rem;
}

.avatar-2xl .avatar-name {
  font-size: 0.83333rem;
}

.avatar-2xl .avatar-emoji {
  font-size: 2.5rem;
}

.avatar-2xl[class*="status-"]:before {
  border: 2px solid #fff;
  height: 16px;
  width: 16px;
  right: -3px;
  bottom: -3px;
}

.avatar-2xl .rounded-soft {
  border-radius: 0.41667rem !important;
}

.avatar-3xl {
  height: 3.5rem;
  width: 3.5rem;
}

.avatar-3xl .avatar-name {
  font-size: 1.16667rem;
}

.avatar-3xl .avatar-emoji {
  font-size: 3.5rem;
}

.avatar-3xl[class*="status-"]:before {
  border: 3px solid #fff;
  height: 18px;
  width: 18px;
  right: 0;
  bottom: 0;
}

.avatar-3xl .rounded-soft {
  border-radius: 0.58333rem !important;
}

.avatar-4xl {
  height: 6.125rem;
  width: 6.125rem;
}

.avatar-4xl .avatar-name {
  font-size: 2.04167rem;
}

.avatar-4xl .avatar-emoji {
  font-size: 6.125rem;
}

.avatar-4xl[class*="status-"]:before {
  border: 3px solid #fff;
  height: 24px;
  width: 24px;
  right: 3px;
  bottom: 3px;
}

.avatar-4xl .rounded-soft {
  border-radius: 1.02083rem !important;
}

.avatar-5xl {
  height: 10.5rem;
  width: 10.5rem;
}

.avatar-5xl .avatar-name {
  font-size: 3.5rem;
}

.avatar-5xl .avatar-emoji {
  font-size: 10.5rem;
}

.avatar-5xl[class*="status-"]:before {
  border: 4px solid #fff;
  height: 32px;
  width: 32px;
  right: 8px;
  bottom: 8px;
}

.avatar-5xl .rounded-soft {
  border-radius: 1.75rem !important;
}

/*-----------------------------------------------
|   Notification
-----------------------------------------------*/
.notification {
  color: #232e3c;
  text-decoration: none;
  background-color: #fff;
  display: flex;
  font-size: 0.83333rem;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  transition: all 0.2s ease-in-out;
}

.notification:not(.notification-flush) {
  border: 1px solid #f9fafd;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 0.375rem;
  padding: 1rem;
}

.notification:hover, .notification:focus {
  color: #0b1727;
  text-decoration: none;
}

.notification-body {
  margin-bottom: 0.25rem;
}

.notification-time {
  margin-bottom: 0;
  color: #748194;
  font-size: 0.83333rem;
}

.notification-unread {
  background-color: #edf2f9 !important;
}

.notification-unread:hover, .notification-unread:focus {
  background-color: #dae4f3 !important;
}

.notification-indicator {
  position: relative;
}

.notification-indicator::before {
  position: absolute;
  content: '';
  right: 0.125rem;
  top: 0.5rem;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  border: 2px solid #edf2f9;
}

.notification-indicator-number {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0.125rem;
  top: 0.505rem;
  height: 1rem;
  width: 1rem;
  font-size: 0.67rem;
  color: #fff;
  font-weight: 700;
}

.notification-indicator-primary::before {
  background-color: #2c7be5;
}

.notification-indicator-warning::before {
  background-color: #f5803e;
}

.notification-indicator-fill::before {
  border: 0;
}

.card-notification {
  min-width: 16rem;
}

.card-notification .card-header,
.card-notification .card-footer {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.card-notification .list-group-flush .list-group-item {
  padding: 0;
}

.card-notification .list-group-flush .list-group-item .notification-flush {
  padding: 0.5rem 1rem;
}

.card-notification .list-group-flush .list-group-title {
  background-color: #f9fafd;
  font-weight: 600;
  font-size: 0.69444rem;
  color: #748194;
  padding: 0.25rem 1.25rem;
}

@media (min-width: 576px) {
  .card-notification {
    min-width: 20rem;
  }
}

/*-----------------------------------------------
|   Scrollbar style for windows
-----------------------------------------------*/
.scrollbar {
  overflow: auto;
}

.windows.chrome .scrollbar {
  overflow: auto;
}

.windows.chrome .scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
  background-color: transparent;
}

.windows.chrome .scrollbar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(116, 129, 148, 0.3);
}

.windows.firefox .scrollbar {
  scrollbar-color: rgba(116, 129, 148, 0.3) transparent;
  scrollbar-width: thin;
}

/*-----------------------------------------------
|   Dashboard
-----------------------------------------------*/
.file-thumbnail {
  height: 2.25rem;
  width: 2.25rem;
}

.dot {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5rem;
}

/* -------------------------------------------------------------------------- */
/*                                  Accordion                                 */
/* -------------------------------------------------------------------------- */
.btn-accordion {
  padding: 0.25rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.83333rem;
  font-weight: 500;
}

.btn-accordion[aria-expanded="true"] [data-prefix="fas"] {
  transform: rotate(90deg);
}

.accordion-icon {
  transition: transform 0.1s linear;
  color: #344050;
}

[aria-expanded="true"] .accordion-icon {
  transform: rotate(90deg);
}

/*-----------------------------------------------
|   Calender
-----------------------------------------------*/
.calendar {
  width: 3.125rem;
  line-height: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  border-radius: 0.375rem;
}

.calendar .calendar-month {
  background-color: #e63757;
  color: #fff;
  border-radius: 0.375rem 0.375rem 0 0;
  padding: 0.25rem;
  font-weight: 600;
}

.calendar .calendar-day {
  font-size: 1.728rem;
  padding: 0.25rem;
  border: 0.125rem solid rgba(230, 55, 87, 0.3);
  border-top: 0;
  border-radius: 0 0 0.375rem 0.375rem;
  color: #9da9bb;
  font-weight: 700;
}

/* -------------------------------------------------------------------------- */
/*                                    Hover                                   */
/* -------------------------------------------------------------------------- */
.hover-actions {
  display: none;
  position: absolute;
}

.hover-actions.show {
  display: inline-block;
}

.hover-actions.show + .hover-hide {
  display: none;
}

.hover-shadow:hover, .hover-shadow:focus {
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
}

.hover-actions-trigger {
  position: relative;
}

.hover-actions-trigger .inbox-link {
  color: inherit;
  text-decoration: none;
}

.hover-actions-trigger:hover .hover-actions, .hover-actions-trigger:focus .hover-actions {
  z-index: 1;
  display: flex;
}

.hover-actions-trigger:hover .hover-actions.btn, .hover-actions-trigger:focus .hover-actions.btn {
  display: inline-block;
}

.hover-actions-trigger:hover .hover-hide, .hover-actions-trigger:focus .hover-hide {
  display: none;
}

/* -------------------------------------------------------------------------- */
/*                                  Animations                                 */
/* -------------------------------------------------------------------------- */
.icon-spin {
  animation: icon-spin 6s cubic-bezier(0.15, 1.15, 0.65, 1) infinite;
}

.settings-popover .d-flex {
  transform-origin: center;
  transform-box: fill-box;
}

.ripple {
  position: absolute;
}

.ripple .fa-spin {
  animation-duration: 3s;
}

.ripple:after, .ripple:before {
  position: absolute;
  content: "";
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  border: 3px solid rgba(0, 210, 122, 0.4);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  animation: ripple 2s ease infinite;
}

.ripple:before {
  border: 5px solid rgba(0, 210, 122, 0.3);
  animation-delay: 5s;
}

@keyframes ripple {
  0% {
    height: 1rem;
    width: 1rem;
  }
  100% {
    height: 3.125rem;
    width: 3.125rem;
    border-color: transparent;
  }
}

@keyframes icon-spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* -------------------------------------------------------------------------- */
/*                                    Modal                                   */
/* -------------------------------------------------------------------------- */
.modal-fixed-right {
  padding-right: 0 !important;
}

.modal-fixed-right .modal-dialog {
  margin: 0;
  margin-left: auto;
  margin-right: initial;
  max-width: 22rem;
}

.modal-fixed-right .modal-content {
  border-radius: 0;
}

.modal-fixed-right.fade .modal-dialog-vertical {
  transform: translateX(100%);
}

.modal-fixed-right.show .modal-dialog-vertical {
  transform: translateX(0%);
}

.modal-header-settings {
  min-height: 4.3125rem;
  position: relative;
  display: flex;
  align-items: flex-start;
  border: none;
  border-radius: 0;
  padding: 0.5rem 1.25rem;
  background-image: linear-gradient(-45deg, #4695ff, #1970e2);
  overflow: hidden;
}

.modal-header-settings:before, .modal-header-settings:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  height: 12.5rem;
  width: 12.5rem;
  background-image: linear-gradient(45deg, #318aff, #247cef);
}

.modal-header-settings:after {
  left: 5.125rem;
  top: 1.188rem;
}

.modal-header-settings:before {
  right: -6.25rem;
  top: -7.938rem;
}

.modal-header-settings .close {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  opacity: 0.75;
  padding-top: 0.75rem;
  margin-top: 0;
  margin-right: 0.25rem;
  outline: 0;
}

.modal-header-settings .close:hover:not(:disabled), .modal-header-settings .close:hover:not(.disabled), .modal-header-settings .close:focus:not(:disabled), .modal-header-settings .close:focus:not(.disabled) {
  opacity: 1;
}

/*-----------------------------------------------
|   Icons group
-----------------------------------------------*/
.icon-group {
  display: flex;
}

.icon-group .icon-item:not(:last-child) {
  margin-right: 0.5rem;
}

.icon-item {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5e6e82;
  transition: all 0.2s ease-in-out;
  height: 2.5rem;
  width: 2.5rem;
  border: 0;
  font-size: 0.875rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.icon-item:hover, .icon-item:focus {
  background-color: #edf2f9;
}

.icon-item.icon-item-sm {
  height: 1.875rem;
  width: 1.875rem;
}

.icon-item.icon-item-lg {
  height: 2.75rem;
  width: 2.75rem;
}

.fontawesome-i2svg-active .icon-indicator {
  opacity: 1;
}

/*-----------------------------------------------
|   Landing banner style
-----------------------------------------------*/
.img-landing-banner {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  overflow: hidden;
  transform: translateY(-3rem);
  margin-bottom: -28rem;
  transition: transform 0.4s ease;
  position: relative;
  box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.175);
  display: block;
}

@media (min-width: 1200px) and (max-width: 1539.98px) {
  .img-landing-banner {
    margin-bottom: -18rem;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .img-landing-banner {
    margin-bottom: -20rem;
  }
}

.img-landing-banner:after {
  content: '';
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70%;
  transition: opacity 0.6s;
}

.img-landing-banner:hover, .img-landing-banner:focus {
  transform: translateY(-5rem);
}

.img-landing-banner:hover:after, .img-landing-banner:focus:after {
  opacity: 0;
}

@media (max-width: 575.98px) {
  .landing-cta-img {
    width: 100%;
    height: auto;
  }
}

/*-----------------------------------------------
|  Back to top button   
-----------------------------------------------*/
.btn-back-to-top {
  background-color: #0b1727;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 0.25rem;
  left: 50%;
  top: -7.5rem;
  transform: translate3d(-50%, -50%, 0) rotate(-45deg);
  height: 2.5rem;
  width: 2.5rem;
}

/*-----------------------------------------------
|   Special Card
-----------------------------------------------*/
.card-span {
  transition: all 0.4s ease;
}

.card-span .card-span-img {
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 5rem;
  height: 5rem;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-span:hover, .card-span:focus {
  transform: translateY(-0.2rem);
  box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.175);
}

/* -------------------------------------------------------------------------- */
/*                               Navbar Standard                              */
/* -------------------------------------------------------------------------- */
.navbar-standard {
  font-size: 0.83333rem;
  font-weight: 600;
}

.navbar-standard .navbar-collapse {
  overflow-y: auto;
  max-height: calc(100vh - 3.5625rem);
}

/* -------------------------------------------------------------------------- */
/*                             Bootstrap Carousel                             */
/* -------------------------------------------------------------------------- */
.theme-slider:hover .carousel-control-next,
.theme-slider:hover .carousel-control-prev, .theme-slider:focus .carousel-control-next,
.theme-slider:focus .carousel-control-prev {
  color: #5e6e82;
  opacity: 0.5 !important;
}

.theme-slider .carousel-control-prev,
.theme-slider .carousel-control-next {
  width: 3rem;
  height: 3rem;
  background: #fff;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: opacity 0.4s ease-in-out;
  color: #5e6e82;
  opacity: 0;
}

.theme-slider .carousel-control-prev:hover, .theme-slider .carousel-control-prev:focus,
.theme-slider .carousel-control-next:hover,
.theme-slider .carousel-control-next:focus {
  opacity: 1 !important;
}

.theme-slider .carousel-control-prev {
  left: 0.5rem;
}

.theme-slider .carousel-control-next {
  right: 0.5rem;
}

/*-----------------------------------------------
|   Form
-----------------------------------------------*/
label {
  font-size: 0.83333rem;
  font-weight: 700;
  letter-spacing: 0.02em;
}

.form-check-label {
  line-height: 1.45rem;
}

/*-----------------------------------------------
|   Remove spinner from Input[type="number"]
-----------------------------------------------*/
.input-spin-none::-webkit-outer-spin-button,
.input-spin-none::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-spin-none[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

/*-----------------------------------------------
|   Settings Panels checkbox
-----------------------------------------------*/
.custom-radio-success .custom-control-input:not(:disabled):active ~ .form-check-label::before {
  background-color: rgba(0, 210, 122, 0.2);
  border-color: rgba(0, 210, 122, 0.2);
}

.custom-radio-success .custom-control-input:focus ~ .form-check-label:before {
  box-shadow: 0 0 0 0.2rem rgba(0, 210, 122, 0.25);
}

.custom-radio-success .custom-control-input:checked ~ .form-check-label:before {
  background-color: #00d27a;
  border-color: #00d27a;
}

.radio-select .form-check-label {
  cursor: pointer;
}

.radio-select-content {
  border: 1px solid #edf2f9;
  color: #9da9bb;
  transition: all 0.2s ease-in-out;
  display: block;
  padding: 1rem;
  font-weight: 400;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
}

.radio-select-link {
  color: inherit;
}

.radio-select .form-check-input:checked + .form-check-label .radio-select-content {
  border: 1px solid #00d27a;
  color: #0b1727;
}

/* -------------------------------------------------------------------------- */
/*                               Settings Panel                               */
/* -------------------------------------------------------------------------- */
.btn-group-navbar-style input[name="navbarStyle"] + .btn-navbar-style {
  box-shadow: none;
  padding: 0;
  margin-bottom: 1rem;
  text-align: left;
}

.btn-group-navbar-style input[name="navbarStyle"] + .btn-navbar-style .img-prototype {
  border: 3px solid #edf2f9;
  border-radius: 0.375rem;
  transition: border 0.2s ease;
  margin-bottom: 0.5rem;
  background-color: #f9fafd;
}

.btn-group-navbar-style input[name="navbarStyle"] + .btn-navbar-style .label-text {
  position: relative;
  padding-left: 1.5rem;
}

.btn-group-navbar-style input[name="navbarStyle"] + .btn-navbar-style .label-text:after {
  position: absolute;
  content: '';
  left: 0;
  width: 1rem;
  height: 1rem;
  border: 1px solid #9da9bb;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  transition: border 0.2s ease;
}

.btn-group-navbar-style input[name="navbarStyle"]:checked:not([disabled]) + .btn-navbar-style .img-prototype {
  border-color: #00d27a;
}

.btn-group-navbar-style input[name="navbarStyle"]:checked:not([disabled]) + .btn-navbar-style .label-text::after {
  border: 5px solid #00d27a;
}

.btn-group-navbar-style input[name="navbarStyle"]:disabled + .btn-navbar-style {
  opacity: 0.5;
}

.nav-tabs {
  border-bottom: 2px solid #F9FAFD;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: #fff;
  color: #4d5969;
  font-weight: 700;
  font-size: 0.875rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #fff;
}

.nav-tabs .nav-link.disabled {
  color: #000;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #2c7be5;
  background-color: #fff;
  border-bottom: 2px solid #2c7be5;
}

.rating {
  display: flex;
  padding: 0;
  margin: 0;
}

.rating li {
  list-style-type: none;
}

.rating-item {
  border: 1px solid #fff;
  cursor: pointer;
  font-size: 2rem;
  color: #f5803e;
}

.rating-item::before {
  content: "\2605";
}

.rating-item.active ~ .rating-item::before {
  content: "\2606";
}

.rating:hover .rating-item::before {
  content: "2605";
}

.rating-item:hover ~ .rating-item::before {
  content: "\2606";
}

/*-----------------------------------------------
|   Chat Page
-----------------------------------------------*/
.card-chat {
  height: calc(100vh - 4.3125rem - 0.625rem);
  z-index: 1;
}

.card-chat .card-body {
  position: relative;
  overflow: hidden;
}

.card-chat-pane {
  height: calc(100% - 2.063rem);
  position: relative;
  overflow: hidden;
}

.card-chat-content {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  height: 100%;
  font-size: 0.83333rem;
  position: relative;
  display: flex;
  flex-direction: column;
}

/*-----------------------------------------------
|   Chat Sidebar
-----------------------------------------------*/
.chat-sidebar {
  position: absolute;
  height: 100%;
  z-index: 11;
  top: 0;
  left: -100%;
  width: 100%;
  transition: all 0.5s ease;
  overflow: hidden;
}

.contacts-list {
  height: calc(100% - 2rem);
  border-right: 1px solid #d8e2ef;
  overflow-y: auto;
  width: 100%;
}

.chat-contact {
  position: relative;
  width: 100% !important;
  align-items: center;
  height: 4.188rem;
  cursor: pointer;
  border-top: 1px solid transparent;
  background-color: #fff;
}

.chat-contact:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 0.25rem;
  left: 0;
  top: 0;
}

.chat-contact:first-child {
  border-top: 0 !important;
}

.chat-contact:hover:after, .chat-contact:focus:after {
  background-color: #00d27a;
}

.chat-contact.active:after {
  background-color: #00d27a;
}

.chat-contact.active:not(:first-child),
.chat-contact.active + .chat-contact {
  border-top: 1px solid #d8e2ef;
}

.chat-contact-body {
  position: relative;
  min-width: 0;
}

.chat-contact-title {
  font-weight: 400;
}

.message-time {
  color: #b6c1d2;
}

.chat-contact-content {
  font-size: 0.83333rem;
  color: #748194;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.unread-message {
  color: #000;
}

.unread-message .chat-contact-title,
.unread-message .chat-contact-content {
  font-weight: 600;
  color: #000;
}

.unread-message .message-time {
  color: #2c7be5;
}

.contacts-search-wrapper {
  position: relative;
  border-top: 1px solid #d8e2ef;
  border-right: 1px solid #d8e2ef;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.063rem;
}

.chat-contacts-search {
  padding-left: 2.12rem;
}

.chat-contacts-search:hover, .chat-contacts-search:focus {
  box-shadow: none;
}

.contacts-search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #b6c1d2;
  font-size: 0.83333rem;
  left: 1rem;
}

/*-----------------------------------------------
|   Chat contents
-----------------------------------------------*/
.chat-content-header {
  padding: 1rem;
  border-bottom: 1px solid #d8e2ef;
}

.chat-content-body {
  height: calc(100% - 4.25rem);
}

.chat-content-body .scroll-content {
  position: relative;
}

.chat-content-scroll-area {
  height: 100%;
  padding-bottom: 1rem;
  overflow-y: auto;
}

.chat-message {
  max-width: calc(100% - 7rem);
  margin-bottom: 0.25rem;
  display: inline-block;
  position: relative;
}

.message-settings {
  position: absolute;
  bottom: -0.9375rem;
  border: 1px solid #edf2f9;
  background-color: #f9fafd;
  border-radius: 50%;
  height: 1.875rem;
  width: 1.875rem;
  text-align: center;
}

.message-settings-left {
  right: -0.9375rem;
}

.message-settings-right {
  left: -0.9375rem;
}

.conversation-info {
  position: absolute;
  width: 15rem;
  right: -15rem;
  background-color: #f9fafd;
  border-left: 1px solid #d8e2ef;
  z-index: 10;
  top: 4.25rem;
  height: calc(100% - 4.25rem);
  transition: right 0.5s ease;
}

.conversation-info.show {
  right: 0;
}

.conversation-info .conversation-info-icon {
  width: 1.25rem;
  font-size: 1rem;
}

.chat-file-upload {
  position: absolute;
  right: 5.5rem;
  z-index: 2;
  top: 0.125rem;
  color: #9da9bb;
}

.chat-file-upload:hover, .chat-file-upload:focus {
  color: #748194;
}

.btn-message-submit {
  position: absolute;
  right: 3.938rem;
  top: 0.1875rem;
}

.btn-send {
  position: absolute;
  top: 0.125rem;
  z-index: 2;
  right: 1rem;
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
  color: #9da9bb;
  outline: none !important;
  margin-left: 0.5rem;
}

.chat-editor-area {
  border-top: 1px solid #d8e2ef;
  position: relative;
  width: 100%;
}

.chat-editor-area .emoji-icon {
  position: absolute;
  top: 5px;
  right: 64px;
  cursor: pointer;
  color: #9da9bb;
}

.chat-editor-area .emoji-icon:hover, .chat-editor-area .emoji-icon:focus {
  color: #748194 !important;
}

.chat-editor-area .emojiarea-editor {
  padding: 0.3rem 7rem 0.3rem 1rem;
  font-size: 14px;
  max-height: 6.25rem;
  overflow: auto;
  word-break: break-all;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

.chat-editor-area .emojiarea-editor[contenteditable=true]:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  color: #b6c1d2;
}

.chat-option {
  color: #b6c1d2;
}

.chat-option:hover, .chat-option:focus {
  color: #2c7be5;
}

@media (min-width: 576px) {
  .contacts-list {
    height: calc(100% - 2.063rem);
  }
  .conversation-scroll-area {
    height: calc(100% - 2.188rem);
  }
  .card-chat {
    height: calc(100vh - 4.3125rem - 3.9875rem);
  }
}

@media (min-width: 768px) {
  .chat-sidebar {
    position: relative;
    height: 100%;
    top: 0;
    left: 0 !important;
    width: auto;
    transition: none;
  }
}

@media (min-width: 992px) {
  .chat-sidebar {
    flex: 0 0 17.5rem;
    max-width: 17.5rem;
  }
}

@media (min-width: 1540px) {
  .chat-sidebar {
    flex: 0 0 21.88rem;
    max-width: 21.88rem;
  }
}

.safari .contacts-list {
  height: calc(100% - 2.063rem);
}

.safari .contacts-search-wrapper {
  height: 2.125rem;
}

.ipad .card-chat,
.mobile.safari .card-chat,
.mobile.chrome .card-chat {
  height: calc(100vh - 4.3125rem - 0.625rem - 3.5rem);
}

.kanban-container {
  padding-bottom: 0.25rem;
  white-space: nowrap;
}

.kanban-column {
  display: inline-block !important;
  width: 22.1875rem;
  white-space: normal;
  vertical-align: top;
  margin-right: 1rem;
}

.kanban-column:not(.form-added) .add-card-form {
  display: none;
}

.kanban-column:not(:first-child) {
  margin-left: -0.375rem;
}

.kanban-column-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9fafd;
  padding: 0.5rem 1.25rem;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.kanban-column-footer {
  background-color: #f9fafd;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  padding: 0.5rem 1.25rem;
}

.kanban-items-container {
  outline: none;
  padding: 0.5rem 1.25rem;
  max-height: calc(100vh - 14.9375rem);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f9fafd;
}

.kanban-items-container .kanban-collapse:first-child,
.kanban-items-container .kanban-item:first-child {
  margin-top: 0 !important;
}

.kanban-item {
  cursor: pointer;
  user-select: none;
  outline: none;
  margin-top: 1rem;
}

.kanban-item-card {
  border: 0;
  color: #344050;
  font-size: 0.83333rem;
  background-color: #fff;
  border-radius: 0.375rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.kanban-item-dropdown-btn {
  padding: 0 0.5rem;
  position: absolute !important;
  right: 0;
  top: 0;
  z-index: 2 !important;
}

.kanban-item-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.69444rem;
  margin-top: 1rem;
  color: #748194;
}

.form-added .kanban-items-container {
  max-height: calc(100vh - 12.125rem);
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.form-added .kanban-column-footer {
  display: none;
}

.add-card-form {
  padding: 1rem;
  border-radius: 0.375rem;
  background-color: #fff;
  border: 1px solid #d8e2ef;
  margin-top: 1rem;
}

.btn-add-card:hover, .btn-add-card:focus {
  background-color: #edf2f9;
}

.bg-attachment {
  position: relative;
  height: 3.5625rem;
  width: 6.25rem;
  border-radius: 0.25rem;
  overflow: hidden;
  background: #d8e2ef;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #748194;
}

.bg-attachment .icon-play {
  position: relative;
  color: #edf2f9;
}

.bg-attachment:hover, .bg-attachment:focus {
  color: #5e6e82 !important;
}

.bg-attachment:hover .icon-play, .bg-attachment:focus .icon-play {
  color: #d8e2ef;
}

.avatar-group .avatar {
  vertical-align: top;
}

.draggable--is-dragging * {
  cursor: grab !important;
}

.draggable-source--is-dragging {
  background-color: #edf2f9 !important;
  box-shadow: none !important;
  border-radius: 0.375rem;
}

.draggable-source--is-dragging * {
  opacity: 0;
}

.add-card-form + .draggable-source--is-dragging {
  margin-top: 0 !important;
}

.draggable-mirror {
  z-index: 1100;
}

.draggable-mirror .kanban-item-card {
  transform: rotate(-2deg);
}

button.btn-circle {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  background-color: #edf2f9;
  text-shadow: none;
  border-radius: 50%;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  opacity: 1;
}

button.btn-circle:hover, button.btn-circle:focus {
  opacity: 1 !important;
  background-color: #d8e2ef;
}

.icon-title {
  width: 1.25rem;
  text-align: center;
  margin-right: 1rem;
}

div[data-collapse] + .kanban-item,
.draggable--original:first-child + .kanban-item {
  margin-top: 0;
}

.nav-link-card-details {
  background-color: #f9fafd;
  color: #5e6e82;
  transition: all 0.2s ease-in-out;
  border-radius: 0.25rem;
  margin-bottom: 0.25rem;
}

.nav-link-card-details:hover, .nav-link-card-details:focus {
  background-color: #edf2f9;
  color: #5e6e82;
}

.ipad .kanban-items-container,
.mobile.safari .kanban-items-container,
.mobile.chrome .kanban-items-container {
  max-height: calc(100vh - 18.4375rem);
}

.ipad .form-added .kanban-items-container,
.mobile.safari .form-added .kanban-items-container,
.mobile.chrome .form-added .kanban-items-container {
  max-height: calc(100vh - 15.625rem);
}

/*-----------------------------------------------
|   Cover and profile image
-----------------------------------------------*/
.avatar-profile {
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
}

.overlay-icon {
  position: absolute;
  height: 50%;
  width: 100%;
  bottom: 0;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.cover-image-file-input {
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.25rem 0.5rem;
  margin-left: 1rem;
  margin-top: 1rem;
  border-radius: 0.25rem;
  font-size: 0.83333rem;
  color: #fff;
  border: 1px solid #d8e2ef;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  background-color: rgba(0, 0, 0, 0.9);
}

.cover-image {
  overflow: hidden;
}

.cover-image:hover .cover-image-file-input {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.theme-wizard .nav-link {
  display: flex;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  text-align: center;
  color: #748194;
  display: block;
}

.theme-wizard .nav-link .nav-item-circle {
  color: #9da9bb;
  background-color: #fff;
  border: 2px solid #d8e2ef;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  line-height: 2.25rem;
  padding: 0;
  text-align: center;
  align-self: center;
  display: block;
  transition: all 0.2s ease-in-out;
}

.theme-wizard .nav-link.active {
  color: #2c7be5;
}

.theme-wizard .nav-link.active .nav-item-circle {
  background-color: #2c7be5 !important;
  color: #fff;
  border-color: #2c7be5;
}

.theme-wizard .nav-link.done {
  color: #00d27a;
}

.theme-wizard .nav-link.done .nav-item-circle {
  background-color: #00d27a !important;
  color: #fff;
  border-color: #00d27a;
}

.theme-wizard .nav-link.done .nav-item-circle .check-icon {
  display: inline-block;
}

.theme-wizard .nav-link.done .nav-item-circle-parent:before {
  background-color: #00d27a;
}

.theme-wizard .nav-link .nav-item-circle .check-icon {
  display: none;
}

.theme-wizard .nav-link .nav-item-circle-parent {
  display: block;
}

.theme-wizard .nav-link .nav-item-circle-parent:before {
  content: "";
  height: 2px;
  width: 101%;
  position: absolute;
  left: 51%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  background: #d8e2ef;
}

.theme-wizard .nav-item:not(:first-child) .active .nav-item-circle-parent:after {
  content: "";
  width: 50%;
  position: absolute;
  left: -1px;
  top: 50%;
  transform: translateY(-50%);
  height: 2px;
  background-color: #00d27a;
}

.theme-wizard .nav-item-circle-parent {
  position: relative;
  padding: 0 1rem;
}

.theme-wizard .nav-item-circle {
  margin: 0 auto;
  z-index: 1;
  position: relative;
}

.theme-wizard .nav-item {
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  max-width: 100%;
  justify-content: center;
}

.theme-wizard .nav-item:first-child .nav-item-circle-parent:before, .theme-wizard .nav-item:last-child .nav-item-circle-parent:before {
  width: 50%;
}

.theme-wizard .nav-item:first-child .nav-item-circle-parent:before {
  transform: translate3d(0, -50%, 0);
}

.theme-wizard .nav-item:last-child .nav-item-circle-parent:before {
  left: 0;
  transform: translate3d(0, -50%, 0);
}

.theme-wizard .pager {
  display: flex;
  justify-content: space-between;
}

.theme-wizard .wizard-lottie-wrapper {
  height: 1px;
  position: relative;
  padding-bottom: 11.875rem;
  overflow: hidden;
}

.theme-wizard .wizard-lottie-wrapper .wizard-lottie {
  width: 21.25rem;
  height: auto;
  transform: translateY(-73%);
}

/*-----------------------------------------------
|   User Styles
-----------------------------------------------*/
