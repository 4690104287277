
.theme-slider{
  .swiper-nav{
    margin-top: 0;
    cursor: pointer;
    [class*=swiper-] {
      opacity: 0;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      font-size: modular-scale(2);
      transition: opacity 0.4s ease-in-out;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      text-align: center;
      color: $gray-700;
      margin: 0;
      background-color: rgba($white, 0.8) !important;
      box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
      z-index: 99;
      @include hover-focus {
        background-color: $white;
        color: $gray-700;
        opacity: 1 !important;
      }
    }
    .swiper-button-prev{ 
      left: map_get($spacers, 2);
    }
    .swiper-button-next{ right: map_get($spacers, 2); }
  }
  
  @include hover-focus { .swiper-nav { [class*=swiper-] { opacity: 0.5; } } }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-family: swiper-icons;
    font-size: $font-size-base;
    font-weight: $font-weight-bolder;
    text-transform: none !important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1;
  }
  // Swiper Carousel equal height
  &.swiper-wrapper{
    display: flex;
    flex-wrap: wrap;
   
    .swiper-slide{
      display: flex;
      height: auto !important;
    }
  }   
}